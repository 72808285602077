import axios from 'axios';
import { getHeaders,  baseUrl } from './utils';

export async function getUserCall() {

  try {
    // const params = updateParams
    const response  = await fetch(`${baseUrl}/get_current_user/me`,{
      method: "GET",
      credentials: 'include',
      headers: getHeaders(),


    })


    const responseBack = await response.json()
    return responseBack
  } catch (err) {
    return { status: 500 };

  }
}

export async function updateUserCall({ id, formData }) {
  let headers = {
    "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
    "Content-Security-Policy": "frame-ancestors 'self';",
    'X-Frame-Options': 'sameorigin',
    'X-Content-Type-Options': 'nosniff',
    'credentials': 'include',
  }
  //  getHeaders();
  // headers['Content-Type'] = 'multipart/form-data'
  try {
    // const params = updateParams
    const response  = await fetch(`${baseUrl}/update_user/${id}`,{
      method: "POST",
      credentials: 'include',
      headers,

      // body:  params
      body: formData

    })


    const responseBack = await response.json()
    // console.log('BACK ???? ', responseBack)
    return responseBack
  } catch (err) {
    return { status: 401 };

  }
}
