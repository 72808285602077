import React, { useEffect, useState, createRef } from 'react';
import { useNavigate } from "react-router-dom";

import { styled as styles, useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import FavoriteIcon from '@mui/icons-material/Favorite';

import logo from '../../assets/JarisLogo.png';
import HeaderDrawer from './HeaderDrawer';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { deepPurple } from '@mui/material/colors';
import { styled as muiStyle } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { default as ButtonNextMui }  from '@mui/material-next/Button';

import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import InputBase from '@mui/material/InputBase';
import Menu from '@mui/material/Menu';
import IMAlerts from './IMAlerts'

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { updateUser } from '../../models/Users'


const drawerWidth = 240;

const StyledBadge = muiStyle(Badge)(({ theme }) => ({
'& .MuiBadge-badge': {
  backgroundColor: '#44b700',
  color: '#44b700',
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  '&::after': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    animation: 'ripple 1.2s infinite ease-in-out',
    border: '1px solid currentColor',
    content: '""',
  },
},
'@keyframes ripple': {
  '0%': {
    transform: 'scale(.8)',
    opacity: 1,
  },
  '100%': {
    transform: 'scale(2.4)',
    opacity: 0,
  },
},
}));

const ImageSrc = styles('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Main = styles('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const HeaderContainer = styled.div`
    position: absolute;
    margin: 15px;
    right: 5px;
    display: flex;
`;



const AppBar = styles(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styles('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Header(props) {
  const theme = useTheme();
  const [ open, setOpen ] = useState(false);
  const [ openMenu, setOpenMenu ] = useState(false);
  const [ alertMessage, setAlertMessage ]  = useState(props.alertMessage);
  const [ displayAlert, setDisplayAlert ] = useState(true);
  const [ disabled, setDisabled ] = useState(false)
  const [ headerSearched, setHeaderSearched ] = useState({})
  const [ image, setImage ] = useState(props.avatar ? props.avatar : null);
  const [ selectedPlayer, setSelectedPlayer ] = useState(null);
  const [ currentView, setCurrentView ] = useState(props.currentView)
  const [ selectedFilter, setSelectedFilter ] = useState(props.headerSearchFields[0].name)

  const inputFileRef = createRef(null);
  const navigate = useNavigate();




  useEffect(() => {
    if (props.player && (!selectedPlayer || selectedPlayer.id !== props.player.id)) {
      setSelectedPlayer(props.player)
      // setShowPlayerNotes(true)
    }
    if (currentView !== props.currentView) {
      setCurrentView(currentView)
    }
    if (props.avatar && props.avatar !== image ) {
      setImage(props.avatar)

    }
  })

  const handleDrawerOpen = () => {
    // DISABLE OPEN DRAWER FOR NOW...
    setOpen(true);
  };

  const toggleMenuDrawer = (drawerOpen) => {
    setOpen(drawerOpen);
  }

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  const Search = styles('div')(({ theme }) => ({
     // position: 'relative',
     position: 'absolute',
     right: '350px',
     borderRadius: theme.shape.borderRadius,
     alignItems: 'center',
     display: 'flex',
     height: '-webkit-fill-available',
     backgroundColor: alpha(theme.palette.common.white, 0.15),
     '&:hover': {
       backgroundColor: alpha(theme.palette.common.white, 0.25),
     },
     marginLeft: 0,
     width: '100%',
     borderRadius: 15,
     [theme.breakpoints.up('sm')]: {
       marginLeft: theme.spacing(1),
       width: 'auto',
     },
  }));
  const SearchIconWrapper = styles('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  const StyledInputBase = styles(InputBase)(({ theme }) => ({
     color: 'inherit',
     '& .MuiInputBase-input': {
       padding: theme.spacing(1, 1, 1, 0),
       // vertical padding + font size from searchIcon
       paddingLeft: `calc(1em + ${theme.spacing(4)})`,
       transition: theme.transitions.create('width'),
       width: '100%',
       [theme.breakpoints.up('sm')]: {
         width: '12ch',
         '&:focus': {
           width: '25ch',
         },
       },
     },
   }));
   const StyledButton = styles(Button)(({ theme }) => ({
      // color: 'inherit',
      background: '#90CAF9',
      color: '#000000',
      fontaFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '26px',
/* identical to box height, or 173% */

      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      '& .MuiInputBase-input': {

        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '20ch',
          },
        },
      },
    }));

  const searchHeaderFilterEnter = (e) => {
     if (e.keyCode == 13) {
       props.searchHeaderFilterButtonClicked(headerSearched)
       setHeaderSearched({})
     }


  }
  const onCloseAlert = () => {
    setAlertMessage("")
    setDisplayAlert(false)
  }

  const handleSelectFilter = (event, nextFilter) => {
    if (nextFilter && nextFilter !== selectedFilter) {
      setSelectedFilter(nextFilter);

    }
   };

  const onSearchFieldPlayerChange = (event) => {
    event.preventDefault();
    const val = event.target.value
    onHeaderSearchFieldChange({ val })
  }

  const onHeaderSearchFieldChange = ({ val }) => {
    let newSearch = headerSearched ;
    // (props.headerSearchFields).forEach((searchObject) => {
    //   newSearch[searchObject.name] = field === searchObject.name ? val : headerSearched[searchObject.name] || ''
    // })
    // newSearch = {}

    newSearch['filter'] = selectedFilter
    newSearch['value'] = val

    setHeaderSearched(newSearch)
  }

  const handleOnChangeAvatr = (event) => {

    const avatar = event.target?.files?.[0];
    console.log('AVATR ??? ', URL.createObjectURL(avatar))
    const updateImg = URL.createObjectURL(avatar)

    const asyncUpdateUser = async () => {
      const res = await updateUser({ id: props.user.id, avatar  })
      // props.refreshUser()
      props.setAvatar(updateImg)
    }

    if (updateImg) {
      setImage(updateImg)
      props.setAvatar(updateImg)
      const timer = setTimeout(() => {
        asyncUpdateUser()
        // updateUser({ id: props.user.id, avatar  })

      }, 1000);
      return () => clearTimeout(timer);
      // const params = { avatar }
    }
  };
  const getNotifications = () => {

    let notifications = 0

    Object.keys(props.notifications).forEach((not) =>
      notifications += props.notifications[not]
    )

    return notifications
  }
  // {alertMessage && displayAlert && !false && <IMAlerts onClose={onCloseAlert} severity="info" message={alertMessage} />}
  const navigateTabs = [
    { title: 'Players', href: '/players', icon: <PersonIcon /> },
    { title: 'Announcements', href: '/announcements',
       icon: <NotificationsIcon />,
       notifications: props.notifications['activeAnnouncements']
     , hidden: props.unauthTabs && props.unauthTabs['activeAnnouncements'] },
    { title: 'Health Score', href: '/health', icon: <FavoriteIcon /> },
    { title: 'RewardMax', href: '/rewardMax', icon: <CardGiftcardIcon /> , hidden: props.unauthTabs && props.unauthTabs['rewardMax']},
  ]
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
          <Badge
            invisible={getNotifications() <= 0}
            badgeContent={getNotifications()}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#FFA726 !important'
              },
              }}
          > <MenuIcon /></Badge>

          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <Box onClick={() => navigate('/players')} sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%', cursor: 'pointer' }}>
              <img src={logo} alt="Logo" />
            </Box>
          </Typography>
          {
            props.playerSearchEnable && <Search key={`SearchKey`} sx={{height: '60px'}}>
                <SearchIconWrapper sx={{height: '60px'}}>
                  <SearchIcon sx={{height: '60px'}}/>
                </SearchIconWrapper>
                <StyledInputBase
                  key={`SearchKeyInput`}
                  placeholder={selectedFilter ? `Search ${selectedFilter}` : 'Search...'}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={onSearchFieldPlayerChange}
                  value={selectedFilter ? headerSearched[selectedFilter] : null}
                  onKeyDown={searchHeaderFilterEnter}
                  sx={{height: '60px', margin: 1}}
                />
                <ToggleButtonGroup
                  value={'id'}
                  exclusive
                  onChange={handleSelectFilter}
                  sx={{height: '60px'}}
                >

                {props.headerSearchFields && props.headerSearchFields.map((searchField) =>
                  <Tooltip title={`${searchField.label}` }>

                  <ToggleButton
                    sx={{ color: selectedFilter === searchField.name ? 'white': 'dark', borderRadius: 2 }}
                    value={searchField.name}
                    aria-label={searchField.name}
                    >{searchField.icon}</ToggleButton>
                  </Tooltip>
                )}
              </ToggleButtonGroup>
              </Search>

          }

          {props.user &&
            <HeaderContainer>

            <MenuItem key={`${props.user.email}Key`} onClick={toggleMenu}>
              <Typography textAlign="center">{props.user.email}</Typography>
            </MenuItem>
            <Menu
                id="menu-appbar"
                anchorEl={openMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openMenu}
                onClose={toggleMenu}
              >
                <MenuItem key={'ProfileMenuKey'} onClick={toggleMenu}>Profile</MenuItem>
                <MenuItem key={'MyAccountMenuKey'} onClick={toggleMenu}>My account</MenuItem>
              </Menu>

              <Tooltip title={`${props.user.firstName} ${props.user.lastName} ${props.user.role}` }>
              <label htmlFor="upload-avatar-pic">
                <IconButton component="span">
                  <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >

                    <input accept="image/*" id="upload-avatar-pic" ref={inputFileRef} type="file" hidden onChange={handleOnChangeAvatr}/>

                        <Avatar
                          src={image}
                          sx={{ bgcolor: deepPurple[500] }}
                          alt={`${props.user.firstName} ${props.user.lastName}`}>
                        {`${props.user.firstName[0].toUpperCase()}${props.user.lastName[0].toUpperCase()}`}

                        </Avatar>

                  </StyledBadge>
              </IconButton>
            </label>
             </Tooltip>
            </HeaderContainer>}

        </Toolbar>
      </AppBar>
      {<HeaderDrawer
        open={open}
        navigateTabs={navigateTabs}
        toggleMenuDrawer={toggleMenuDrawer}/>}

    </Box>
  );
}
