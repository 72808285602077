import React, {useContext} from "react";

import Button from "@mui/material/Button";

import {OnboardingContext} from "./OnboardingContext";

const CompleteOnboardingButton = () => {
  const onboarding = useContext(OnboardingContext);

  if (!onboarding.allow_complete_onboarding) {
    return null;
  }

  return (
    <Button
      variant="contained"
      disabled={!onboarding.enable_complete_onboarding}
      onClick={() => {
        // TODO
      }}
    >
      Complete Onboarding
    </Button>
  );
};

export default CompleteOnboardingButton;
