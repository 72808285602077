import { playersCall, playersSearchCall } from '../api/Players'
import { processResponse, toCamelCase } from './Utils'


export const processPlayerResponse = (userResponse) => {
  // let players = []
   return (userResponse).map((player) =>
    processResponse(player)
   )
   // return players;
}

export async function getPlayers({ unAuthCallback, filter }) {

  const response = await playersCall({ unAuthCallback, filter });

  if (response ) {
    if (response && response.status !== 500 ) {
      // const newToken = response.headers.get("Authorization");
      // localStorage.setItem("token", newToken);
      if (response.status === 401) {
        return response
      }
      let res = {}

      // Object.keys(response).map((key) => res[key] = key === 'players' ? processPlayerResponse(response[key]) : processResponse(response[key]))
      // console.log('RESOINSE ???11111?', res)
      Object.keys(response).map((key) => res[toCamelCase(key)] = key === 'players'
        ?  response[key].map((note) =>
               processResponse(note))
        : processResponse(response[key]))

        // console.log('RESOINSE clint ', res)

      return res
    } else {
      unAuthCallback(response);
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function playersSearch({ unAuthCallback, params }) {

  let paramsToServer = params
  // Object.keys(params).forEach((key, i) => {
  //   paramsToServer[camelToUnderscore(key)] = params[key]
  // });
  if (params['search'] && params['search']['filter']) {
    paramsToServer['search']['filter'] = paramsToServer['search']['filter'].toLowerCase()
  }

  const response = await playersSearchCall(paramsToServer);
  if (response ) {
    if (response && response.status === 200) {
      // const newToken = response.headers.get("Authorization");
      // localStorage.setItem("token", newToken);
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = key === 'players'
          ?  response[key].map((note) =>
                 processResponse(note))
          : processResponse(response[key]))
      res["status"] = 200
      return res
    } else {
      // console.log('Session expired ? ', response)
      // NEEDS TO SIGN IN AS JARIS USER
      if (unAuthCallback) {
        unAuthCallback(response)
      }

      return { status: response.status || 500, error: 'Unauth' }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

// export async function playerTest({ unAuthResponseCallback }) {
//   const response = await playerTestCall();
//   if (response ) {
//     if (response && response.status !== 401) {
//       // const newToken = response.headers.get("Authorization");
//       // localStorage.setItem("token", newToken);
//
//       return response
//     } else {
//       // NEEDS TO SIGN IN AS JARIS USER
//       if (unAuthResponseCallback) {
//         unAuthResponseCallback()
//       }
//       return { status: 401 }
//     }
//   } else {
//     console.log('ERROR', response)
//     return { error: `ERROR! ${response}`};
//   }
// }
