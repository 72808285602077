import React, { useEffect, useState } from 'react';
import { playersSearch } from '../../models/Players'
import { getLRPlayerProfile } from '../../models/Player/Profile'
import IMSkeletonLoader from '../common/IMSkeletonLoader'
import { useNavigate } from "react-router-dom";

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';


const theme = createTheme({
  palette: {
    // primary: {
    //   // Purple and green play nicely together.
    //   main: purple[500],
    // },
    secondary: {
      // This is green.A700 as hex.
      main: '#B0BEC5',
    },
  },
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
export default function ConfirmMergeDialog({ playerId, playerName, onConfirmMerge, onCancel, title }) {
  const [ open, setOpen ] = React.useState(true);
  const [ pick, setPick ] = useState(null)
  const [ disableContinue, setDisableContinue ] = useState(false)
  const [ activeStep, setActiveStep ] = useState(0)
  const [ playerToRemove, setPlayerToRemove ] = useState(null)
  const [ loadingPlayer, setLoadingPlayer ] = useState(false)
  const [ error, setError ] = useState(null)
  const [ lrPlayer, setLRPlayer ] = useState(null)
  const navigate = useNavigate();

  const steps = [
    'Player to be kept',
    'Player to be merged',
    'Confirm merges',
  ];

  const getLrPlayerPreview = async (id) => {
    setLoadingPlayer(true)
    setDisableContinue(true)
    const response = await getLRPlayerProfile({ playerId: id, preview: true, real: true })
    if (!response || !response.player) {
      if(response.error && response.error === 'Expired') {
        navigate('/')

      }

      setError('Player Not Found! ')
      setLoadingPlayer(false)
      return
    }
      if (response.player.disabled) {
        setError(`Player, ${response.player.name} must be Enabled for Merging Accounts to be processed`)
        id === playerId ? setLRPlayer(response.player) : setPlayerToRemove(response.player)
        setLoadingPlayer(false)
        return
      }
      if (id === playerId) {

        setLRPlayer(response.player)
        setDisableContinue(false)
      } else {

        const player = response ? response.player : null
        console.log('PLAYER ??? ', player)
        if (response.status === 200 && player) {

          setPlayerToRemove(player)
          setError(null)
          setDisableContinue(false)
          // setConfirmReady(nextStep === steps.length -1 && player)
        } else {
          setDisableContinue(true)
          setError('Invalid Player to remove!')
        }
        setLoadingPlayer(false)
      }
    setLoadingPlayer(false)
  }
  useEffect(() => {
    if (!lrPlayer && !loadingPlayer) {
      setLoadingPlayer(true)
      getLrPlayerPreview(playerId)
    }
  })
  const handleClose = () => {
    onCancel()
    setOpen(false);
  };

  const handleConfirmMerge = () => {

    onConfirmMerge({ playerToKeep: lrPlayer.id, playerToDelete: playerToRemove.id })

    setOpen(false);

  };

  const handleNext = async () => {

    let nextStep = activeStep < steps.length -1 ? activeStep + 1 : steps.length -1
    const canContinue = pick && pick !== lrPlayer.id.toString()

    switch (activeStep) {
      case 0 :
        setDisableContinue(!canContinue)
        setLoadingPlayer(true)
        break;
      case 1 :
        setDisableContinue(!canContinue)

        nextStep = !canContinue ? activeStep : nextStep
        if (canContinue) {
          await getLrPlayerPreview(pick)
        }
        break;
      case 2 :
        // MERGE!
        handleConfirmMerge()
        break;
      default:
    }

    setActiveStep(nextStep);

  };

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep > 0 ? activeStep - 1 : 0);
    setError(null)
  };
  const onChoose = (event) => {
    const playerIdToRemove = event.target.value;

    const timer = setTimeout(() => {
      setPick(playerIdToRemove)
      const disable = !playerIdToRemove || playerIdToRemove === lrPlayer.id.toString()
      console.log('DISBALE ?', disable, playerIdToRemove, lrPlayer.id, playerIdToRemove === lrPlayer.id)
      setDisableContinue(disable)

    }, 1000);
    return () => clearTimeout(timer);

  }
  const PLAYER_VERIFY = {
    'ID': 'id',
    'Phone Number': 'phoneNumber',
    'Last IP': 'ipAddress',
    'Device': 'device'
  }
  const getContent = (index) => {
    return (
       <Card >

            <CardContent>
            { index === 0
              ? loadingPlayer || !lrPlayer
                ? <Stack>
                  <Typography variant="body2" color="text.secondary">Verifying Player To Keep...</Typography>

                    <IMSkeletonLoader key={`skeletonKeyItem`} itemsNumber={5} columns={1} variant={'rectangular'} width={260} height={24}/>
                  </Stack>
                : error
                ? <Typography variant={'body2'} sx={{ color: '#F11414', textAlign: 'center' }}>{error}</Typography>
                : <Stack spacing={0}>
                    <Typography gutterBottom variant="h6" component="header">
                      {`${lrPlayer ? lrPlayer.name : playerName} `}
                    </Typography>
                    {
                      Object.keys(PLAYER_VERIFY).map((label) =>
                      <Stack spacing={{ xs: 1, sm: 2 }}
                          direction="row"
                          useFlexGap
                          flexWrap="wrap">
                          <Typography variant="body1"  sx={{fontWeight: 700}}>
                            {`${label}:`}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {lrPlayer[PLAYER_VERIFY[label]]}
                          </Typography>
                        </Stack>
                      )
                    }
                  </Stack>
              : index === 1
              ?
              <>
                <Typography variant="body1" sx={{paddingLeft: 1, paddingBottom: 1}} >
                Inform the player of the account that will be merged into the previous account
                </Typography>
                <Box sx={{margin: 2}}>
                <TextField
                     id="textFieldMerge2"
                     label="Player ID"
                     type="number"
                     onChange={onChoose}
                     variant="standard"

                     InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                   />
                 </Box>
                </>
              : loadingPlayer
              ?  <Typography > Verifying Player to remove ...</Typography>

              :  !error
                ? <Stack spacing={4}>
                  <Typography variant="body1">All member accounts and points from the account you wish to delete will be reassigned to the account you chose to keep.</Typography>

                  <Stack spacing={{ xs: 1, sm: 2 }}
                          sx={{width: '100% !important', justifyContent: 'space-between'}}
                          direction="row"
                          useFlexGap
                          flexWrap="wrap">
                    <Box>
                      <Typography variant="button" sx={{color: 'green', fontSize: 18}}>keep</Typography>
                      <Typography gutterBottom variant="h6" component="header">
                        {`${lrPlayer ? lrPlayer.name : playerName} `}
                      </Typography>
                      {
                        Object.keys(PLAYER_VERIFY).map((label) =>
                        <Stack spacing={{ xs: 1, sm: 2 }}
                            direction="row"
                            useFlexGap
                            flexWrap="wrap">
                            <Typography variant="body1"  sx={{fontWeight: 700}}>
                              {`${label}:`}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              {lrPlayer[PLAYER_VERIFY[label]]}
                            </Typography>
                          </Stack>
                        )
                      }</Box>

                      <Box>
                      <Typography variant="button" sx={{color: 'red', fontSize: 18}}>delete</Typography>
                      <Typography gutterBottom variant="h6" component="header">
                        {`${playerToRemove.name} `}
                      </Typography>
                      {
                        Object.keys(PLAYER_VERIFY).map((label) =>
                        <Stack spacing={{ xs: 1, sm: 2 }}
                            direction="row"
                            useFlexGap
                            flexWrap="wrap">
                            <Typography variant="body1"  sx={{fontWeight: 700}}>
                              {`${label}:`}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                              {playerToRemove[PLAYER_VERIFY[label]]}
                            </Typography>
                          </Stack>
                        )
                      }</Box>
                  </Stack>

                  </Stack>
                : <Typography>{error}</Typography>}

            </CardContent>
            <CardActions>

            </CardActions>
          </Card>


    )
  }
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: 580, maxWidth: 580, bgcolor: 'background.paper' }}>
          <Dialog
            sx={{ '& .MuiDialog-paper': { width: 580, maxWidth: 580, maxHeight: 'fit-content' } }}
            maxWidth="xs"
            open={open}
            >
            <DialogTitle>{title}</DialogTitle>

              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

            <DialogContent dividers>
              <Stack spacing={4}>
                <Stepper activeStep={activeStep} alternativeLabel >
                 {steps.map((label, index) => (
                   <Step key={label, index}>
                     <StepLabel>{label}</StepLabel>
                   </Step>
                 ))}
               </Stepper>
                {
                  <Box sx={{ mb: 2 }}>
                    {getContent(activeStep)}
                  </Box>
                }

             </Stack>
              <br/>
            </DialogContent>

            <DialogActions >
            <Stack spacing={{ xs: 1, sm: 2 }}
                    sx={{display: 'contents'}}
                    direction="row"
                    useFlexGap
                    flexWrap="wrap">
            {activeStep === steps.length - 1 && !disableContinue && <Typography variant={'body2'} sx={{ color: '#F11414', textAlign: 'center' }}>This action can’t be undone, please confirm selected account are correct.</Typography>}


              <Box sx={{justifyContent: 'right', display: 'flex', width: '50%'}}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1, height: 42 }}
                >
                  Back
                </Button>
                  <Button
                    color={activeStep === steps.length - 1 ? 'error' : 'primary'}
                    variant="contained"
                    onClick={handleNext}
                    disabled={disableContinue}
                    sx={{ mt: 1, mr: 1, height: 42  }}

                  >
                    {activeStep === steps.length - 1 ? 'Merge' : 'Continue'}
                  </Button>

                </Box>
              </Stack>
            </DialogActions>
        </Dialog>
      </Box>
     </ThemeProvider>
  );
}
