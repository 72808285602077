import React, { useState, useEffect } from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import IMLoader from '../common/IMLoader'
import { formatDate, formatTime, daysDiff, monthsMap } from '../common/Utils'

export default function PurchasesGraph(props) {
  const [ data, setData ] = useState(null)
  const [ datesG, setDatesG ] = useState(null)
  const [ boostDataG, setBoostDataG ] = useState(null)
  const [ standardDataG, setStandardDataG ] = useState(null)
  const [ refresh, setRefresh ] = useState(false)

  const [ loading, setLoading ] = useState(true)
  const [ options, setOptions ] = useState({})

  useEffect(() => {
    if ((data === null || refresh !== props.togglePurchases)
        && (props.boostPurchases || props.standardPurchases)) {
      setupData()
    }
  })

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format

  const getDate = (dayObject) => {
    const str = JSON.parse(dayObject).date
    const date = new Date(str)

    let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));

    return utcDate
  }

  const setupData = () => {
    setLoading(true)
    setRefresh(props.togglePurchases)

    if ((!props.standardPurchases || props.standardPurchases.length === 0) && (!props.boostPurchases || props.boostPurchases.length === 0)) {
      setBoostDataG([])
      setStandardDataG([])
      setData([])
      setDatesG([])
      setLoading(false)
      return;
    }

    let days = []
    let dates = {}
    let day = {}
    let sdate = null
    let bdate = null
    // let date = null
    let sdayIndex = -1
    let bdayIndex = -1
    let stringDate = ''
    let sStringDate = ''
    let bStringDate = ''

    let standardData = []
    let boostData = []
    let oldestDate = null
    let latestDate = null


    const daysInfo = Math.max(props.standardPurchases.length, props.boostPurchases.length)


    // } else
    // {
      if (!props.standardPurchases || props.standardPurchases.length === 0) {
        oldestDate = getDate(props.boostPurchases[0])
        latestDate = getDate(props.boostPurchases[props.boostPurchases.length - 1])

      } else {
        if (!props.boostPurchases || props.boostPurchases.length === 0) {
          oldestDate = getDate(props.standardPurchases[0])
          latestDate = getDate(props.standardPurchases[props.standardPurchases.length - 1])

        } else {

          latestDate = getDate(props.standardPurchases[props.standardPurchases.length -1]) > getDate(props.boostPurchases[props.boostPurchases.length -1]) ? getDate(props.standardPurchases[props.standardPurchases.length -1]) : getDate(props.boostPurchases[props.boostPurchases.length -1])
          oldestDate = getDate(props.standardPurchases[0]) < getDate(props.boostPurchases[0]) ? getDate(props.standardPurchases[0]) : getDate(props.boostPurchases[0])
        }
      }
    // }

    let nextDay = oldestDate;
    let stop = false

    while (!stop) {
      stop = `${nextDay.getDate() }-${monthsMap(nextDay.getMonth())}` === `${latestDate.getDate() }-${monthsMap(latestDate.getMonth())}`

      stringDate = nextDay ? `${nextDay.getDate() }-${monthsMap(nextDay.getMonth())}` : null
      day = { day: stringDate }


      // sdayIndex += props.standardPurchases && props.standardPurchases[sdayIndex + 1] ? 1 : 0

      // sdate = props.standardPurchases && props.standardPurchases[sdayIndex] ? getDate(props.standardPurchases[sdayIndex]) : null

      // sStringDate = sdate ? `${sdate.getDate() }-${monthsMap(sdate.getMonth())}` : null
      //
      // if (sStringDate && stringDate === sStringDate) {
      //   day['standard'] = JSON.parse(props.standardPurchases[sdayIndex]).amount
      //   day['standardCards'] = JSON.parse(props.standardPurchases[sdayIndex]).cards
      // } else {
      //   // day['standard'] = 0
      //   // day['standardCards'] = 0
      //   // sdayIndex -= 1
      // }

      if (props.standardPurchases && props.standardPurchases[sdayIndex + 1]) {
        sdate = getDate(props.standardPurchases[sdayIndex+1])

        sStringDate = sdate ? `${sdate.getDate() }-${monthsMap(sdate.getMonth())}` : null

        if (sStringDate === stringDate) {

          day['standard'] = JSON.parse(props.standardPurchases[sdayIndex+1]).amount
          day['standardCards'] = JSON.parse(props.standardPurchases[sdayIndex+1]).cards
          sdayIndex++
        } else {

            // day['boost'] = 0
            // day['boostCards'] = 0
            // bdayIndex -= 1
        }
      }

      // bdayIndex += props.boostPurchases && props.boostPurchases[bdayIndex + 1] ? 1 : 0

      if (props.boostPurchases && props.boostPurchases[bdayIndex + 1]) {
        bdate = getDate(props.boostPurchases[bdayIndex + 1])
        bStringDate = bdate ? `${bdate.getDate() }-${monthsMap(bdate.getMonth())}` : null
        if (bStringDate === stringDate) {
          // console.log('BOOST day ', day, props.boostPurchases[bdayIndex + 1],  JSON.parse(props.boostPurchases[bdayIndex + 1]))

          day['boost'] = JSON.parse(props.boostPurchases[bdayIndex + 1]).amount
          day['boostCards'] = JSON.parse(props.boostPurchases[bdayIndex + 1]).cards
          bdayIndex++
        } else {

            // day['boost'] = 0
            // day['boostCards'] = 0
            // bdayIndex -= 1
        }
      }



      sdate = props.standardPurchases[sdayIndex + 1] ? getDate(props.standardPurchases[sdayIndex + 1]) : null
      bdate = props.boostPurchases[bdayIndex + 1] ? getDate(props.boostPurchases[bdayIndex + 1]) : null

      nextDay = !sdate ? bdate : !bdate ? sdate : sdate < bdate ? sdate : bdate


      // dates[stringDate] = !dates[stringDate] ?  day : dates[stringDate]

      if (!dates[stringDate]) {
        dates[stringDate] = day
        boostData.push(day['boost'])
        standardData.push(day['standard'])
        days.push(day)
      }
      // console.log('DATA ???? ', days, dates, nextDay)



    }



    setData(days)

    setBoostDataG(boostData)

    setStandardDataG(standardData)
    setDatesG(dates)
    setLoading(false)

}

  const getValidDate = (d) => {
    return isNaN(d) ? 0 : Number(d)
  }
  const getTooltipTitle = (dateKey) => {
    // console.log('DATA standard ', data[dateKey].standard)

    const standardAmount = !dateKey || !datesG[dateKey] ? 0 : getValidDate(datesG[dateKey].standard)
    const boostAmount = !dateKey || !datesG[dateKey] ? 0 : getValidDate(datesG[dateKey].boost)

    const standardCards = !dateKey || !datesG[dateKey] ? 0 : datesG[dateKey].standardCards || 0
    const boostCards = !dateKey || !datesG[dateKey] ? 0 : datesG[dateKey].boostCards || 0

    return `${dateKey} - Total: ${currencyFormatter(standardAmount + boostAmount )} (${standardCards + boostCards})`

  }

  return (
    <>{ loading === true || data === null
       ? <IMLoader message={'Loading Purchases Cards Data...'} mini/>
       : <BarChart
           series={[
             { data: standardDataG, stack: 'A', label: 'Standard', color: '#4eb3d3',
               valueFormatter: (v, { dataIndex }) => {

                   // const { amount, cards } = JSON.parse(props.standardPurchases[ dataIndex]);
                   const { standard, standardCards } = data[dataIndex]

                   return standard ? `${ currencyFormatter(standard)} (${standardCards}).` : '-';
                 } },
             { data: boostDataG, stack: 'A', label: 'Boost', color: '#f28e2c',
               valueFormatter: (v, params) => {
                  const { dataIndex } = params
                   // const { amount, cards } = JSON.parse(props.boostPurchases[dataIndex]);
                   // return amount ? `${ currencyFormatter(amount)} (${cards}).` : '-';
                   const { boost, boostCards } = data[dataIndex]

                   return boost ? `${ currencyFormatter(boost)} (${boostCards}).` : '-';
                 }
               },

           ]}
           xAxis={[{ scaleType: 'band', data: Object.keys(datesG),
            valueFormatter: (v, { location } ) =>  {
              return location === 'tooltip' ? `${getTooltipTitle(v)}` : v
            }
          }]}


         width={1000}
         height={350}
       />
     }</>

  );
}
