import axios from 'axios';
import { getHeaders,  baseUrl } from '../utils';


export async function getRangedPlayersHealthScoreCall({ range }) {
  try {
    const data = { range }
    const response  = await fetch(`${baseUrl}/all_players_health`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data
      })
      }
    )

    const responseBack = await response.json()
    // console.log('RESOINSE ????', responseBack)

    return responseBack
  } catch (err) {
    return { err };

  }
}

export async function editPlayersHealthRangeCall({ range, changes }) {
  try {
    const data = { range, changes }
    const response  = await fetch(`${baseUrl}/edit_health_range`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data
      })
      }
    )

    const responseBack = await response.json()
    // console.log('RESOINSE ????', responseBack)

    return responseBack
  } catch (err) {
    return { err };

  }
}
