import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import PlayerHealth from './PlayerHealth'
import { styled as muiStyled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import StarIcon from '@mui/icons-material/Star';
import ListItemIcon from '@mui/material/ListItemIcon';

const TopSpender = muiStyled(Box)(({ theme }) => ({
  padding: '6px 16px',
  position: 'absolute',
  width: '1000px',
  height: '76px',
  color: 'white',
  // left: 325px;
  // top: 136px;

  /* Dark/Warning/Light */

  background: '#FFB74D',
  borderRadius: '4px'
}))

const Health = muiStyled(Box)(({ theme }) => ({
  padding: '6px 16px',
  position: 'absolute',
  width: '1000px',
  height: '76px',
  // color: 'white',
  marginTop: '100px',
  // left: 325px;
  // top: 136px;

  /* Dark/Warning/Light */

  // background: '#FFB74D',
  borderRadius: '4px'
}))



export default function HomeHeader(props) {


  return (
    <Box>
      {props.player && props.player.topSpender && false &&
      <>  <TopSpender >
          <Stack spacing={0} direction="row">
            <Box sx={{padding: 1}}>
              <StarIcon color={'white'} />
            </Box>
            <Stack spacing={0.5} sx={{padding: 1}}>
              <Typography sx={{fontSize: '16px', fontWeight: 550}}>
              {`${props.player.name} is a Top Spender`}
              </Typography>
              <Typography sx={{fontSize: '14px', fontWeight: 450}}>
              This player has often been making purchases on the apps installed and is considered a top spender. Wow them!
              </Typography>
            </Stack>
          </Stack>
        </TopSpender>

      <Box sx={{ m: 3 }} />

      <PlayerHealth />
</>}
    </Box>

  )
}
