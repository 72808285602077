import { editPlayerProfileCall, getLRPlayerProfileCall, playerDeleteCall } from '../../api/Player/Profile'
import { processResponse, toCamelCase, processParamsToServer } from '../Utils'


export async function editPlayerProfile({ playerId, data, addedInfo }) {

  let dataToServer = processParamsToServer(data)
  if (addedInfo) {
    dataToServer = { ...dataToServer, ...processParamsToServer(addedInfo) }
  }

  const response = await editPlayerProfileCall({ playerId, data: dataToServer });
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function playerDelete({ playerId }) {
  const response = await playerDeleteCall({ playerId });
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function getLRPlayerProfile({ playerId, preview, real }) {

  const response = await getLRPlayerProfileCall({ playerId, preview, real });
 // console.log('RESOINSE ????', response, response.status)
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
