import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { editPlayerProfile } from '../../models/Player/Profile'
import { getPlayers } from '../../models/Players'

import Box from '@mui/material/Box';
import IMLoader from '../common/IMLoader'
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ConfirmDialog from '../common/ConfirmDialog'
import IMAlerts from '../common/IMAlerts'

import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { countriesCodes } from '../../utils/constants'
import GroupedPlayersContainer from './GroupedPlayersContainer'
import GroupIcon from '@mui/icons-material/Group';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ConstructionIcon from '@mui/icons-material/Construction';
import BalconyIcon from '@mui/icons-material/Balcony';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSkullCrossbones, faUserNinja } from '@fortawesome/free-solid-svg-icons'
import ConfirmMergeDialog from './ConfirmMergeDialog'

const RenderOnBoradingCompleted = (props) => {
  const { value } = props;
  return value == null ? 'No' : 'Yes'
}

const RenderID = (props) => {
  const { value, row } = props;
  const { onPlayerIDClick } = row;

  const onClick = () => {
    onPlayerIDClick(value)
  }
  return (
    <Typography sx={{color: 'blue', cursor: 'pointer'}} onClick={onClick} >
      {value}
    </Typography>

  )
}
const RenderActions = (props) => {
  const { value } = props;
  const handleClick = (onClick, player, name) => () => {
    onClick(player, name)
  }

  return (
      <Stack direction="row" spacing={2} >
        {value.map((action) =>
          <Tooltip title={action.tooltip}>
            <IconButton
              onClick={handleClick(action.onClick, action.player, action.name)}>
              {action.icon}
            </IconButton>
          </Tooltip>

        )}
    </Stack>
  )

}
const RenderCountry = (props) => {
  const { value } = props;

  const countryCode = countriesCodes.filter((country) => country.code === value)[0]
  return (
    <Box sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        {value && <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${value.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${value.toLowerCase()}.png 2x`}
          alt=""
        />}
        {countryCode ? countryCode.label : ''}
      </Box>
    )

}
const RenderStatus = (props) => {
  const { value } = props;
  const handleClick = () => {
  };

  return (
    !value
    ? <Chip variant="outlined" color="success" icon={<CheckCircleIcon onClick={handleClick}/>} label={"Active"} />
    : <Chip variant="outlined" color="error" icon={<ErrorIcon onClick={handleClick}/>} label={"Disabled"} />
  );
};

RenderStatus.propTypes = {
  value: PropTypes.string.isRequired,
};


const RenderMerges = (props) => {

  const { value, row } = props;
  const { onPlayerIDClick } = row;

  const onClick = (id) => () => {
    onPlayerIDClick(id)
  }
  return (
    !value
    ? <></>
    : <Stack direction="row" spacing={1}>
    {
      value.map((val) =>
        <Typography sx={{color: 'red', cursor: 'pointer'}} onClick={onClick(val)} >
          {val}
        </Typography>
      )
    }
    </Stack>
  )
}
const playerColumns = [
  { field: 'id', headerName: 'Player ID', width: 130, renderCell: RenderID },
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'country', headerName: 'Country', width: 150, renderCell: RenderCountry },
  { field: 'disabled', headerName: 'Status', width: 130, renderCell: RenderStatus },
  { field: 'onboardingCompletedAt', headerName: 'Onboarding Completed', width: 230, renderCell: RenderOnBoradingCompleted },
  { field: 'createdAt', headerName: 'Account Created', width: 150 },
  { field: 'updatedAt', headerName: 'Last Updated', width: 200 },
  { field: 'actions', headerName: 'Actions', width: 100, renderCell: RenderActions }

];

const graveyardColumns = [
  { field: 'id', headerName: 'Player ID', width: 130, renderCell: RenderID },
  { field: 'mergedInto', headerName: 'Merged Into', width: 130, renderCell: RenderID },
  { field: 'deleteNote', headerName: 'Delete Note', width: 130 },
  { field: 'createdAt', headerName: 'Account Deleted', width: 150 },
  { field: 'merges', headerName: 'Deleted Players Merged', width: 230, renderCell: RenderMerges },
]



const theme = createTheme({
  components: {
    // Name of the component
    MuiDataGrid: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontWeight: '400',
        },
        columnHeaderTitle: {
          fontWeight: '600 !important',
        }
      },
    },
  },
});




export default function PlayersTable(props) {
  const [ currentGroupFilter, setCurrentGroupFilter ] = useState('');
  const [ players, setPlayers ] = useState([]);
  const [ loading, setLoading ] = useState(false)
  const [ dialogProps, setDialogProps ] = useState(null)
  const [ alert, setAlert ] = useState(null)

  const [ loadingMessage, setLoadingMessage ] = useState('')
  const [ playersByFilter, setPlayersByFilter ] = useState({})
  const [ mount, setMount ] = useState(false)
  const [ searchedPlayers, setSearchedPlayers ] = useState([])
  const [ searchedPlayersType, setSearchedPlayersType ] = useState(null)
  const [ toggleHeaderSearchedPlayers, setToggleHeaderSearchedPlayers ] = useState(false)
  const [ searchUrl, setSearchUrl ] = useState(null)
  const [ currentColumns, setCurrentColumns ] = useState(playerColumns)
  const [ permittedActions, setPermittedActions ] = useState(props.user ? props.user.permittedPlayerActions : [])

  const rows = (currentPlayers) => {
    if (!currentPlayers) return []
    const getActions = (player) => {
      const actions = [{ action: 'merge', player: player.id, name: player.name || player.email, icon: <GroupIcon fontSize="small" />, tooltip: 'Merge Accounts', onClick: onMergeDialog }]
      return actions.filter((act) => permittedActions.includes(act.action))
    }


    const rowsBack =  currentPlayers.map((player) =>

      currentGroupFilter === 'graveyards' || searchedPlayersType === "PlayerGraveyards"
      ? ({
        id: player.id,
        mergedInto: player.mergedInto ? player.mergedInto.playerId || player.mergedInto.id : '',
        deleteNote: player.deleteNote,
        merges: player.mergesIds ? player.mergesIds || '' : '',
        createdAt: dayjs(player.createdAt).format('MM/DD/YYYY'),
        // updatedAt: dayjs(player.updatedAt).format('MM/DD/YYYY h:mm:ss A'),
        onPlayerIDClick: onPlayerIDClick
      })
      : ({
        id: player.id,
        name: player.name,
        // email: player.email,
        country: player.country,
        disabled: player.disabled,
        onboardingCompletedAt: player.onboardingCompletedAt ,
        createdAt: dayjs(player.createdAt).format('MM/DD/YYYY'),
        updatedAt: dayjs(player.updatedAt).format('MM/DD/YYYY h:mm:ss A'),
        actions: getActions(player),
        onPlayerIDClick: onPlayerIDClick
      })

    )
    return rowsBack
  }

  useEffect(() => {
    const pathname = window.location.pathname.split('/')

    const urlParams = new URLSearchParams(window.location.search);
    let searchParam
    let valParam
    for (const key of urlParams.keys()) searchParam = key;
    for (const value of urlParams.values()) valParam = value;
    if (pathname[1] === 'players' && urlParams && !searchUrl && searchParam && valParam) {
      setSearchUrl(urlParams)
      let newSearch = {}
      newSearch['filter'] = searchParam
      newSearch['value'] = valParam

      props.searchHeaderFilterButtonClicked(newSearch)
    } else {
      if (!mount && !searchUrl) {
        setMount(true)
        const filter = 'last_viewed_players'
        onFetchFilteredPlayers(filter);
        getGroupedPlayers({filter})
      }
    }

    if (props.forceLoad === true) {
      setLoading(true)
      setLoadingMessage(props.forceLoadMessage)
    }
    if (props.toggleHeaderSearchedPlayers !== toggleHeaderSearchedPlayers ) {
      setToggleHeaderSearchedPlayers(props.toggleHeaderSearchedPlayers)
      setSearchedPlayers(props.searchedPlayers)
      setSearchedPlayersType(props.searchedPlayersType)
      props.searchedPlayersType === 'PlayerGraveyards'
        ? setCurrentColumns(graveyardColumns)
        : setCurrentColumns(playerColumns)

      setPlayers(null)
      setCurrentGroupFilter('')
      setLoading(false)
    }

  })



  const onMergeDialog = ( playerId, playerName ) => {
    // COMMENT OUT FOR DEV TESTING PURPOSE..
    // playerId = 55
    const dialogPropsInit = {
      type: 'merge',
      merge: true,
      title: 'Merge Accounts',
      playerId,
      playerName,
     }

    setDialogProps(dialogPropsInit)

  }
  const onRowClick = (rowProps) => {
    // props.onChoosePlayer(rowProps.row)
  }
  const onPlayerIDClick = (playerId) => {
    props.onChoosePlayer(playerId)
  }


  const onFetchFilteredPlayers = (filter) => {
    setLoading(true)
    setLoadingMessage(`Loading ${filter.split('_').join(' ')}`)
    setCurrentGroupFilter(filter)
    filter === 'graveyards'
      ? setCurrentColumns(graveyardColumns)
      : setCurrentColumns(playerColumns)
  }

  const onSetPlayers = (playersSet) => {
    let groupedPlayers = playersByFilter
    groupedPlayers[currentGroupFilter] = playersSet
    setSearchedPlayers([])
    setSearchedPlayersType(null)
    setPlayers(playersSet)
  }

  const getGroupedPlayers = async ({ filter }) => {
      onFetchFilteredPlayers(filter)
      setLoading(true)
      let success = false
      const response = await getPlayers({ unAuthCallback: props.unAuthCallback, filter})

      if (response && response.players) {
        let newPlayersByFilter = playersByFilter
        newPlayersByFilter[filter] = response.players
        setPlayersByFilter(newPlayersByFilter)
        onSetPlayers(response.players)
        success = true
      } else {
        setPlayers([])
        success = false
      }

      setLoading(false)
      return success
  }

  const currentPlayers = () => {
    return players || searchedPlayers
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Stack direction="row" spacing={2} sx={{margin: '15px', width: '90%'}}>
           <GridToolbarExport />
           <Box sx={{width: 'inherit'}}>
             <Typography sx={{ fontSize: 16, textAlign: 'center' }} color="text.secondary" gutterBottom>
               {currentGroupFilter.split('_').join(' ').toUpperCase()}
             </Typography>
           </Box>
         </Stack>
      </GridToolbarContainer>
    );
  }

  const onDialogCancel = () => {
    setDialogProps(null)
    // setRemoveToMerge(null)
  }

  const onConfirmDialog =  async (params) => {
    if (dialogProps && Object.keys(dialogProps).length > 0) {
      const dialogType = dialogProps.type
      setLoading(true)
      let response = null
      switch (dialogType) {
        case 'merge':
            const { playerToKeep, playerToDelete } = params
            if (playerToKeep && playerToDelete) {
              // console.log('EDIT ', playerToKeep, playerToDelete)

              const response  = await editPlayerProfile({ playerId: playerToKeep, data: params, addedInfo: { merge: true, real: true } })
              // console.log('RESPONSE FROM MERGE', response)
              if (response.error) {
                setAlert({ view: true, severity: 'error', message: response.error })

              } else {
                setAlert({ view: true, severity: 'success', message: 'Merge has been sucessfly submitted, this action can take some minutes to reflect in the account' })

              }

            }

          break;

        default:
          console.log("un supported dialog type");
      }
    }

    setDialogProps(null)
    // setRemoveToMerge(null)
    setLoading(false)
    setLoadingMessage('')

  }
  const onCloseAlert = () => {
    setAlert(null)
  }
  const GROUPED_PLAYERS = [
    { title: 'Last Viewed', filter: 'last_viewed_players', colorBtnType: 'primary', icon: <VisibilityIcon /> },
    { title: 'Disabled', filter: 'disabled', colorBtnType: 'light', icon: <NoAccountsIcon /> },
    { title: 'Top Spenders', filter: 'top_spenders', colorBtnType: 'yellow', icon: <StarBorderIcon /> },
    { title: 'Recent Created', filter: 'recent_created', colorBtnType: 'teal', icon: <RecentActorsIcon /> },
    { title: 'Developers', filter: 'developer', colorBtnType: 'dark', icon: <ConstructionIcon /> },
    { title: 'Graveyards', filter: 'graveyards', colorBtnType: 'red', icon: <FontAwesomeIcon icon={faSkullCrossbones} /> },

  ]
  return (
    <Container sx={{height: 690.5, maxWidth: 'none !important'}}>
    <Stack direction="row" spacing={2} sx={{margin: '15px'}}>
    {
      GROUPED_PLAYERS.map((group) =>
        <GroupedPlayersContainer
          onChoosePlayer={onRowClick}
          title={group.title}
          filter={group.filter}
          getPlayers={getGroupedPlayers}
          colorBtnType={group.colorBtnType}
          icon={group.icon}
          currentGroupFilter={currentGroupFilter}
          />
      )
    }
     </Stack>
    {alert && alert.view &&
      <Box sx={{ width: '70%' }}>
        <IMAlerts
          message={alert.message}
          onClose={onCloseAlert}
          success={!alert.error}
          severity={alert.severity}
          error={alert.error}/>
      </Box>}
    {dialogProps && dialogProps.merge
      && <ConfirmMergeDialog
            onCancel={onDialogCancel}
            {...dialogProps}
            onConfirmMerge={onConfirmDialog}
          />}
      <ThemeProvider theme={theme}>

       { loading
        ? <Box sx={{marginTop: 15}}><IMLoader message={loadingMessage} mini/></Box>
        : currentPlayers() && <>

          <DataGrid
            components={{
              Toolbar:  CustomToolbar,
            }}
            rows={rows(currentPlayers())}
            disableSelectionOnClick
            onRowClick={onRowClick}
            columns={currentColumns}
            pageSize={10}

            rowsPerPageOptions={[10]}
            localeText={{
              noRowsLabel: "Couldn't find any players. Please try a different filter "
            }}

          />
      </>}
      </ThemeProvider>
    </Container>
  );
}
