import React, {useState, useEffect, useContext} from "react";

import TableCell from '@mui/material/TableCell';
import Button from "@mui/material/Button";
import { completeSpotlight } from "../../../../../api/Players";
import {SpotlightContext} from "./SpotlightContext";
import Tooltip from "@mui/material/Tooltip";
import ConfirmDialog from '../../../../common/ConfirmDialog';
import IMAlerts from '../../../../common/IMAlerts';

const SpotlightCompletedCell = ({spotlight}) => {
  const [buttonLoading, setButtonLoading] = useState(false)
  const [buttonDone, setButtonDone] = useState(false)
  const [buttonState, setButtonState] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [ alert, setAlert ] = useState(null)
  const { shownSpotlights, setShownSpotlights, originSpotlights, setOriginSpotlights } = useContext(SpotlightContext);

  // const negativeEndStates = [
  //   'unconfirmed',
  //   'incomplete',
  //   'expired'
  // ]

  const positiveEndStates = [
    'converted',
    'completed',
    'next'
  ]

  useEffect(()=>{
    setButtonState(currentButtonState())
  },[buttonLoading, buttonDone])

  function currentButtonState(){
    let buttonState = 'show'
    if(spotlight.state === 'presented' || positiveEndStates.includes(spotlight.state)){ buttonState = 'hide' }
    if(!spotlight.completedBy && positiveEndStates.includes(spotlight.state)){ buttonState = 'robot' }
    if(spotlight.completedBy && positiveEndStates.includes(spotlight.state)){ buttonState = 'support' }
    return buttonState
  }

  function updateReferenceSpotlight(givenSpotlight){
    let changedOrigins = []
    let changedShown = []

    if(originSpotlights.length){
      originSpotlights.forEach((currentSpotlight)=>{
        if(currentSpotlight.offerId === givenSpotlight.offerId){
          currentSpotlight.state = 'completed'
        }

        changedOrigins.push(currentSpotlight)
      })
    }

    if(shownSpotlights.length){
      shownSpotlights.forEach((currentSpotlight)=>{
        if(currentSpotlight.offerId === givenSpotlight.offerId){
          currentSpotlight.state = 'completed'
        }
        changedShown.push(currentSpotlight)
      })
    }

    setOriginSpotlights(changedOrigins)
    setShownSpotlights(changedShown)

  }

  function handleCompleteClick(){
    if(buttonDone){return false}
    setConfirmOpen(true)

    // let result = window.confirm("Are you sure you want to complete this Spotlight?");
    // if (result === true){

    // }
  }

  function handleCompleteSpotlight(){
    setConfirmOpen(false)
    setButtonLoading(true)
    completeSpotlight(spotlight).then((response)=>{
      console.log(response)
      if(response.status === 200 && !response.data.error){
        updateReferenceSpotlight(spotlight)
        setButtonLoading(false)
        setButtonDone(true)
      } else {
        console.error(response)
        setButtonLoading(false)
        setAlertOpen(true)
      }
    })
  }

  function closeConfirm(){
    setConfirmOpen(false)
  }

  function closeAlert(){
    setAlertOpen(false)
  }

  const chosenElement = () => {
    if(buttonLoading){
      return completeButton(true, "Working...")
    }

    if(buttonDone){
      return completeButton(false, "Success!", 'success')
    }

    if(buttonLoading === false){
      if(buttonState === 'show'){
        return completeButton(false)
      }
      if(buttonState === 'robot'){
        return <p role="img" aria-label="robot" style={{fontSize: 20, textAlign: "center"}}>🤖</p>
      }
      if(buttonState === 'support'){
        return(
          <Tooltip title={spotlight.completedBy}>
            <p role="img" aria-label="person" style={{fontSize: 20, textAlign: "center"}}>🧑</p>
          </Tooltip>
        )
      }

    }

  }

  function completeButton(disabled, text = "Complete", color = 'primary'){
    return(
        <>
          { alertOpen &&
              <IMAlerts
                severity={'error'}
                title={"Failed to Complete Spotlight"}
                message={`Could not complete ${spotlight.installedAppName}.`}
                onClose={closeAlert}
              />
          }
          { confirmOpen &&
              <ConfirmDialog
                title={"Confirm Spotlight Completion"}
                enableConfirm={true}
                onCancel={closeConfirm}
                onConfirm={handleCompleteSpotlight}
                confirmBtnColor={'success'}
                content={
                  <div style={{textAlign:'center'}}>
                    <p style={{margin: '0 20%'}}>{`Are you sure you want to complete this Spotlight Offer?`}</p>
                    <p style={{marginTop: '50px', fontWeight: "bold"}}>{`${spotlight.installedAppName}`}</p>
                  </div>
                }
              />
          }
          <Button
            variant="contained"
            color={color}
            style={{width: '100%'}}
            disabled={disabled}
            onClick={handleCompleteClick}
          >
            {text}
          </Button>
        </>
    )
  }

  return(
    <TableCell>
      {chosenElement()}
    </TableCell>
  )


}

export default SpotlightCompletedCell