import { getPrizesCall, editCardCall, newCardCall, purchaseBoostCall, purchaseEditCall, initPrizesKeysCall } from '../api/Prize'
import { processResponse, toCamelCase, getDateTime, processParamsToServer } from './Utils'


export const BOOST_EDIT_ACTIONS = {
  CAP: 'cap',
  IN_STOCK_TARGET: 'in_stock_target',
  DEV_IN_STOCK_TARGET: 'boost_dev_target_stock_level',

}
export async function initPrizesKeys() {

  const response = await initPrizesKeysCall();
  // console.log('initPrizesKeysCall',response )
  if (response ) {
    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        // unAuthCallback(response)
        return response;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function getPrizes({ type }) {

  const response = await getPrizesCall({ type });

  if (response ) {
    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        // unAuthCallback(response)
        return;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function editCard(data) {

  const dataToServer = processParamsToServer(data)
  const response = await editCardCall({ data: dataToServer });

  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function newCard(data) {

  const dataToServer = processParamsToServer(data)
  const response = await newCardCall({ data: dataToServer });

  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function purchaseBoost(data) {

  const response = await purchaseBoostCall(data);

  if (response ) {

    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        // unAuthCallback(response)
        return response;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function purchaseEdit({ type, action }) {

  const response = await purchaseEditCall({ type, action });

  if (response ) {

    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        // unAuthCallback(response)
        return response;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}
