import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import TicketsTable from './TicketsTable'
import { playerZendeskTickets } from '../../../../models/Zendesk/Tickets'

import IMLoader from '../../../common/IMLoader';

const DateItem = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '266%',
  /* or 32px */
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '1px',
  textTransform: 'uppercase',

  /* Theme/Secondary */

  color: '#6C757D'
}));
const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  backgroundColor: 'transparent !important',
  textAlign: 'center',
  // color: theme.palette.text.secondary,
  // marginLeft: '23px',
  // marginTop: '40px',
  // width: '260px',
  height: '67px',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '120%',
  display: 'flex',
  alignItems: 'center',
  color: '#212529',
  flex: 'none',
  order: 0,
  background: '#ECEFF1',
  flexGrow: 0
}));
const AgentItem = styled(Link)(({ theme }) => ({
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '150%',
  cursor: 'pointer',
  /* or 24px */

  letterSpacing: '0.15px',

  /* Body Text/Body Color */

  color: '#212529'
}));
const DescriptionItem = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '150%',
  /* or 24px */

  letterSpacing: '0.15px',

  /* Body Text/Body Color */

  color: '#212529'
}));

const ContentItem = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  /* or 24px */

  letterSpacing: '0.15px',

  /* Body Text/Body Color */

  color: '#212529'
}));

export default function ZendeskContainer(props) {

  const [ tickets, setTickets ] = useState([])
  const [ assignees, setAssignees ] = useState(null)
  const [ zendeskUser, setZendeskUser ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ mount, setMount ] = useState(false)



  const setupZendeskPlayerTickets = async () => {
    setLoading(true)
    const zendeskResponse = await playerZendeskTickets({playerId: props.player.id})
    if (zendeskResponse && zendeskResponse.tickets) {
      setTickets(zendeskResponse.tickets)
      setAssignees(zendeskResponse.assignees)
      setZendeskUser(zendeskResponse.zendeskUser)
    } else {

    }
    setLoading(false)
    // setZendeskResponse(response)
  }

  useEffect(() => {
    if (!mount) {
      setMount(true)
      setupZendeskPlayerTickets()
    }
  })


  const filterContent = (text) => {
    return text.replace('Fuck', 'F$#@$')
  }
  function Ticket(props) {
    const ticket = props.ticket


    return (
      <Grid container spacing={0.5} key={`ticketContainer${props.index}`}>
        <Divider sx={{width: '-webkit-fill-available', margin: '2px'}}/>
        <Grid item xs={8}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Item><Chip
                onClick={() => window.open(ticket.ticketUrl, "_blank")}
                label={ticket.status.toUpperCase()}
                color={ticket.status === 'closed'
                          ? 'success'
                          : ticket.status === 'pending'
                            ? 'warning' : 'error'}/>
               </Item>
               <DateItem>{ticket.updatedAt}</DateItem>
            </Stack>
            <DescriptionItem>{`"${filterContent(ticket.description)}"`}</DescriptionItem>

            <AgentItem underline="hover" onClick={() => window.open(assignees[ticket.assigneeId].userUrl, "_blank")}>{`Agent assigned: ${assignees[ticket.assigneeId] ? assignees[ticket.assigneeId].name : 'No assigned Agent '}`}</AgentItem>

          </Stack>
        </Grid>

      </Grid>
    )

  }

  const title = () => {
    return zendeskUser
      ?   <AgentItem underline="hover" onClick={() => window.open(zendeskUser.userUrl, "_blank")}>
          {`Zendesk Tickets for User ${zendeskUser.name}`}
          </AgentItem>

      :  " Zendesk Tickets"
  }

  const ticketsRows = () => {
    const getReason = (reasons) => {
      return reasons.map((reason) => reason.replace('account_access', '').split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')).join(', ')
    }
    return tickets.map((ticket) => (
      {
        id: ticket.id,
        createdAt: ticket.createdAt,
        status: ticket.status,
        contactReason: getReason(ticket.tags)
      }
    ))
  }

  return (
    <Box>
      <Typography sx={{ fontWeight: 400,
                        fontSize: '24px',
                        lineHeight: '133.4%'}}>
      {title()}
      </Typography>
    {tickets.length > 0 &&  <TicketsTable rows={ticketsRows()}/>}
    { tickets.length > 0
      ? <Stack spacing={4}>
        {
          tickets.map((ticket, i) =>
            <Ticket key={`${i}Ticketkey`} index={Number(i)} ticket={ticket}/>
          )
        }
      </Stack>
      : loading
      ? <IMLoader message={'Fetching player tickets from Zendesk'} mini/>
      : <Typography sx={{ fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '133.4%'}}>
       No tickets
      </Typography> }

    </Box>
  )

}
