import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getRangedPlayersHealthScore, editPlayersHealthRange } from '../../models/Health/PlayerHealthScore'
import { syncMemberRedemptionsStatus } from '../../models/Health/MemberRedemptionsStatus'
import ConfirmDialog from '../common/ConfirmDialog'
import NumberInput from '../common/NumberInput'
import IMLoader from '../common/IMLoader'
import IMAlerts from '../common/IMAlerts';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

import {
  DataGrid,
} from '@mui/x-data-grid';

//


export default function PlayersHealth(props) {


  const [ loading, setLoading ] = useState(false)
  const [ playersHealth, setPlayersHealth ] = useState(null)
  const [ healthRangePercentages, setHealthRangePercentages ] = useState(null)
  const [ healthRangeScores, setHealthRangeScores ] = useState([])
  const [ loadingMessage, setLoadingMessage ] = useState('Fetching players health records ...')
  const [ range, setRange ] = useState('black')
  const [ totalNegative, setTotalNegative ] = useState(0)
  const [ totalPositive, setTotalPositive ] = useState(0)
  const [ dialogProps, setDialogProps ] = useState(null);
  const [ newRange, setNewRange ] = useState(null);
  const [ rangeMarks, setRangeMarks ] = useState([])
  const [ perc, setPerc ] = useState([])
  const [ serverHealthRangeScores, setServerHealthRangeScores ]= useState([])
  const [ alert, setAlert ] = useState(null)
  const [ pending, setPending ] = useState(false)
  const [ rangesSum, setRangesSum ] = useState(null)
  const [ bounds, setBounds ]  = useState([])

  const theme = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            // Match 24px = 3 * 2 + 1.125 * 16
            boxSizing: 'content-box',
            padding: 3,
            fontSize: '3.125rem',
          },
        },
      },
    },
  });

  const handleClick = (newRange) => () => {
    healthSetup(newRange)
  };
  const setupRanges = (totalNegative, totalPositive, newHealthRangeScores) => {

//  MAX POSITIVE == 55
// MAX NEAGTIVE -238
//  SCALE == 293
//  0 == -238
//  100 == 55
// f(X) = x - 238
    const scale = totalPositive - totalNegative
    setHealthRangeScores([100 * ((newHealthRangeScores[1] - totalNegative)/(scale)), 100 * ((newHealthRangeScores[0] - totalNegative)/scale)])

  }
  function valuetext(value) {
    // return value
    // const scale = totalPositive - totalNegative

    // const val = Math.trunc(((value/100)*scale)+(totalNegative))
    const val = Number(value) > 30 ? serverHealthRangeScores[1] : serverHealthRangeScores[0]

    return val
  }
  const healthSetup = async (newRange = null) => {
    if (loading ) {
      return
    }

    setLoading(true)

    if (!newRange) {
      newRange = range
    }
    setRange(newRange)
    setLoadingMessage('Fetching players health records ...')

    const response = await getRangedPlayersHealthScore({ range: newRange })

    if (response.status === 200 ) {
      if (response.playersHealth) {

          setPending(false)
          setPlayersHealth(response.playersHealth)
          setServerHealthRangeScores(response.healthRangeScores.map((val) => Number(val)))
          setHealthRangePercentages(response.healthRangePercentages)
          setTotalNegative(response.totalNegative)
          setTotalPositive(response.totalPositive)

          setupRanges(response.totalNegative, response.totalPositive, response.healthRangeScores)
          const scale = response.totalPositive - response.totalNegative
          const marks = response.healthRangeScores.map((range) => ({ value: 100 * ((range - response.totalNegative)/(scale)), label: range}))
          setRangeMarks(marks)
          setBounds(response.healthRangeScores)
          // setPerc(marks.map((val) => val.value))
          setPerc([30, 70])

          if (response.rangeBlack) {
            setRangesSum({ black: response.rangeBlack, grey: response.rangeGrey, white: response.rangeWhite })
          }
          // setRead(response.read)
          // setEdit(response.edit)
        // }
      }

    } else {
      setAlert({ display: true, severity: 'info', message: response.error, title: 'Players Health Update Status' })
      setPending(true)
      props.setPending(true)
      setPlayersHealth([])
    }
    setLoadingMessage(null)
    setLoading(false)
  }

  const onSyncRedmeptionsFromPlayersRange = async () => {
    setLoading(true)
    setLoadingMessage('Syncing redemptions statuses ...')

    const response = await syncMemberRedemptionsStatus()

    const message = response.status === 200 ? 'Redemptions sent to process' : response.error || 'Redemptions Failed to sync! please try again or contact an admin'
    const severity = response.status === 200 ? 'success' : 'error'

    if (response.status === 200) {
      setPending(true)
      props.setPending(true)
      setPlayersHealth([])
    }
    setAlert({display: true, severity, message, title: 'Redemption Status Sync'})
    setLoadingMessage(null)

    setLoading(false)
  }

  useEffect(() => {
    if (!loading && playersHealth === null && props.currentUser) {
      setLoading(true)
      healthSetup()
    }

  })
  const handleChangeScoresRange = (event, newValue) => {
    setHealthRangeScores(newValue);
  };

  const onChangeSliderCommitted =  (event, newValue) => {
    const scale = totalPositive - totalNegative

    let val1 = Math.trunc(((newValue[0]/100)*scale)+(totalNegative))
    let val2 = Math.trunc(((newValue[1]/100)*scale)+(totalNegative))

    setNewRange([val1, val2])

  }
  const onChangeRange =  (range) => (newValue) => {
    if (isNaN(newValue)) {
      return
    }

    const val1 = range == 'low' ? Number(newValue) : newRange == null || newRange[0] == null ? bounds[0] : newRange[0]
    const val2 = range == 'up' ? Number(newValue) : newRange == null || newRange[1] == null ? bounds[1] : newRange[1]

      setNewRange([ Number(val1), Number(val2)])

  }


  const onEditingRange = () => {
    if (newRange) {
      setDialogProps({
        title: `Adjust Scores GREY Range to ${newRange[0]} to ${newRange[1]}`,
        content: 'This action may cause some players to fall under differnt range'
      })
    }
  }

  function PlayerCell(props) {
    if (props.value == null) {
      return null;
    }

    return (
      <Link underline="none" target="_blank" href={`/player/${props.value}`}>{props.value}</Link>
    );
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'playerId', headerName: 'Player ID', width: 120, renderCell: (params) => <PlayerCell {...params} />  },

    { field: 'totalScore', headerName: 'Score', width: 120 },
    { field: 'healthRange', headerName: 'Health Range'}
  ];

  const onCloseDialog = () => {
    setDialogProps(null)
    setNewRange(null)
    setupRanges(totalNegative, totalPositive, serverHealthRangeScores)
    const scale = totalPositive - totalNegative
    const marks = serverHealthRangeScores.map((range) => ({ value: 100 * ((range - totalNegative)/(scale)), label: range}))

    setRangeMarks(marks)
    setPerc(marks.map((val) => val.value))

  }
  const onConfirmADialog = async () => {
    setLoading(true)
    setLoadingMessage('Editing Health Range')
    const changes = `Range changed from ${serverHealthRangeScores} to ${newRange}`
    const response = await editPlayersHealthRange({ range: newRange, changes })
    // console.log('RANGE EDIT RES ', response)
    let message = ''
    const severity = response.status === 200 ? 'success' : 'error'

    if (response.status === 200) {

      setupRanges(totalNegative, totalPositive, newRange)
      const scale = totalPositive - totalNegative
      const marks = newRange.map((range) => ({ value: 100 * ((range - totalNegative)/(scale)), label: range}))
      setRangeMarks(marks)
      setPerc(marks.map((val) => val.value))
      setNewRange(null)
      setDialogProps(null)
      setServerHealthRangeScores(newRange)
      setPending(true)
      props.setPending(true)
      message = 'Range is now being updated'
      await healthSetup()
    } else {
      message = 'Range failed to update'

      onCloseDialog()
    }
    setAlert({ display: true, severity, message })
    setLoadingMessage('')

    setLoading(false)

  }
  const onRefresh = async () => {
    healthSetup()

  }


  const onCloseAlert = () => {
    setAlert(null)
  }
  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
    {loadingMessage && <IMLoader message={loadingMessage} mini/>}
    {alert && alert.display &&
      <IMAlerts
        onClose={onCloseAlert}
        severity={alert.severity}
        title={alert.title}
        message={alert.message}/>}

    {dialogProps && <ConfirmDialog
        onCancel={onCloseDialog}
        contentConfirm={dialogProps.contentConfirm}
        {...dialogProps}
        onConfirm={onConfirmADialog}
        enableConfirm={true}
        />}
    <Box sx={{ flexGrow: 1 }}>

      {playersHealth && !loading && !pending &&
        <Stack direction="column" spacing={10}>
          <Stack direction="row" spacing={20}>
            <ThemeProvider theme={theme}>
               <Chip label={rangesSum ? rangesSum.black : ''} icon={<HeartBrokenIcon color="error"/>}  onClick={handleClick('black')} variant={range === 'black' ? 'filled': "outlined"}/>

               <Chip label={rangesSum ? rangesSum.grey : ''} icon={<FavoriteIcon color="disabled"/>}  onClick={handleClick('grey')} variant={range === 'grey' ? 'filled': "outlined"}/>
               <Chip label={rangesSum ? rangesSum.white : ''} icon={<FavoriteBorderIcon color="success"/>} onClick={handleClick('white')} variant={range === 'white' ? 'filled': "outlined"}/>

           </ThemeProvider>
           {!loading && healthRangeScores && !pending &&
             <Box sx={{ width: 900 }}>
              { <Slider
                getAriaLabel={() => 'Scores Range'}
                value={[30, 70]}
                onChange={handleChangeScoresRange}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                valueLabelFormat={valuetext}
                onChangeCommitted={onChangeSliderCommitted}
                size="medium"
                step={0.01}
                disableSwap
                disabled
                sx={{
               "& .MuiSlider-track": {
                 background: "grey",
                 borderColor: "white"
               },
               "& .MuiSlider-thumb": {
                 [`&:nth-of-type(${1}n)`]: {
                   background: "red",
                   "& span": {
                     background: "red"
                   }
                 },
                 [`&:nth-of-type(${2}n)`]: {
                   background: "green",
                   "& span": {
                     background: "green"
                   }
                 }
               },
               "& .MuiSlider-mark": {
                 background: "none"
               },
               "& .MuiSlider-rail": {
                 background: `linear-gradient(to right, red 0% ${perc[0]}%, grey ${perc[0]}% ${perc[1]}%, green ${perc[1]}% 100%)`
               },
               "& .MuiSlider-valueLabel": {},
             }}

              />}
                {bounds && <Stack direction="row" spacing={10}>
                 <NumberInput value={newRange  ? newRange[0] : bounds[0]} customColor='red'  onChangeCustom={onChangeRange('low')} />
                 <NumberInput value={newRange  ? newRange[1] : bounds[1]}  customColor='green' onChangeCustom={onChangeRange('up')} />
                  <Button variant="outlined" onClick={onEditingRange} disabled={!newRange ||  (newRange[0] &&  newRange[1] &&  newRange[0] >  newRange[1])}>
                    Edit Range
                  </Button>
               </Stack>}


           </Box>}

          </Stack>

        </Stack>

      }
      <Stack direction="row" spacing={120} sx={{margin: 2}}>
        <Button variant="outlined" onClick={onRefresh} disabled={loading}>
           Refresh From Database
         </Button>
          <Button variant="outlined" onClick={onSyncRedmeptionsFromPlayersRange} disabled={loading || pending}>
            Sync Redemptions Statuses from current players health range
          </Button>
      </Stack>


      {!loading && playersHealth && range && !pending && <Grid container spacing={0}>
         <Grid item xs={3}>
           <Typography sx={{fontSize: '24px', fontWeight: 700, margin: 3,
         }} color={range === 'black' ? "error" : range === 'grey' ? 'grey' : 'green'} >
                {`Players ${range.toUpperCase()} Health `}
            </Typography>
         </Grid>
       </Grid>}


     </Box>

    { pending
      ? <Typography sx={{fontSize: '24px', fontWeight: 700, marginTop: 5,
        }} color="text.primary" >
           {'Players Health Scores Are being updated, please wait and then try refreshing the page again later '}
       </Typography>
      : playersHealth && !loading && <DataGrid
        editMode="row"
        rows={playersHealth}
        columns={columns}
        sx={{backgroundColor: 'white', borderColor: 'transparent'
        }}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount
      />}

    </Box>
  );
}
