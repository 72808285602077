import { getUserLogsCall } from '../api/UserLog'
import { processResponse, toCamelCase, getDateTime } from './Utils'


export async function getUserLogs({ logsType }) {

  const response = await getUserLogsCall({ logsType });

  if (response ) {
    if (response && !response.error && response.status === 200) {
      // localStorage.setItem("token", newToken);
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        // unAuthCallback(response)
        return;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}
