import axios from 'axios';
import { getHeaders,  baseUrl } from '../utils';

export async function playerNotesCall(playerId) {

  try {

    const response  = await fetch(`${baseUrl}/player_notes/${playerId}`,{
      method: "GET",
      credentials: 'include',
      headers: getHeaders(),
    })


    const responseBack = await response.json()
    // console.log('BACK ???? ', responseBack)
    return responseBack
  } catch (err) {
    return { status: 401 };

  }
}


export async function addNoteCall({ playerId, note, zendeskTicketNumber, admin }) {
  try {
    const response  = await fetch(`${baseUrl}/add_note/${playerId}`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
       body: JSON.stringify({
         note: { note, zendesk_ticket_number: zendeskTicketNumber, admin }
       })
      }
    )
    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}
