import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

// import { getPrizes } from '../../models/Prize'
import GiftCards from './GiftCards'
import History from './History'
import DevSettings from './DevSettings'
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ConstructionIcon from '@mui/icons-material/Construction';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material-next/Chip';
import ImageListItem from '@mui/material/ImageListItem';


function TabPanel(props) {
  const { children, tabView, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabView !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabView === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabView: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function RewardMaxContainer(props) {

  const [ loading, setLoading ] = useState(false)
  const [ read, setRead ] = useState(false)
  const [ edit, setEdit ] = useState(false)
  const [ tabView, setTabView ] = React.useState(0)

  const [ refreshToggle, setRefreshToggle ] = useState(false)

  // const [ currentCards, setCurrentCards ] = useState(null)
  const [ globalVals, setGlobalVals ] = useState(null)

  const handleChangeTab = (event, newValue) => {
    setTabView(newValue);
  };

  useEffect(() => {

  })


  return (

    <Box sx={{ marginLeft: 8}}>
      <Box sx={{ width: '100%' }}>
       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Tabs value={tabView} onChange={handleChangeTab} aria-label="basic tabs example">
           <Tab icon={<CreditCardIcon />} iconPosition="start"  label="GIFT CARDS" {...a11yProps(0)} />
           <Tab icon={<ViewModuleIcon />} iconPosition="start"  label="TLC CREDITS" {...a11yProps(1)} />
           <Tab icon={<AccessTimeIcon />} iconPosition="start"  label="HISTORY" {...a11yProps(2)} />
           <Tab icon={<ConstructionIcon />} iconPosition="start"  label="DEV SETTINGS" {...a11yProps(3)} />

         </Tabs>
       </Box>
       <TabPanel tabView={tabView} index={0}>
         <Box sx={{margin: 1

         }}>{
            <GiftCards
                currentUser={props.currentUser}
                editAll={true}
                refresh={refreshToggle}
                globalVals={globalVals}
                setGlobalVals={setGlobalVals}
                unAuthResponse={props.unAuthResponse}
              />
               }
        </Box>
       </TabPanel>

       <TabPanel tabView={tabView} index={1}>
       </TabPanel>

       <TabPanel tabView={tabView} index={2}>
       <History
        unAuthResponse={props.unAuthResponse}/>
       </TabPanel>

       <TabPanel tabView={tabView} index={3}>
       <Box sx={{margin: 1

       }}>{
          <DevSettings
              currentUser={props.currentUser}
              editAll={true}
              refresh={refreshToggle}
              globalVals={globalVals}
              setGlobalVals={setGlobalVals}
              unAuthResponse={props.unAuthResponse}
            />
             }
      </Box>
       </TabPanel>

     </Box>

    </Box>

  )
}
