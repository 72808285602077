import React, {useContext} from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";

import FeedVersionDropdown from "./FeedVersionDropdown";
import CompleteOnboardingButton from "./CompleteOnboardingButton";
import PlayerTimestamp from "../../PlayerTimestamp";

import {OnboardingContext} from "./OnboardingContext";

const Placeholder = () => <Typography>-</Typography>;

const InfoList = styled("dl")`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  gap: 2px 10px;
`;
const InfoTerm = styled("dt")`
  color: ${({theme}) => theme.palette.grey[700]};
`;
const InfoDetails = styled("dd")`
  margin: 0;
`;

const OnboardingTimestamp = ({property}) => {
  const onboarding = useContext(OnboardingContext);
  if (onboarding[property] == null) {
    return <Placeholder/>;
  }
  return <PlayerTimestamp date={onboarding[property]}/>;
};

const TLCCreditStatus = () => {
  const onboarding = useContext(OnboardingContext);
  if (onboarding.completed_at == null) {
    return <Placeholder/>;
  }
  if (onboarding.tlc_credit_at == null) {
    return (
      <Stack direction="row" spacing={0.5}>
        <ClearIcon color="warning"/>
        <Typography>Earned</Typography>
      </Stack>
    );
  }
  return (
    <PlayerTimestamp date={onboarding.tlc_credit_at}>
      <Stack direction="row" spacing={0.5}>
        <CheckIcon color="success"/>
        <Typography>Earned</Typography>
      </Stack>
    </PlayerTimestamp>
  );
};

const DoubleRewardsStatus = () => {
  const onboarding = useContext(OnboardingContext);
  if (onboarding.completed_at == null) {
    return <Placeholder/>;
  }
  if (onboarding.double_rewards_at == null) {
    return (
      <Stack direction="row" spacing={0.5}>
        <ClearIcon color="warning"/>
        <Typography>No</Typography>
      </Stack>
    );
  }
  return (
    <PlayerTimestamp date={onboarding.double_rewards_at}>
      <Stack direction="row" spacing={0.5}>
        <CheckIcon color="success"/>
        <Typography>Yes</Typography>
      </Stack>
    </PlayerTimestamp>
  );
};

const OnboardingStatus = () => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <InfoList>
      <InfoTerm>Started at:</InfoTerm>
      <InfoDetails>
        <OnboardingTimestamp property="started_at"/>
      </InfoDetails>
      <InfoTerm>Completed at:</InfoTerm>
      <InfoDetails>
        <OnboardingTimestamp property="completed_at"/>
      </InfoDetails>
    </InfoList>

    <InfoList>
      <InfoTerm>TLC Credit:</InfoTerm>
      <InfoDetails>
        <TLCCreditStatus/>
      </InfoDetails>
      <InfoTerm>Double Rewards:</InfoTerm>
      <InfoDetails>
        <DoubleRewardsStatus/>
      </InfoDetails>
    </InfoList>

    <Stack direction="row" spacing={2}>
      <FeedVersionDropdown/>
      <CompleteOnboardingButton/>
    </Stack>
  </Stack>
);

export default OnboardingStatus;
