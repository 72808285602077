import React, {useState} from "react";
import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";
import Button from "@mui/material/Button";
import {onUpdateDevSetting} from "../../../../models/DevSetting";
import {onUpdateLrAdminDevSetting} from "../../../../models/DevSetting";
import Stack from "@mui/material/Stack";
import {Typography} from "@material-ui/core";

export default function SaveDevSetting({config, playerId, edit}) {
  const [saving, setSaving] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const LrAdminSettings = ["redemption_status"]

  function getRecords() {
    return Object.entries(config).filter(([_, entry]) => entry.value != null && entry.value !== "").map(([key, config]) => ({
      ...config,
      name: key,
      player_id: playerId,
    }));
  }

  function formatLrAdminDevSetting(dev_settings) {
    const result = {};

    result[dev_settings[0]["player_id"]] = Object.assign({}, ...dev_settings.map(setting => ({[setting.name]: setting.value})))

    return result;
  }

  async function handleSave() {
    const dev_settings = getRecords();

    if (dev_settings.length !== Object.keys(config).length) {
      setInvalid(true);
      return;
    }

    setInvalid(false);
    setSaving(true);
    await onUpdateDevSetting({dev_setting: dev_settings});

    if (LrAdminSettings.some(setting=> Object.keys(config).includes(setting))) {
      await onUpdateLrAdminDevSetting( {dev_setting: formatLrAdminDevSetting(dev_settings)});
    }

    setSaving(false);
  }

  return (
    <Stack direction="row">
      <Button variant="contained" size="small" onClick={handleSave} disabled={saving || !edit}>
        {saving ? "Saving..." : "Save"}
      </Button>
      {invalid && (
        <Stack sx={{ ml: 2 }} direction="row">
          <DangerousRoundedIcon color="error" sx={{ mr: 1 }} />
          <Typography style={{ fontStyle: "italic" }}>Complete all fields before saving.</Typography>
        </Stack>
      )}
    </Stack>
  )
}
