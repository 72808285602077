import Cookies from 'js-cookie';

export const getHeaders = () => {
  // const csrfToken = token || document.querySelector('meta[name="csrf-token"]').content;
  const headers = {
    // 'X-CSRF-Token': csrfToken,
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
    "Content-Security-Policy": "frame-ancestors 'self';",
    'X-Frame-Options': 'sameorigin',
    'X-Content-Type-Options': 'nosniff',
    'credentials': 'include',
    // "X-CSRF-Token": Cookies.get("CSRF-TOKEN")
    // 'Authorization': sessionStorage.getItem("token"),
    // 'Authorization': Cookies.get('_jaris_session'),
  }

  return headers
}

export const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001'
export const iPermUrl = process.env.REACT_APP_API_I_PERM_URL || 'http://localhost:3000'
