import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { green, red, orange, deepOrange } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

// import CircularProgress, {
//   circularProgressClasses,
// } from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';



export default function ProgressScoreBar(props) {


  const getColorSpectrum = () => {
    const precentageValue = props.success
      ? 900  * (100 * (props.sum) / props.total) / 100
      : 900  * (100 * -1 * (props.sum) / props.total) / 100
    return (precentageValue - precentageValue % 100) < 51 ? 100 : precentageValue - precentageValue % 100

  }
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: props.success
      ? green[50]
      : deepOrange[50]
      ,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: props.success
      ? green[getColorSpectrum()]
      : deepOrange[ getColorSpectrum() ],
    },
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 390,
          height: 68,
        },
      }}
    >
      <Paper elevation={0} >
        <Box sx={{margin: 2}}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
            <Typography sx={{fontSize: '20px', fontWeight: 700,
              }} color="text.primary" >
                {props.title}
             </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" >
                {`${Math.abs(props.sum)} / ${props.total}`}
              </Typography>
            </Grid>
          </Grid>

          <BorderLinearProgress variant="determinate" value={props.sum >= props.total ? 100 : props.error ? 100 * -1 * (props.sum) / props.total : 100 * (props.sum) / props.total} />
        </Box>
      </Paper>
    </Box>
  );
}
