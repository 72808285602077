import axios from 'axios';
import { getHeaders,  baseUrl } from '../utils';



export async function getPlayerScoreFlagsCall(playerId) {
  try {
    const response  = await fetch(`${baseUrl}/player_health/${playerId}`,{
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',

      }
    )

    const responseBack = await response.json()
    // console.log('RESOINSE ????', responseBack)

    return responseBack
  } catch (err) {
    return { err };

  }
}

export async function getAllPlayersScoreFlagsCall({ range }) {
  try {
    const data = { range }
    const response  = await fetch(`${baseUrl}/all_players_health`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data
      })
      }
    )

    const responseBack = await response.json()
    // console.log('RESOINSE ????', responseBack)

    return responseBack
  } catch (err) {
    return { err };

  }
}
