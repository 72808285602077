import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMemberRedemptionsStatus } from '../../models/Health/MemberRedemptionsStatus'
import { formatDate, formatTime } from '../common/Utils'
import { useAsyncDebounce } from 'react-table'

import IMLoader from '../common/IMLoader'
import IMAlerts from '../common/IMAlerts';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

import {
  DataGrid,
} from '@mui/x-data-grid';

//


export default function RedemptionsStatus(props) {


  const [ loading, setLoading ] = useState(false)
  const [ redemptions, setRedemptions ] = useState(null)
  const [ redemptionsStatuses, setRedemptionsStatuses ] = useState(null)
  const [ status, setStatus ] = useState('ready_to_process')
  const [ loadingMessage, setLoadingMessage ] = useState('Fetching redemptions statuses ...')
  const [ pending, setPending ] = useState(false)
  const [ alert, setAlert ] = useState(null)
  const [ playerFilter, setPlayerFilter ] = useState(null)

  const theme = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            // Match 24px = 3 * 2 + 1.125 * 16
            boxSizing: 'content-box',
            padding: 3,
            fontSize: '3.125rem',
          },
        },
      },
    },
  });
  const handleClick = (newStatus) => () => {
    setPlayerFilter(null)
    redemptionsSetup({ newStatus })
  };
  const searchPlayerFilterEnter = (e) => {
    if (e.keyCode == 13) {
      // console.log('PLAYER FILTER!', playerFilter)
      redemptionsSetup({ player: playerFilter })
    }
  }
  const redemptionsSetup = async ({ newStatus, player }) => {
    if (loading ) {
      return
    }

    setLoading(true)

    if (!newStatus) {
      newStatus = status
    }

    setStatus(newStatus)
    setLoadingMessage('Fetching redemptions statuses records ...')
    // player = 7725697

    const response = !player ? await getMemberRedemptionsStatus({ status: newStatus }) : await getMemberRedemptionsStatus({ player })
    // const response = await getMemberRedemptionsStatus({ player: 7725697 })

    // console.log('ALL statuses RESPONSE ', response)

    if (response.status === 200 ) {
      if (response.memberRedemptionsStatus) {
          setPending(false)
          setRedemptionsStatuses(response.memberRedemptionsStatus)
          setRedemptions(response.memberRedemptionsStatus)
      }

    } else {
      setAlert({ display: true, severity: 'info', message: response.error })
      setPending(true)
      // props.setPending(true)
      setRedemptions([])
    }
    setLoadingMessage(null)
    setLoading(false)
  }

  useEffect(() => {
    if (!loading && redemptions === null && props.currentUser) {
      setLoading(true)
      redemptionsSetup({})
    }

  })
  const onCloseAlert = () => {
    setAlert(null)
  }
  function DateCell(props) {
    if (props.value == null) {
      return null;
    }
    return `${formatDate(new Date(props.value))} - ${formatTime(new Date(props.value))}`
  }
  function PlayerCell(props) {
    if (props.value == null) {
      return null;
    }

    return (
      <Link underline="none" target="_blank" href={`/player/${props.value}`}>{props.value}</Link>
    );
  }
  const onChangeText = useAsyncDebounce(value => {
    setPlayerFilter(value.trim() === true ? '' : value || '')

  }, 200)
  const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'memberId', headerName: 'Member ID', width: 120  },
    { field: 'playerId', headerName: 'Player ID', width: 120, renderCell: (params) => <PlayerCell {...params} />  },
    { field: 'redemptionStatus', headerName: 'Redemption Status', width: 220 },

    { field: 'redemptionId', headerName: 'Redemption ID', width: 120 },
    { field: 'redemptionStatusUpdatedAt', headerName: 'Updated At', width: 220, renderCell: (params) => <DateCell {...params} />  },

    { field: 'lastNote', headerName: 'Last Note', width: 520}
  ];


  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
    {loadingMessage && <IMLoader message={loadingMessage} mini/>}
    {alert && alert.display &&
      <IMAlerts
        onClose={onCloseAlert}
        severity={alert.severity}
        message={alert.message}/>}
    <Box sx={{ flexGrow: 1 }}>
      {redemptions && !loading && !pending &&
        <Stack direction="column" spacing={10}>
          <Stack direction="row" spacing={20}>
            <ThemeProvider theme={theme}>
               <Chip label={'Invalid Request'}
                  variant={status === 'invalid_request' ? 'filled': "outlined"}
                  icon={<HeartBrokenIcon color="error"/>}
                  onClick={handleClick('invalid_request')} />

               <Chip label={'Needs Review'}
                      variant={status === 'needs_review' ? 'filled': "outlined"}
                      icon={<FavoriteIcon color="disabled"/>}
                      onClick={handleClick('needs_review')} />
               <Chip label={'Ready To Process'}
                      variant={status === 'ready_to_process' ? 'filled': "outlined"}
                      icon={<FavoriteBorderIcon color="success"/>}
                      onClick={handleClick('ready_to_process')} />

           </ThemeProvider>
           <TextField
              label="Search by Player"
              id="filled-start-adornment"
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              }}
              variant="outlined"
              onChange={e => {
                onChangeText(e.target.value);
              }}
              onKeyDown={searchPlayerFilterEnter}
            />
           <Button variant="outlined"  disabled={true}>
              Refresh From Database
            </Button>
          </Stack>

        </Stack>

      }

      {!loading && redemptions && status && !pending && <Grid container spacing={0}>
         <Grid item xs={8}>
           <Typography sx={{fontSize: '24px', fontWeight: 700, margin: 3,
         }} color={playerFilter ? 'black' : status === 'invalid_request' ? "error" : status === 'needs_review' ? 'grey' : 'green'} >
                {playerFilter ? `Player ${playerFilter} Redemptions Statuses`: `${status.toUpperCase()} Redemptions Statuses `}
            </Typography>
         </Grid>
       </Grid>}


     </Box>

    { pending
      ? <Typography sx={{fontSize: '24px', fontWeight: 700, marginTop: 5,
        }} color="text.primary" >
           {'Redemptions statuses are being updated, please wait and then try refreshing the page again later '}
       </Typography>
      : redemptions && !loading && <DataGrid
        editMode="row"
        rows={redemptions}
        columns={columns}
        sx={{backgroundColor: 'white', borderColor: 'transparent'
        }}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount
      />}

    </Box>
  );
}
