import React from 'react';
import ReactDOM from 'react-dom/client';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
// import './styles.scss'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const config = {
    apiKey: 'hbp_wVrArxlV05vwyV6mgZTfVqhIuIoTdC29KBD1',
    environment: process.env.RAILS_ENV || 'development',
    revision: 'git SHA/project version'
}
const honeybadger = Honeybadger.configure(config)
// Honeybadger.notify("Testing Honeybadger!");
console.log('HONEY ', honeybadger)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HoneybadgerErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
