import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { formatDate, formatTime } from '../common/Utils'

import IMLoader from '../common/IMLoader'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  DataGrid,
} from '@mui/x-data-grid';



export default function ScoreFlagsLogs(props) {

  const [ allRows, setAllRows ] = useState(props.allRows);
  const [ loading, setLoading ] = useState(false)
  const [ logsGridRows, setLogsGridRows ] = useState(null)

  const theme = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            // Match 24px = 3 * 2 + 1.125 * 16
            boxSizing: 'content-box',
            padding: 3,
            fontSize: '1.125rem',
          },
        },
      },
    },
  });

  useEffect(() => {
    setLoading(true)
    const newRows = props.allRows.map((row) => ({
      id: row.id,
      userName: row.userName,
      flag: row.description,
      note: row.note,
      updatedAt: row.updatedAt
    }))
    setLogsGridRows(newRows)
    setLoading(false)

  })


  function DateCell(props) {
    if (props.value == null) {
      return null;
    }
    return `${formatDate(new Date(props.value))} - ${formatTime(new Date(props.value))}`
  }
  const columns = [
    { field: 'id', headerName: 'Log ID', width: 100 },
    { field: 'userName', headerName: 'User', width: 200},
    { field: 'flag', headerName: 'Flag', width: 680},
    { field: 'note', headerName: 'Note Changes', width: 420 },
    { field: 'updatedAt', headerName: 'Updated At', width: 220, renderCell: (params) => <DateCell {...params} /> },

  ];


  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
    {loading && <IMLoader message={'Loading Logs...'}/>}


    { logsGridRows && <DataGrid
        editMode="row"
        rows={logsGridRows}
        columns={columns}
        sx={{backgroundColor: 'white', borderColor: 'transparent'
        }}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount
      />}
    </Box>
  );
}
