import React, { useEffect, useState } from 'react';
import IMLoader from '../common/IMLoader'
import Box from '@mui/material/Box';

import PlayerGrave from './PlayerGrave'
import Player from './Player'

export default function PlayerContainer(props) {
  const [ player, setPlayer ] = useState(null)
  const [ playerType, setPlayerType ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  const [ forceLoad, setForceLoad ] = useState(false)


  const getPlayer = async () => {

    setLoading(true)
    const responseStatus = await props.getPlayer()
    setLoading(false)
  }
  useEffect(() => {
    if (props.selectedPlayer && (!player || player.id !== props.selectedPlayer.id )) {
      setPlayer(props.selectedPlayer)
      setPlayerType(props.searchedPlayersType)
    } else {
      if (!player) {
        getPlayer()
      }
    }
  });


  return (
    <Box>
      {loading || !player || !playerType ? (
        <IMLoader message={"Loading Player..."} />
      ) : playerType === "PlayerGraveyards" ? (
        <PlayerGrave {...props} player={player} />
      ) : (
        <Player {...props} player={player} currentUser={props.currentUser} />
      )}
    </Box>
  )
}
