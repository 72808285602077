import axios from "axios";

import { baseUrl, getHeaders } from "./utils";

export function createClient(baseURL) {
  return axios.create({
    baseURL,
    headers: getHeaders(),
    withCredentials: true,
  });
}

export const client = createClient(baseUrl);

export const v2Client = createClient(`${baseUrl}/v2`);
