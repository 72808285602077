import {getDevSettingsCall, updateDevSettingCall, updateLrAdminDevSettingCall} from '../api/DevSetting'
import { processResponse } from './Utils'


export async function getDevSettings(player) {

  const response = await getDevSettingsCall(player);

  if (response ) {
    if (response && !response.error && response.status === 200) {

      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        return;
      }
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function onUpdateDevSetting({ dev_setting }) {
  const response = await updateDevSettingCall({ dev_setting });

  if (response ) {
    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        let res = {}
        Object.keys(response).map((key) => res[key] = processResponse(response[key]))

        return res;
      }
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function onUpdateLrAdminDevSetting({ dev_setting }) {
  const response = await updateLrAdminDevSettingCall({ dev_setting });

  if (response ) {
    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        let res = {}
        Object.keys(response).map((key) => res[key] = processResponse(response[key]))

        return res;
      }
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}
