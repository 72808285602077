import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import wave from '../../assets/ArmadaWave.png';
import grin from '../../assets/mobile/grin.png';
import hide from '../../assets/mobile/Hide.png';
import IMLoader from '../common/IMLoader'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import AddIcon from '@mui/icons-material/Add';
import Announcements from './Announcements';
import Announcement from './Announcement'

export default function AnnouncementsContainer(props) {

  const [ currentView, setCurrentView ] = useState('announcements')
  const [ announcement, setAnnouncement ] = useState(null)
  const [ viewTitle, setViewTitle ] = useState('')
  const [ announcementAction, setAnnouncementAction ] = useState(null)
  const [ actionBtns, setActionBtns ] = useState([])

  const TypeCircles = {
     'news': '🟢',
     'critical': '🔴',
     'alert': '🟡'
  }
  const TypeOptions = [
     { value: 'news', label: 'News   🟢' },
     { value: 'critical', label: 'Critical   🔴' },
     {value: 'alert', label: 'Alert   🟡'}
  ]

  const IllustrationOptions = [
     { value: 'wave', label: 'Armanda waving', icon: wave },
     { value: 'grin', label: 'Grin', icon: grin },
     { value: 'hide', label: 'Hide', icon: hide },
  ]

  const CountryOptions = [
    { value: 'US', label: 'US' },
    { value: 'CA', label: 'CA' },
    { value: 'AU', label: 'AU' },
    { value: 'GB', label: 'GB' },

  ]

  const DEFAULT_ANNOUNCEMENT_FIELDS = {
    'id': null,
    'type': 'alert',
    'title': 'Scheduled Maintenance',
    'illustration': 'wave',
    'message': 'During this maintenance period, our app will be temporarily unavailable. We apologize for any inconvenience this may cause, but please note that this maintenance is necessary to improve the performance and stability of our platform.',
    'button': 'Ok, Thanks!',
    'fromDate': dayjs((new Date())) ,
    'toDate': dayjs((new Date())) ,
    'fromTime': new Date() ,
    'toTime': new Date(Date.now() + ( 10 * 60 * 1000)) ,
    'country': 'US' ,
    'displaySchedule': true

  }

  useEffect(() => {

  });


  const actionButtons = (action) => {
    const btnsObjects = {
      'schedule': {value: 'Schedule', color: 'success'},
      'stop': {value: 'Stop', color: 'error'},
      'delete': {value: 'Delete', color: 'error'},
      'update': {value: 'Update', color: 'success'},

    }
    switch (action.toLowerCase()) {
      case 'new':
      case 'clone':
        return [btnsObjects['schedule']]
      case 'stop':
        return [btnsObjects['stop']]
      case 'delete':
        return [btnsObjects['delete']]
      case 'review':
        return [btnsObjects['update'], btnsObjects['delete']]
      case 'view':
        return []
      case 'edit':
      case 'review / edit':
        return [btnsObjects['update'], btnsObjects['delete']]
    }
  }
  const announcementFromRow = (row, action) => {
    return {
      'id': action === 'clone' ? null : row.id,
      'type': row.type,
      'title': row.title,
      'illustration': row.illustration,
      'message': row.message ,
      'button': row.button ,
      'displaySchedule': row.displaySchedule ,
      'fromDate': action === 'clone' ? dayjs((new Date())) : row.fromDate,
      'toDate': action === 'clone' ? dayjs((new Date())) : row.toDate,
      'fromTime': action === 'clone' ? new Date() : row.fromTime,
      'toTime': action === 'clone' ? new Date(Date.now() + ( 10 * 60 * 1000)) : row.toTime,
      'country': row.country ,
      'dynamoid': row.dynamoid
    }
  }
  const onNewAnnouncementCall = () => {
    setAnnouncement(DEFAULT_ANNOUNCEMENT_FIELDS)
    setViewTitle('NEW ANNOUNCEMENT')
    setAnnouncementAction('new')
    setCurrentView('announcement')
    setActionBtns(actionButtons('new'))
  }
  const onAnnouncementActionCall = (announcement, action) => {
    if (!announcement || !action) {
      return
    }
    const announcementRow = announcementFromRow(announcement, action)
    // console.log('NAVIGTAE TO ACTION !!! ', announcement, action)

    setAnnouncement(announcementRow)
    setViewTitle(announcementRow.title)
    setAnnouncementAction(action)
    setCurrentView('announcement')
    setActionBtns(actionButtons(action))
  }
  const onAnnouncementsCall = () => {
    setAnnouncement(null)
    setViewTitle(null)
    setAnnouncementAction(null)
    setCurrentView('announcements')
    setActionBtns([])
  }
  const isReadOnly = () => {
    const started = announcement.fromDate < new Date()
    const editAction = actionBtns && actionBtns.length > 0 && actionBtns[0].value === 'Update'
    const schedule = actionBtns && actionBtns.length > 0 && actionBtns[0].value === 'Schedule'
    return schedule ? false : editAction ? started : true
  }
  return (
    <>
    {
      currentView === 'announcement'
      ? <Announcement
          title={viewTitle}
          actionBtns={actionBtns}
          illustrationOptions={IllustrationOptions}
          typeOptions={TypeOptions}
          action={announcementAction}
          onAction={props.onAnnouncementChange}
          getActionButtons={actionButtons}
          onAnnouncementsCall={onAnnouncementsCall}
          readOnly={isReadOnly()}
          fields={announcement}/>
      : <Announcements
          onAnnouncementActionCall={onAnnouncementActionCall}
          onNewAnnouncementCall={onNewAnnouncementCall}
          typeOptions={TypeCircles}/>
    }
    </>
  )
}
