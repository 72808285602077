import React, { useEffect, useState } from 'react';


import {
  DataGrid,
} from '@mui/x-data-grid';
import {

  randomId,
} from '@mui/x-data-grid-generator';
import ProgressScoreBar from '../../../Health/ProgressScoreBar'
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Chip from '@mui/material-next/Chip';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



function WeightCell(props) {

  if (props.value == null) {
    return null;
  }

  return (
    <Chip label={props.value} color={props.value > 0 ? "success" : "error"} />
  );
}
const columns = [
  { field: 'description', headerName: 'What', width: 450 },
  { field: 'weight', headerName: 'Weight', width: 80, type: 'number',       renderCell: (params) => <WeightCell {...params} />}
]
export default function HealthCard(props) {
  const [ playerHealth, setPlayerHealth ] = useState(null)
  const [ scores, setScores ] = useState(null)
  const [ starsRows, setStarsRows ] = useState(null)
  const [ strikeRows, setStrikeRows ] = useState(null)

  const healthSetup = () => {
    let rows = []
    Object.keys(props.playerHealth).forEach((healthKey) => {
      if (healthKey === 'bad' || healthKey === 'good'){
        props.playerHealth[healthKey].forEach((record) => {
          rows.push({
            id: record.id,
            description: record.scoreFlag.description,
            weight: record.scoreFlag.weight,
            scoreType: healthKey
          })
        })

      }
    })
    setPlayerHealth(rows)
    setStarsRows(rows.filter((row) => row.scoreType === 'good'))
    setStrikeRows(rows.filter((row) => row.scoreType === 'bad'))

    setScores({
      bad: props.playerHealth.badScore,
      good: props.playerHealth.goodScore,
    })
  }

  useEffect(() => {

    if (playerHealth === null && props.playerHealth) {
      healthSetup()
    }

  })


  return (
    <>
    {!playerHealth
      ? <></>
      : <Card sx={{ width: '50%' }}>
      <CardHeader
        sx={{ bgcolor: red[500],
          "& .MuiCardHeader-avatar": {
           position: 'relative',
           marginLeft: 'auto',
           ineHeight: '32px',
           fontSize: '24px', fontWeight: 400,
          },
          "& .MuiCardHeader-title": {
            lineHeight: '32px',
            fontSize: '24px', fontWeight: 400,
          },
          "& .MuiCardHeader-content": {
            position: 'absolute !important',
            color: 'white',
          }}}
        avatar={
         <Avatar sx={{ bgcolor: red[500]  }} aria-label="recipe">
           {props.totalScore}
         </Avatar>
       }

       title="Health Score"
     />
      <CardContent>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
          <ProgressScoreBar
            success
            total={props.totalScoresSum.totalPositive}
            precentage={100 * scores.good / props.totalScoresSum.totalPositive}
            sum={scores.good}
            title={"Stars(+)"}/>
          </AccordionSummary>
          {starsRows && starsRows.length > 0 && <AccordionDetails>
            <DataGrid
              editMode="row"
              rows={starsRows}
              columns={columns}
              sx={{backgroundColor: 'white', borderColor: 'transparent'
              }}

              hideFooterRowCount={true}
              hideFooterSelectedRowCount
            />
          </AccordionDetails>}
       </Accordion>

       <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
        <ProgressScoreBar
          error
          total={-1 * props.totalScoresSum.totalNegative}
          precentage={100 * (scores.bad) / ( props.totalScoresSum.totalNegative)}
          sum={scores.bad }
          title={"Strike(+)"}/>
        </AccordionSummary>
        {strikeRows && strikeRows.length > 0 && <AccordionDetails>
          <DataGrid
            editMode="row"
            rows={strikeRows}
            columns={columns}
            sx={{backgroundColor: 'white', borderColor: 'transparent'
            }}

            hideFooterRowCount={true}
            hideFooterSelectedRowCount
          />
        </AccordionDetails>}
        <AccordionActions>

        </AccordionActions>
      </Accordion>
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>}</>
  );
}
