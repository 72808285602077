import axios from 'axios';
import { getHeaders,  baseUrl } from '../utils';

export async function editPlayerProfileCall({ playerId, data }) {

  try {
    const response  = await fetch(`${baseUrl}/edit_player/${playerId}`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
       body: JSON.stringify({
         data
       })
      }
    )
    // console.log('RESOINSE ????', response)

    const responseBack = await response.json()
    return responseBack
  } catch (err) {
    return { err };

  }
}

export async function playerDeleteCall({ playerId }) {
  try {
    const response  = await fetch(`${baseUrl}/delete_player/${playerId}`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',

      }
    )
    // console.log('RESOINSE ????', response)

    const responseBack = await response.json()
    return responseBack
  } catch (err) {
    return { err };

  }
}


export async function getLRPlayerProfileCall({ playerId, preview, real }) {

  try {
    const response  = await fetch(`${baseUrl}/read_player/${playerId}`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        preview,
        real
      })
      }
    )

    const responseBack = await response.json()
    return responseBack
  } catch (err) {
    return { err };

  }
}
