import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/material/styles';


const Header = styled(Box)(({ theme }) => ({
  padding: '6px 16px',
  position: 'absolute',
  width: '1000px',
  height: '76px',

}))
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const advanced = require("dayjs/plugin/advancedFormat")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export default function PlayerGrave(props) {
  const [ showMore, setShowMore ] = useState(false)
  const navigate = useNavigate();

  const getRows = (players) => {

    return players.map((player) => {
      const date  =  (new Date(player.createdAt)).toLocaleString("sv-SE", {
        timeZone: "America/Los_Angeles"
      })
      return {id: player.playerId,
        deletedAt: `${date} PDT`,
        note: player.deleteNote}
    })
  }

  const RenderID = (props) => {
    const { value, row } = props;

    return (
      <Typography sx={{color: 'blue', cursor: 'pointer'}} onClick={() => navigate(`/player/${value}`)} >
        {value}
      </Typography>

    )
  }
  const column = [
  { field: 'id', headerName: 'Player ID', width: 330, renderCell: RenderID  },
  { field: 'deletedAt', headerName: 'Deleted at', width: 330 },
  { field: 'note', headerName: 'Notes', width: 330 },

  ]

  const onPlayerNavigate = () => {
    props.player.mergedInto.playerId ? navigate(`/player/${props.player.mergedInto.playerId}`) : navigate(`/player/${props.player.mergedInto.id}`)
  }
  return (
    <Box sx={{marginLeft: '32px', paddingBottom: 3, marginTop: '-35px'}}>
      <Header >
        <Stack spacing={0} direction="row">
          <Box sx={{padding: 1}}>
            <PersonIcon color={'error'} />
          </Box>
          <Stack spacing={0.5} sx={{padding: 1}}>
            <Typography sx={{fontSize: '24px', fontWeight: 700}}>
            {`Whoopsie-doodle! Guess what? `}
            </Typography>
            <Typography variant="body2" sx={{fontSize: '14px', fontWeight: 700}}>
            {`Player ${props.player.playerId} got a one-way ticket to the digital graveyard, but guess who just found their epic tombstone? Yep, it's you, my adventurous friend!`}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ height: 100, marginTop: 2,
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              height: 0,

          },
          '& .MuiDataGrid-virtualScroller':{
            height: 0,
          },
          }}>
          {props.player && <DataGrid
           rows={getRows([props.player])}
           hideFooterPagination
           hideFooter
           columns={column}
           />}
        </Box>
        <Box sx={{marginTop: 5}}>
        {props.player.merges && props.player.merges.length > 0
        ? <Box sx={{height: `${(props.player.merges.length + 1) * 50}px`}}>
          <Typography variant="h5" sx={{fontSize: '14px', fontWeight: 700, marginBottom: 2}}>
              Merged Into this account
          </Typography>
          <DataGrid
           rows={getRows(props.player.merges)}
           hideFooterPagination
           hideFooter
           columns={column}
           />
          </Box>
        : props.player.mergedInto
          ?  <Stack spacing={2} direction="row" sx={{alignItems: 'baseline'}}>
              <Typography variant="h5" sx={{fontSize: '14px', fontWeight: 700, marginBottom: 2}}>
                  Merged Into: Player
              </Typography>
              <Button
                variant="text"
                onClick={onPlayerNavigate}>
                {props.player.mergedInto.playerId || props.player.mergedInto.id}
            </Button>
            </Stack>
          : <></>}
        </Box>
        <Box sx={{marginTop: 7, paddingBottom: '100px'}}>
        <Grid container spacing={0.5} sx={{marginLeft: 1}}>
          <Grid item xs={11}>
            <Typography variant="h5" sx={{fontSize: '14px', fontWeight: 700, marginBottom: 2}}>
                Account Details
            </Typography>
          </Grid>
          <Grid item xs={1}>
          <Tooltip title={`${showMore ? 'Show Less' : 'Show More'}` }>

          <IconButton
            color='primary'
            onClick={() => setShowMore(!showMore)}

          >
            <VisibilityIcon />
          </IconButton>
          </Tooltip>
          </Grid>
          </Grid>
          <Collapse in={showMore} collapsedSize={400}>
          <List>
          <Grid container spacing={0.5} sx={{marginLeft: 1}}>
          {
            Object.keys(props.player.playerAttributes).map((attr) =>
              <><ListItem><Grid item xs={4}>
                <Typography variant="body2" sx={{fontSize: '14px'}}>
                    {attr}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" sx={{fontSize: '14px'}}>
                    {props.player.playerAttributes[attr] ? JSON.stringify(props.player.playerAttributes[attr]) : ''}
                </Typography>
              </Grid>
              <Grid item xs={4}></Grid>


              </ListItem>
                <Divider sx={{width: '-webkit-fill-available'}}/>
              </>
            )
          }
          </Grid>
          </List>
          </Collapse>

        </Box>
      </Header>

    </Box>
  )
}
//
// const PLAYER_ATTR = {
//
// accessToken:  null,
// acquisitionCost: 0,
// acquisitionSource: "transition",
// adTester: null,
// age: null,
// ageMax: null,
// ageMin: null,
// appsflyerId: "1591918658712-2437974575603955746",
// appsflyerIdfa: "bc9b90e6-1f6d-44e0-80ff-4192449e0fe3",
// appsflyerPlatform: "Android",
// appsflyerProgramSlug: "football-universal",
// attributionData: {afMessage: 'organic install', afStatus: 'Organic', isFirstLaunch: 'false'},
// attributionMediaSource: "Organic",
// birthday: "1990-01-01",
// blockIp: null,
// checkinCount: null,
// commissionTotal: null,
// confirmationSentAt: null,
// confirmationToken: null,
// confirmedAt: null,
// country: null,
// createdAt: "2020-06-11T16:37:38.590-07:00",
// currentSignInAt: null,
// currentSignInIp: null,
// developer: true,
// disabled: false,
// disabledAt: null,
// disabledReason: null,
// elite: false,
// eliteJoinedAt: null,
// eliteReservedAt: null,
// ep: null,
// exportedAt: null,
// firstGiftCardEarned: null,
// fraudLevel: null,
// gender: "female",
// homeCountry: "US",
// homeFeed: "feed8",
// id: 5966964,
// idfa: null,
// inAppPurchaseRevenue: 0,
// ipCountry: "US",
// ipLastLookup: "2020-08-25T13:29:20.126-07:00",
// ipState: "WA",
// ipTimeZone: "America/Los_Angeles",
// lastCheckin: null,
// lastSignInAt: null,
// lastSignInIp: null,
// memberGuids: ['6e9b435c-95b9-4908-29a7-a320adee44b7', '90ed4d82-91a0-9680-930b-3678b349eee9', '8b3c4507-46d6-150e-6064-a726b40b5d04', '62c02539-aa48-5cdc-3c29-4676c75f4964'],
// onboardingCompletedAt: "2020-06-29T12:53:17.557-07:00",
// onboardingStartedAt: "2020-06-11T16:38:44.241-07:00",
// onboardingSubFeed: 2,
// openedAppCount: 3,
// originalProgramId: 1474,
// penalties: 0,
// phoneVerificationStatus: "verified",
// playerNotes: "{\"delete_note\":\"merge\",\"player_to_keep_id\":4803558}",
// points: 4500,
// previousPointBalance: null,
// profit: 7.25,
// provider: "walkon",
// purchaseTotal: null,
// recruitmentCompletedAt: null,
// recruitmentStartedAt: null,
// recruitmentStatus: null,
// redeemEmail: null,
// redemptionCost: 5,
// redemptionHold: false,
// referrerId: null,
// rememberCreatedAt: null,
// resetPasswordSentAt: null,
// resetPasswordToken:null,
// sessionCount: 17,
// shareRecruitData: false,
// signInCount: 0,
// sponsorRating: null,
// state: "WA",
// stripeId: null,
// timeZone: "America/Los_Angeles",
// totalCost: 5,
// totalRevenue: 12.25,
// uid: null,
// updatedAt: "2022-04-06T01:08:24.270-07:00",
// xp: 49500,
// playerId: 5966964,
// updatedAt: "2022-04-06T08:08:25.312Z"
// }
