import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table'
import { useGridApiContext } from '@mui/x-data-grid';

import auFlag from '../../assets/AU.png';
import usFlag from '../../assets/US.png';
import caFlag from '../../assets/CA.png';
import gbFlag from '../../assets/GB.png';

import { editCard, BOOST_EDIT_ACTIONS } from '../../models/Prize'
import { COUNTRY_MAP   } from './utils'
import { EditingStockLevel } from './DenominationEditingContent'
import ConfirmDialog from '../common/ConfirmDialog'
import IMLoader from '../common/IMLoader'
import IMAlerts from '../common/IMAlerts';
import IMOnlineBadge from '../common/IMOnlineBadge'
import NumberInput from '../common/NumberInput'

import { createStyles, makeStyles } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCardIcon from '@mui/icons-material/AddCard';
import Switch from '@mui/material/Switch';
import SyncIcon from '@mui/icons-material/Sync';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material-next/Chip';
import FormGroup from '@mui/material/FormGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  useGridApiRef,
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageListItem from '@mui/material/ImageListItem';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import { formatDate, formatTime } from '../common/Utils'


export default function GiftCard(props) {
  const [ rows, setRows ] = useState(props.rows);
  const [ rowModesModel, setRowModesModel ] = useState({});
  const [ editedRow, setEditedRow ] = useState(null);
  const [ refresh, setRefresh ] = useState(false)

  const [ dialogProps, setDialogProps ] = useState(null);
  const [ loading, setLoading ] = useState(false)
  const [ alert, setAlert ] = useState(null)
  const [ loadingMessage, setLoadingMessage ] = useState('')
  const [ editedRows, setEditedRows ] = useState({})
  const [ pending, setPending ] = useState(props.pending)
  const [ changeRow, setChangeRow ] = useState(null);
  const [ enableCardEditingConfirm, setEnableCardEditingConfirm ] = useState(false)
  const apiRef = useGridApiRef();

  const setupGiftCardsRows = () => {

    setRows(props.rows)
    setLoading(false)
    setLoadingMessage(null)

  }
  useEffect(() => {
    if (!rows && props.rows) {
      setLoading(true)
      setLoadingMessage('Loading Gift Cards Denominations')
      setupGiftCardsRows()
    }
  })


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    setEditedRow(null)
  };

  const handleRowEditStart = (params, event) => {
    setEditedRow(params.id)
  };

  const handleEditClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'activeStatus' } });
    setEditedRow(id)
  };

  const handleOnCellModesModelChange = (cellModesModel, gridCallbackDetails) => {
    // console.log('handleOnCellModesModelChange', cellModesModel, gridCallbackDetails)
  }

  const saveRowChanges = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  }

  const handleSaveClick = (id) => () => {
    saveRowChanges(id)
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

  };

  const cancelRowChanges = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      // setFilteredCards(rows.filter((row) => row.id !== id));
    }

    setEditedRow(null)
  }

  const handleCancelClick = (id) => () => {
    cancelRowChanges(id)
  };


  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function BoolCell(props) {
      const { value } = props;
      return (
        value === 'unsupported'
          ? <></>
          : value === true
          ? <IconButton aria-label="CheckIcon" >
             <CheckIcon  color={'primary'}/>
           </IconButton>
          : <IconButton aria-label="CloseIcon" >
             <CloseIcon  color={'error'}/>
           </IconButton>
      )
  }
  const  BoolEditInputCell = (props) => {

    const { id, value, field, row, setChangeRow } = props;
    const [ rowVal, setRowVal ] = useState(row[field]);

     // const currentRoleVal = rows.filter((row) => row.id === id).field
     const apiRef = useGridApiContext();

     const handleChange = async (event) => {
       // setChangeRow({[id]: { [field]: event.target.value}})
       const val = event.target.value
       setRowVal(val);

     };
     useEffect(() => {
       if (rowVal !== row[field]) {
         setChangeRow({[id]: { [field]: rowVal}})
       }
     })

     return (
       <Select
         value={rowVal}
         onChange={handleChange}
         size="small"
         sx={{ height: 1 }}
         native
         autoFocus
       >
         <option value={true}>True</option>
         <option value={false}>False</option>
       </Select>
     );
  }
  const renderBoolEditInputCell = (params) => {
    return <BoolEditInputCell {...params} setChangeRow={setChangeRow}/>;
  };
  function NameCell(props) {
    const { row } = props
    if (props.value == null) {
      return null;
    }

    return <Stack key={row.image} direction="row" spacing={2}>
              <ImageListItem key={row.image} sx={{width: '40px'}}>
                < IMOnlineBadge imgSrc={row.image} title={'GC'}/>

              </ImageListItem>

              <Typography>{props.value}</Typography>

            </Stack>
  }
  function ActiveStatusCell(props) {

    if (props.value == null) {
      return null;
    }

    return (
      <Chip label={props.value} color={props.value === 'Unavailable' ? "error" : 'success'} />
    );
  }

  function CountryCell(props) {
    let comp = <>{props.value}</>
    switch (props.value) {
      case 'US': comp =  <><img src={usFlag} alt="usFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/> US</>
      break;
      case 'CA': comp = <><img src={caFlag} alt="caFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/> CA</>
      break;
      case 'GB': comp = <><img src={gbFlag} alt="gbFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/> GB</>
      break;
      case 'AU': comp = <><img src={auFlag} alt="auFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/> AU</>
    }

      return comp
  }
  function InStockCell(props) {
    const { id, value, field, row, setChangeRow, devSettings } = props

    return (<Box sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: devSettings ? row.boostDevTargetStockLevel > value ? '#FEEBEE' : 'transparent'
      : row.inStockTarget > value ? '#FEEBEE' : 'transparent'

    }}><Typography>{props.value}</Typography></Box>)
  }

  function InStockTargetCell(props) {
    const { id, value, field, row, setChangeRow } = props
    const [ val, setVal ] = useState(value)
     // const currentRoleVal = rows.filter((row) => row.id === id).field
    const apiRef = useGridApiContext();


    if (props.value == null) {
      return null;
    }

    const onChange = (newValue) => {
      let value = newValue ? Number(newValue.target.value) : 0
      if (isNaN(value) || value < 0 || value === 0) {
        value = 0
      }

      if (row.boost && value === 0) {
        setVal(1)
        value = 1
      }
      setVal(value)
      apiRef.current.setEditCellValue({ id, field, value, debounceMs: 200 });

    }

    return (

      <TextField
        id="InStockCellID"
        defaultValue={val}
        value={val}
        onChange={onChange}
        sx={{
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: 1.5,
          gridColumn: 1/2,
          gridRow: 1/3,
          border: 'none',
          borderRadius: 'inherit',
          padding: '8px 12px',
          outline: 0
        }}
      />

    )
  }

  const renderInStockCell = (params) => {
    return <InStockTargetCell {...params} setChangeRow={setChangeRow}/>;
  };



  const columns = [
    { field: 'denomination', align: 'left',headerName: 'Denomination', width: 380, editable: false,
      renderCell: (params) => <NameCell {...params} />},
    { field: 'earnPoints', align: 'left',headerName: 'Points', width: 120, editable: false},
    { field: 'internalStatus', align: 'left',headerName: 'Internal Status', width: 120, editable: false,
      type: 'singleSelect',
      valueOptions: [ 'Active',
                      'Disabled',
                      'Unavailable',
                      'Replaced'
                      ],
      renderCell: (params) => <ActiveStatusCell {...params} /> },
    { field: 'standard',
            align: 'left',
            headerName: 'Standard',
            width: 100,
            editable: props.devSettings ? false : true,
            type: 'singleSelect',
            renderCell: (params) => <BoolCell {...params} />,
            valueOptions: [true, false] },
    { field: 'boost',
              align: 'left',
              headerName: 'Boost',
              width: 100,
              editable: props.devSettings ? false : true,
              type: 'singleSelect',
              renderCell: (params) => <BoolCell {...params} />,
              valueOptions: [true, false]  },
    { field:  props.devSettings ? 'boostDevTargetStockLevel' : 'inStockTarget',
              align: 'center',
              headerName: 'In-Stock Target',
              width: 150,
              editable: true,
              renderEditCell: renderInStockCell
    },
    { field:  props.devSettings ? 'boostDevStockLevel' : 'boostStockLevel',
              align: 'center',
              headerName: 'In-Stock',
              width: 150,
              editable: false,
              renderCell: (params) =>  <InStockCell {...params} devSettings={props.devSettings}/>
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit || editedRow === id;
        // const row = initialRows.filter((row) => row.id === id)
        // const rowSelected = filteredCards.filter((row) => row.id === id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [

          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,


        ];
      },
    },


  ];


  const onCloseAlert = () => {
    setAlert(null)
  }

  const onCloseDialog = () => {
    setDialogProps(null)
    setEnableCardEditingConfirm(false)
  }
  const onDialogCancel = (dialogEdited) => {
    onCloseDialog()

    setRowModesModel({
      ...rowModesModel,
      [dialogEdited.id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    setRows(props.rows)
    setEditedRow(null)
  }

  const onConfirmDialog =  async (editedDialog) => {

    if (dialogProps && Object.keys(dialogProps).length > 0) {
      const dialogType = dialogProps.type
      const newCard = editedDialog.newCard

      setLoading(true)
      let response = null
      let message = ''

      switch (dialogType) {

        case 'Edit':
          setLoadingMessage('Editing Gift Cards')
          let changes = {}
          const oldCard = props.rows.filter((card) => card.id === newCard.id)[0]



          if (props.devSettings) {
            if (oldCard.boostDevTargetStockLevel !== newCard.boostDevTargetStockLevel ){
              changes['boostDevTargetStockLevel'] = [oldCard.boostDevTargetStockLevel, newCard.boostDevTargetStockLevel]
            }
          } else {
            if (oldCard.boost !== newCard.boost ){
              changes['boost'] = [oldCard.boost, newCard.boost]
            }
            if (oldCard.standard !== newCard.standard ){
              changes['standard'] = [oldCard.standard, newCard.standard]
            }
            if (oldCard.inStockTarget !== newCard.inStockTarget ){
              changes['inStockTarget'] = [oldCard.inStockTarget, newCard.inStockTarget]
            }
          }


          if (newCard) {
            const data = {
              id: newCard.id,
              prizeId: newCard.prizeId,
              sku: newCard.sku,
              boost: newCard.boost,
              standard: newCard.standard,
              inStockTarget: newCard.inStockTarget,
              boostDevTargetStockLevel: newCard.boostDevTargetStockLevel,

              changes
            }
            data['action'] = props.devSettings ? BOOST_EDIT_ACTIONS.DEV_IN_STOCK_TARGET : BOOST_EDIT_ACTIONS.IN_STOCK_TARGET

            const response = await editCard(data)
            // console.log('RESPONSE ??? ', response)
            if (response.status === 200) {
              props.onEditCard(newCard)
              message = 'Card Updated!'

            } else {
              onDialogCancel(newCard)
              message = response.error || 'Card Failed to Update!'
            }
            const severity = response.status === 200 ? 'success' : 'error'

            setAlert({ display: true, severity, message })


          }
          setLoadingMessage(null)
          setEditedRow(null)
          break;


        default:
          console.log("un supported dialog type");



      }
      if (response) {

        const severity = response.status === 200 ? 'success' : 'error'

        setAlert({ display: true, severity, message })

      }
      onCloseDialog()

    }
    setLoading(false)
    setLoadingMessage('')
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    onEditCard(newRow)
    return updatedRow;
  };



  const onEditCard = (card) =>  {
    // console.log('UDATE ', card)
    if (card) {
      const currentCard = rows.filter((oldCard) => oldCard.id === card.id)[0]
      if (currentCard) {

        const content = <EditingStockLevel
                          currentCard={currentCard}
                          card={card}
                          devSettings={props.devSettings}
                          onCheckToggle={setEnableCardEditingConfirm}
                        />
      setDialogProps(
        { content,
          id: card.id,
          newCard: card,
          title: props.devSettings? 'DEV In-Stock Target change' : 'In-Stock Target change',
          type: 'Edit',
          contentConfirm: '',
          confirmBtnColor: 'error',
          cancelBtnText: 'CLOSE',
          confirmBtnStartIcon: <CheckCircleIcon />,
          confirmBtnText: 'APPLY CHANGES',
          enableConfirm: enableCardEditingConfirm
         })

       }

    }
  }
  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
    {loadingMessage && <IMLoader message={loadingMessage}/>}
    {alert && alert.display &&
      <IMAlerts
        onClose={onCloseAlert}
        severity={alert.severity}
        message={alert.message}/>}
    {dialogProps && <ConfirmDialog
        onCancel={onDialogCancel}
        contentConfirm={dialogProps.contentConfirm}
        {...dialogProps}
        onConfirm={onConfirmDialog}
        enableConfirm={enableCardEditingConfirm}
        />}
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0}>

         <Grid item xs={1}>
           <Box
             sx={{
               display: 'flex',
               flexWrap: 'wrap',

               '& > :not(style)': {
                 borderRadius: '10px',
                 m: 1,
                 width: 71,
                 height: 68,
                 display: 'flex', alignItems: 'center', justifyContent: 'center'
               },
             }}
           >

            </Box>
         </Grid>
       </Grid>


     </Box>
      <Box  sx={{backgroundColor: 'white', marginTop: 5
      }}>
      <Grid container spacing={0}>

      </Grid>

      </Box>

      {rows && <DataGrid
        apiRef={apiRef}
        editMode="row"
        rows={rows}
        columns={columns}
        sx={{backgroundColor: 'white', borderColor: 'transparent'
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        onRowEditStart={handleRowEditStart}
        processRowUpdate={processRowUpdate}

        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount
        onCellModesModelChange={handleOnCellModesModelChange}
      />}
    </Box>
  );
}
