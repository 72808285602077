import { playerNotesCall, addNoteCall } from '../../api/Player/Notes'
import { processResponse, toCamelCase, processParamsToServer } from '../Utils'

export async function getPlayerNotes({ playerId, unAuthCallback }) {

  const response = await playerNotesCall(playerId);
 // console.log('RESOINSE ????', response, response.status)
  if (response ) {
    if (response && response.status !== 401 ) {
      // const newToken = response.headers.get("Authorization");
      // localStorage.setItem("token", newToken);
      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[key] = key === 'notes'
        ?  response[key].map((note) =>
               processResponse(note))
        : processResponse(response[key]))
      // console.log('RESOINSE ???11111?', res)

      return res
    } else {
      unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}


export async function addNote({ playerId, note, zendeskTicketNumber, admin }) {

 const adminToServer = processParamsToServer(admin)

 const response = await addNoteCall({ playerId, note, zendeskTicketNumber, admin: adminToServer });
 // console.log('RESOINSE ????', response, response.status)
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[key] = key === 'notes'
        ?  response[key].map((note) =>
               processResponse(note))
        : processResponse(response[key]))
      // console.log('RESOINSE ???11111?', response)

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
