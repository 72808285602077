import { getHeaders,  baseUrl } from '../utils';

export async function getActivityGroups(playerId){

  try {
    const response  = await fetch(`${baseUrl}/boost/activity_groups/read/${playerId}`,{
      method: "GET",
      credentials: 'include',
      headers: getHeaders(),
    })

    const responseBack = await response.json()
    // console.log(responseBack)
    return responseBack.data
  } catch (err) {
    return { status: 401 };
  }
}