import React, { useEffect, useState } from 'react';
import { unstable_useNumberInput as useNumberInput } from '@mui/base/unstable_useNumberInput';
import { styled } from '@mui/system';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import { blue, grey, red, green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  const {
    getRootProps,
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
    focused,
  } = useNumberInput(props);

  const inputProps = getInputProps();
  const [ currentColor, setCurrentColor ] = useState(props.customColor)
  const [ refresh, setRefresh ] = useState(props.refresh)

  // Make sure that both the forwarded ref and the ref returned from the getInputProps are applied on the input element
  inputProps.ref = useForkRef(inputProps.ref, ref);
  useEffect(() => {
      if (props.refresh !== refresh) {

          setRefresh(props.refresh)
          setCurrentColor(props.customColor)
      }


  })
  return (
    <StyledInputRoot  {...getRootProps()} className={focused ? 'focused' : null}  customColor={currentColor}>
      {!props.disableSteppers && <StyledStepperButton {...getIncrementButtonProps()} className="increment"  customColor={currentColor}>
        ▴
      </StyledStepperButton>}
    {!props.disableSteppers &&  <StyledStepperButton {...getDecrementButtonProps()} className="decrement" customColor={currentColor}>
        ▾
      </StyledStepperButton>}

      <StyledInputElement {...inputProps} />
    </StyledInputRoot>
  );
});

export default function NumberInput(props) {
  const [ currentVal, setCurrentVal ] = useState(props.value)
  const [ refresh, setRefresh ] = useState(false)


  const onInputChange = (e) => {
    const newVal = e.target.value
    if (props.onChangeCustom) {
      props.onChangeCustom(newVal)
    }
    setCurrentVal(newVal)

    setRefresh(!refresh)

  }
  const onChange = (e, value) => {
    if (props.onChangeCustom) {
      props.onChangeCustom(value)
    }
    setCurrentVal(value)

    setRefresh(!refresh)
  }

  return (
    <Stack spacing={1} ><Typography sx={{color:'#00000099'}}>
      {props.title}
      </Typography>
    <CustomNumberInput aria-label="Demo number input"
      {...props}
      placeholder={props ? props.placeholder : "Type a number…"}
      customColor={props.customColor}
      onInputChange={onInputChange}
      onChange={onChange}
      refresh={!refresh}
      min={props.min}
      max={props.max}
      value={currentVal}
      />
      <Typography sx={{color:'#00000099', marginLeft: '10px !important'}}>
        {props.helperText}
        </Typography>
      </Stack>
  );
}

const getColor = (customColor) => {
  if (customColor) {
    switch (customColor) {
      case 'red':
        return red
      case 'green':
        return green
      case 'grey':
        return grey
      default:
        return null
    }
  } else {
    return null
  }
}

const StyledInputRoot = styled('div')(
  ({ theme, customColor }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${getColor(customColor) === null ? theme.palette.mode === 'dark' ? green[300] : green[900] : getColor(customColor)[300]};
  background: ${getColor(customColor) === null ? theme.palette.mode === 'dark' ? green[900] : '#fff': getColor(customColor)[100]};
  border: 1px solid ${getColor(customColor) === null ? theme.palette.mode === 'dark' ? green[700] : green[200] : getColor(customColor)[700]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;

    &.focused {
      border-color: ${grey[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};

      & button:hover {
        background: ${grey[400]};
      }
      // firefox
      &:focus-visible {
        outline: 0;
    }
  `,
);

const StyledInputElement = styled('input')(
  ({ theme, customColor }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${getColor(customColor) === null ? theme.palette.mode === 'dark' ? green[300] : green[900] : getColor(customColor)[300]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`,
);

const StyledStepperButton = styled('button')(
  ({ theme, customColor }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 19px;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  background: ${getColor(customColor) === null ? theme.palette.mode === 'dark' ? green[900] : '#fff' : getColor(customColor)[900]};
  border: 0;
  color: '${getColor(customColor) === null ? theme.palette.mode === 'dark' ? green[300] : green[900] : getColor(customColor)[300]}';
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

    &.increment {
      grid-column: 2/3;
      grid-row: 1/2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid;
      border-bottom: 0;
      border-color: ${theme.palette.mode === 'dark' ? green[800] : green[200] };
      background: ${theme.palette.mode === 'dark' ? green[900] : green[50] };
      color: '${theme.palette.mode === 'dark' ? green[200] : green[900] }';

      &:hover {
        cursor: pointer;
        color: #FFF;
        background: ${theme.palette.mode === 'dark' ? grey[600] : grey[500]};
        border-color: ${theme.palette.mode === 'dark' ? grey[400] : grey[600]};
      }
    }

    &.decrement {
      grid-column: 2/3;
      grid-row: 2/3;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? green[800] : green[200] };
      background: ${theme.palette.mode === 'dark' ? green[900] : green[50]};
      color: ${theme.palette.mode === 'dark' ? green[200] : green[900]};

      &:hover {
        cursor: pointer;
        color: #FFF;
        background: ${theme.palette.mode === 'dark' ? grey[600] : grey[500]};
        border-color: ${theme.palette.mode === 'dark' ? grey[400] : grey[600]};
      }
  }
  `,
);
