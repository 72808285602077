import { getActivityGroups } from '../../api/Player/ChallengeActivityGroups'
import { processActivityGroups } from '../Utils'

export async function getPlayerActivityGroups() {
  const pathname = window.location.pathname.split('/')
  const playerId = pathname[2]

  const response = await getActivityGroups(playerId);

  return processActivityGroups(response)
}