import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { styled as muiStyle } from '@mui/material/styles';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { formatDate, formatTime, daysDiff } from '../common/Utils'
import { onNewAnnouncement, onUpdateAnnouncement, onDeleteAnnouncement } from '../../models/Announcement'

import ConfirmDialog from '../common/ConfirmDialog'

import IMAlerts from '../common/IMAlerts';

import wave from '../../assets/ArmadaWave.png';
import grin from '../../assets/mobile/grin.png';
import hide from '../../assets/mobile/Hide.png';

import menu from '../../assets/mobile/hamburger.png';
import notificationDot from '../../assets/mobile/notificationDot.png';
import amazonBar from '../../assets/mobile/amazonBar.png';
import mainScreen from '../../assets/mobile/mainScreen.png';
import points from '../../assets/mobile/points.png';
import star from '../../assets/mobile/star.png';
import closeBtn from '../../assets/mobile/closeBtn.png';

import dayjs from 'dayjs';

import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IMLoader from '../common/IMLoader'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function Announcement(props) {

  const [ loading, setLoading ] = useState(false)
  const [ type, setType ] = useState(props.fields['type'].toLowerCase())
  const [ title, setTitle ] = useState(props.fields['title']);
  const [ message, setMessage ] = useState(props.fields['message'])
  const [ illustration, setIllustration ] = useState(props.fields['illustration'])
  const [ fromDate, setFromDate ] = useState(props.fields['fromDate'])
  const [ toDate, setToDate ] = useState(props.fields['toDate'])
  const [ fromTime, setFromTime ] = useState(props.fields['fromTime'])
  const [ toTime, setToTime ] = useState(props.fields['toTime'])
  const [ country, setCountry ] = useState(props.fields['country'])
  const [ button, setButton ] = useState(props.fields['button'])
  const [ checkedDate, setCheckedDate ] = useState(props.fields['displaySchedule'])
  const [ verified, setVerified ] = useState(-1)
  const [ alert, setAlert ] = useState({ display: false, severity: 'error', message: 'There’s already a scheduled or active announcement set for this time range and audience. Engage Maintenance 05/25' })
  const [ updatedFields, setUpdatedFields ] = useState(props.fields)
  const [ dateError, setDateError ] = useState(null)
  const [ dialogProps, setDialogProps ] = useState(null)
  const [ actionBtns, setActionBtns ] = useState(props.actionBtns)
  const [ readOnly, setReadOnly ] = useState(props.readOnly)
  const customInputRef = useRef();

  const TITLE_CHARACTER_LIMIT = 25
  const MESSAGE_CHARACTER_LIMIT = 250
  const BUTTON_CHARACTER_LIMIT = 15

  const theme = createTheme({
    typography: {
      poster: {
        fontSize: '4rem',
        color: 'red',
      },
      // Disable h3 variant
      h3: undefined,
    },
  });

  const GridItem = muiStyle(Grid)(({theme}) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  }));

  const NotificationImg = styled.img`
    position: relative;
    left: 7px;
    top: -3px;
  `
  const MenuImg = styled.img`
    position: absolute;
    margin-top: 3px;
  `
  const PointsImg = styled.img`
    position: relative;
    left: 2px;
  `
  const StarImg = styled.img`
    margin-top: 3px;
    margin-right: 10px;
  `

  const AmazonBarImg = styled.img`

  `
  const MainScreenImg = styled.img`

  `
  const CloseBtnImg = styled.img`
    width: 19px;
    height: 19px;
    position: absolute;
    right: 25px;
    top: 25px;
  `

  useEffect(() => {
    if (verified === -1) {
      verifyFields({})
    }
  });

  const onDialogCancel = () => {
    setDialogProps(null)
  }

  const onCloseAlert = () => {
    setAlert(null)
  }
  const onDateError = (error) => {
    let message = ''
    switch (error) {
      case 'maxDate':
      case 'minDate': message = 'Date range should be at least 10 minutes';
        break;
      case 'minTime': message = 'Date range should be at least 10 minutes';
        break;

      case 'invalidDate':
        message = 'Your date is not valid';
        break;

      // default: {
      //   message = 'Date range should be at least 10 minutes';
      // }
    }
    setDateError({ error, message })
    verifyFields({ 'dateError': { error, message }})
  }
  const onChangeDate = (field) => (date) => {
    verifyFields({ [field]: date })

  }
  const handleChange = (field) => (event) => {

    const newValue = field === 'displaySchedule' ? event.target.checked : event.target.value

    switch (field) {
      case 'type': setType(newValue);
        break;
      case 'illustration': setIllustration(newValue)
        break;
      case 'country': setCountry(newValue)
        break;
      case 'displaySchedule': setCheckedDate(newValue)
        break;
      case 'title':
          if (newValue.length <= TITLE_CHARACTER_LIMIT) {
            setTitle(newValue)
          }
        break;
      case 'message':
          if (newValue.length <= MESSAGE_CHARACTER_LIMIT) {
            setMessage(newValue)
          }
        break;
      case 'button':
          if (newValue.length <= BUTTON_CHARACTER_LIMIT) {
            setButton(newValue)
          }
        break;

    }

    let newFields = updatedFields

    newFields[field] = field === 'displaySchedule' ? event.target.checked : newValue

    setUpdatedFields(newFields)

    verifyFields({ [field]: newValue })

  }


  const verifyFields = (newUpdate) => {
    const toDateUpdated = newUpdate['toDate'] ? newUpdate['toDate'] : toDate
    const fromDateUpdated = newUpdate['fromDate'] ? newUpdate['fromDate'] : fromDate

    const toTimeUpdated = newUpdate['toTime'] ? newUpdate['toTime'] : toTime
    const fromTimeUpdated = newUpdate['fromTime'] ? newUpdate['fromTime'] : fromTime
    const diff = toDateUpdated.diff(fromDateUpdated)
    const daysDiff = diff / 86400000

    let isVerified = daysDiff < 0
                    ? false
                    : Math.ceil(daysDiff) > 1
                      ? true
                      : new Date(toTimeUpdated).getHours() > new Date(fromTimeUpdated).getHours()
                        ? true
                        : new Date(toTimeUpdated).getMinutes() - new Date(fromTimeUpdated).getMinutes() >= 10
    // console.log('VERIFIED ', daysDiff, Math.ceil(daysDiff),  new Date(toTimeUpdated).getHours(), new Date(fromTimeUpdated).getHours(), new Date(toTimeUpdated).getMinutes(), new Date(fromTimeUpdated).getMinutes())
    const today = dayjs().utcOffset(0).startOf('date')

    isVerified = isVerified && today.isBefore(fromDate) && today.isBefore(toDate)

    if (!isVerified) {
      setVerified(false)
      return
    }
    let recentField = newUpdate ? Object.keys(newUpdate)[0] : ''
    Object.keys(updatedFields).forEach((field) => isVerified =  recentField === field
      ? isVerified && (newUpdate[field] || field === 'displaySchedule' || field === 'id')
      : isVerified && (updatedFields[field] || field === 'displaySchedule' || field === 'id'))

    setVerified(isVerified)

  }

  const onAnnouncementsCall = () => {
    props.onAnnouncementsCall()
  }

  const getTitle = (t) => {
    return t.charAt(0).toUpperCase() + t.slice(1)
  }

  const schedule = async (newSchedule) => {
    setLoading(true)
    let newAlert = {}
    newAlert['display'] = true

    updatedFields['fromDate'] = fromDate
    updatedFields['fromTime'] = fromTime
    updatedFields['toDate'] = toDate
    updatedFields['toTime'] = toTime

    const response = newSchedule ? await onNewAnnouncement({ announcement: updatedFields })
            : await onUpdateAnnouncement({ announcement: updatedFields, announcementId: props.fields['id'] })
    // console.log('RESPONSE NEW ',response )
    if (response.error ) {
      newAlert['severity'] = 'error'

      newAlert['message'] = response.error === 'Overlaps Dates' && response.overlapping
        ? `There’s already a scheduled or active announcement set for this time range and audience. ${response.overlapping[0].title} ${response.overlapping[0].startAt}`
        : `Failed to schedule ${response.error}`
    } else {
      newAlert['severity'] = 'success'
      newAlert['message'] = 'New Announcement Scheduled Successfully!'
      setActionBtns(props.getActionButtons('review'))
    }

    setAlert(newAlert)
    setLoading(false)
  }
  const stop = async () => {
    const stoppedDate = dayjs((new Date()))
    const stoppedTime = new Date(Date.now() - ( 10 * 60 * 1000))

    setLoading(true)
    let newAlert = {}
    newAlert['display'] = true

    updatedFields['fromDate'] = fromDate
    updatedFields['fromTime'] = fromTime
    updatedFields['toDate'] = stoppedDate
    updatedFields['toTime'] = stoppedTime

    setToDate(stoppedDate)
    setToTime(stoppedTime)

    const response = await onUpdateAnnouncement({ announcement: updatedFields, announcementId: props.fields['id'] })

    if (response.error ) {
      newAlert['severity'] = 'error'

      newAlert['message'] = `Failed to Stop !  ${response.error}`
    } else {
      newAlert['severity'] = 'success'
      newAlert['message'] = 'The Announcement was Successfully Stopped!'
      setActionBtns(props.getActionButtons('view'))
      setReadOnly(true)
    }

    setAlert(newAlert)
    setLoading(false)
  }
  const deleteAnnouncement = async () => {

    setLoading(true)
    let newAlert = {}
    newAlert['display'] = true

    const response = await onDeleteAnnouncement({ announcementId: props.fields['id'] })

    if (response.error ) {
      newAlert['severity'] = 'error'

      newAlert['message'] = `Failed to Delete !  ${response.error}`
    } else {
      newAlert['severity'] = 'success'
      newAlert['message'] = 'The Announcement was Successfully Deleted!'
      setTimeout(() => {
        props.onAnnouncementsCall()
      }, 3000);
      setActionBtns(props.getActionButtons('view'))
      setReadOnly(true)
    }

    setAlert(newAlert)
    setLoading(false)
  }
  const onConfirmADialog = (action) => () => {
    // props.onAction(updatedFields, action)
    switch (action.toLowerCase()){
      case 'schedule':
        schedule(true)
        break;
      case 'update':
        schedule();
        break;
      case 'stop':
        stop();
        break;
      case 'delete':
        deleteAnnouncement();
        break;

    }
    props.onAction()
    setDialogProps(null)
  }

  const onAction = (action) => () => {
    const content =   <Typography sx={{ fontSize: '16x', fontWeight: 400, marginTop: '10px',
      }} color="text.primary" >
        { action.value === 'Schedule'
          ? 'Please review your announcement carefully. Once scheduled, it will be broadcasted on the time selected. Make sure everything is accurate and as intended.'
          : `Are you sure you want to continue? This will ${action.value} the announcement immediately.`}
        </Typography>
    setDialogProps(
      { content,
        title: `${action.value} Announcement?`,
        type: action.value,
        contentConfirm: '',
        enableConfirm: true,
        confirmBtnText: `${action.value} Announcement`,
        cancelBtnText: 'Cancel & Review',
        confirmBtnColor: action.color,
        onConfirmADialog: onConfirmADialog(action.value)
       })
    // props.onAction(updatedFields, action)
  }

  return (
    <Box sx={{marginTop: '-35px'}}>
    {alert && alert.display &&
      <IMAlerts
        onClose={onCloseAlert}
        severity={alert.severity}
        message={alert.message}/>}
    {dialogProps && <ConfirmDialog
        onCancel={onDialogCancel}
        contentConfirm={dialogProps.contentConfirm}
        {...dialogProps}
        onConfirm={dialogProps.onConfirmADialog}
        />}
    { loading
      ? <IMLoader message={'Submitting New Announcement...'} mini/>
      :<>
      <Stack direction="row" spacing={2} sx={{width: '100%', alignItems: 'center', fontSize: 24, marginLeft: '15px'}}>
        <Button
          onClick={onAnnouncementsCall}
          sx={{ alignItems: 'center',
            justifyContent: 'end',
            backgroundColor: '#1976D24D',
                 position: 'inherit',
                left: '70px', width: '58px', height: '59px', width: '59px',
                borderRadius: '64px'}}
               variant="contained" endIcon={<ArrowBackIcon color='primary' sx={{ fontSize: '40px !important' }} fontSize="large" />}
        ></Button>
        <Typography sx={{ marginLeft: 1, fontSize: '34px',
        }} color="text.primary" >
          {props.title}
       </Typography>

      </Stack>
      <Stack spacing={2} >

        <Typography sx={{ marginLeft: '30px !important', fontSize: '24px', fontWeight: 700, marginTop: '10px !important',
        }} color="text.primary" >
          {'What are you broadcasting to players?'}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >

            <Grid item xs={6}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
              <Grid item xs={4}>
                 <FormControl sx={{ m: 1, width: '-webkit-fill-available;'}}>
                   <InputLabel id="Type-select-label">Type</InputLabel>
                    <Select
                       variant="filled"
                       labelId="selectType"
                       id="selectTypeId"
                       value={type}
                       label={type ? getTitle(type) : 'Type'}
                       disabled={readOnly}
                       onChange={handleChange('type')}
                     >
                     {
                       (props.typeOptions).map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))
                     }


                     </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8} sx={{alignItems: 'center',
                                      display: 'flex'}}>
                <TextField sx={{width: '-webkit-fill-available;'}}
                  id="title-text-field"
                  label="Title"
                  value={title}
                  disabled={readOnly}
                  onChange={handleChange('title')}
                  variant="filled" />
              </Grid>
              <Grid item xs={4} sx={{marginTop: '-8px'}}>
                <FormControl sx={{ m: 1, width: '-webkit-fill-available;'}}>
                  <InputLabel id="Illustration-select-label">Illustration</InputLabel>

                   <Select
                       variant="filled"
                      labelId="selectType"
                      id="selectTypeId"
                      disabled={readOnly}
                      value={illustration}
                      label={`Illustration`}

                      onChange={handleChange('illustration')}
                    >
                       <MenuItem value={'wave'}>{'Armanda waving'}</MenuItem>
                       <MenuItem value={'grin'}>{'Grin'}</MenuItem>
                       <MenuItem value={'hide'}>{'Hide'}</MenuItem>

                    </Select>
               </FormControl>
              </Grid>
              <Grid item xs={8}>
              <FormControl sx={{ width: '-webkit-fill-available;'}}>

                <TextField
                    id="message-text-field"
                    label="Message"
                    multiline
                    rows={4}
                    disabled={readOnly}
                    variant="filled"
                    value={message}
                    onChange={handleChange('message')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sx={{marginTop: '-60px'}}>
                <Box sx={{width: '-webkit-fill-available',
                    justifyContent: 'center',
                    display: 'flex'}}>
                {  illustration === 'hide'
                  ? <img src={hide} alt="Hide" />
                  : illustration === 'grin'
                  ? <img src={grin} alt="Grin" />
                  : <img src={wave} alt="ArmandaWave" />}
                </Box>
              </Grid>
              <Grid item xs={8} >
                <FormControl sx={{ width: '-webkit-fill-available;'}}>
                  <TextField
                    id="button-text-field"
                    label="Button"
                    value={button}
                    disabled={readOnly}
                    placeholder='Ok, Thanks!'
                    variant="filled"
                    onChange={handleChange('button')}
                  />
                </FormControl>
              </Grid>
            </Grid>

           <Divider sx={{marginLeft: '20px !important', marginTop: 4}}/>
           <Typography sx={{ fontSize: '24px', fontWeight: 700, marginTop: '10px',
           }} color="text.primary" >
             {'When'}
             </Typography>
           <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
              <Grid item xs={6} >
                <Typography sx={{ marginLeft: 1, fontSize: '14px', fontWeight: 700, marginTop: '10px',
                }} color="text.primary" >
                  {'FROM'}
                  </Typography>
                  <Typography sx={{ marginLeft: 1}} variant="subtitle1" gutterBottom>
                   {'(Pacific Time)'}
                 </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography sx={{ marginLeft: 1, fontSize: '14px', fontWeight: 700, marginTop: '10px',
              }} color="text.primary" >
                {'TO'}
                </Typography>
                <Typography x={{ marginLeft: 1}} variant="subtitle1" gutterBottom>
                 {'(Pacific Time)'}
               </Typography>
              </Grid>
              <Grid item xs={6} sx={{marginTop: 2}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(params) => <TextField {...params} />}
                      label="FromDate"
                      value={fromDate}
                      disabled={readOnly}
                      onChange={(newValue) => setFromDate(newValue)}
                      disablePast={!readOnly}
                      onError={onDateError}
                      onAccept={onChangeDate('fromDate')}
                       />

                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} sx={{marginTop: 2}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(params) => <TextField {...params} />}
                      disabled={readOnly}
                      renderInput={(params) => <TextField {...params} />}
                      label="ToDate"
                      value={toDate}
                      onChange={(newValue) => setToDate(newValue)}
                      disablePast={!readOnly}
                      minDate={fromDate}
                      onError={onDateError}
                      onAccept={onChangeDate('toDate')}
                       />

                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} sx={{marginTop: 2}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      renderInput={(params) => <TextField {...params} />}
                      disablePast={!readOnly}
                      disabled={readOnly}
                      label="Time"
                      value={fromTime}
                      onChange={(newValue) => setFromTime(newValue)}
                      onError={onDateError}
                      onAccept={onChangeDate('fromTime')}
                    />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} sx={{marginTop: 2}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      renderInput={(params) => <TextField {...params} />}
                      disablePast={!readOnly}
                      label="Time"
                      disabled={readOnly}
                      value={toTime}
                      onChange={(newValue) => setToTime(newValue)}
                      minTime={new Date(fromDate).getDate() === new Date(toDate).getDate() ? dayjs(fromTime + ( 10 * 60 * 1000)) : null}
                      onError={onDateError}
                      onAccept={onChangeDate('toTime')}

                    />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} >
                <FormControlLabel control={<Switch checked={checkedDate}
                    disabled={readOnly}
                    onChange={handleChange('displaySchedule')} />} label="Display date/time to player" />
                <FormHelperText>Show scheduled date(s) and hour(s) in-app to players</FormHelperText>
              </Grid>
           </Grid>
           <Divider sx={{marginLeft: '20px !important', marginTop: 4}}/>
           <Typography sx={{ fontSize: '24px', fontWeight: 700,
           }} color="text.primary" >
             {'Audience'}
             </Typography>
             <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
               <Grid item xs={6} sx={{marginTop: 1}}>
                 <FormControl sx={{ width: '-webkit-fill-available;'}}>
                   <InputLabel id="Country-select-label">Country</InputLabel>
                      <Select
                         variant="filled"
                         labelId="selectCountry"
                         id="selectCountryId"
                         value={country}
                         disabled={readOnly}
                         label={`Country`}
                         onChange={handleChange('country')}
                       >
                        <MenuItem value={'US'}>{'US'}</MenuItem>
                        <MenuItem value={'CA'}>{'CA'}</MenuItem>
                        <MenuItem value={'AU'}>{'AU'}</MenuItem>
                        <MenuItem value={'GB'}>{'GB'}</MenuItem>
                     </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{marginLeft: '20px !important', marginTop: '200px !important'}}/>

            </Grid>
            <Grid item xs={6}>
            <Stack direction='row' sx={{height: '100%'}}>
              <Divider orientation="vertical"  sx={{width: '1px', height: '100%', borderWidth: 1, borderTop: 0, marginTop: 2, borderBottom: 0,}}/>
              <ButtonGroup
                variant="contained"
                sx={{position: 'absolute', top: '140px',
                      right: '70px',
                      }}
                 aria-label="action-btns-group">

                {actionBtns.map((btn) =>
                  <Button
                    disabled={!verified && (btn.value.toLowerCase() === 'schedule' || btn.value.toLowerCase() === 'update')}
                    onClick={onAction(btn)}
                    color={btn.color}
                    sx={{

                      width: '150px', height: '36px',
                    }}
                         variant="contained"
                  >{btn.value}</Button>
                )}
              </ButtonGroup>
              <Stack sx={{alignItems: 'center', width: '100%'}}>
                  <Typography sx={{ fontSize: '24px', fontWeight: 400, marginBottom: '10px',
                  }} color="text.primary" >
                    {'PLAYER VIEW'}
                    </Typography>
                  <Box
                     sx={{
                       width: 360,
                       height: 50,
                       backgroundColor: '#313738',
                     }}
                   >
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}sx={{marginTop: 0}} >
                        <Grid item xs={2} sx={{justifyContent: 'end', alighItems: 'center',
                                display: 'flex', height: '50px'}}>
                          <MenuImg src={menu} alt="Menu" height="25"/>
                          <NotificationImg src={notificationDot} alt="Menu" height="13"/>

                        </Grid>
                        <Grid item xs={6} >
                        </Grid>

                        <Grid item xs={4} sx={{alignItems: 'baseline', marginTop: '5px',
                                justifyContent: 'space-between;',
                                display: 'flex', height: '50px'}}>
                          <PointsImg src={points} alt="points" height="20"/>
                          <StarImg src={star} alt="star" height="20"/>

                        </Grid>
                      </Grid>
                   </Box>
                   <Box
                      sx={{
                        width: 360,
                        height: 90,
                        backgroundColor: type === 'alert' ? '#F8DB83' : type === 'critical' ? '#DDACAC' : '#BDD28F',
                        padding: 1,
                        fontSize: '16px'
                      }}
                    >
                    <ThemeProvider theme={theme} >
                      <Typography sx={{display: 'inline', fontWeight: 700}} variant="subtitle1">{title}</Typography>
                      <Typography sx={{display: 'inline'}} >{` ${message.substring(0, 75)}...`}</Typography>
                      <Typography sx={{display: 'inline', color: '#d34643', textDecoration: 'underline'}} >{'more details'}</Typography>
                    </ThemeProvider>

                  </Box>
                  <Box
                     sx={{
                       width: 360,
                       height: 100,
                       backgroundColor: '#F1EFEE',
                       alignItems: 'center',
                       display: 'flex',
                       justifyContent: 'center'
                     }}
                   >
                   <AmazonBarImg src={amazonBar} alt="amazon" />

                 </Box>
                 <Box
                    sx={{
                      width: 360,
                      marginTop: '25px',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                  <MainScreenImg src={mainScreen} alt="amazon" />
                  <Box
                     sx={{
                       width: 320,
                       backgroundColor: '#FFFFFF',
                       position: 'absolute',
                       borderRadius: '30px',

                       display: 'flex',
                       marginTop: '50px',

                       height: '53%'
                     }}
                   >
                    <CloseBtnImg src={closeBtn} alt="close" />
                    <Stack sx={{alignItems: 'center', width: '100%', marginTop: '25px'}}>
                      <Box sx={{width: '-webkit-fill-available',
                          justifyContent: 'center',
                          maxHeight: '120px',
                          display: 'flex'}}>
                          {  illustration === 'hide'
                            ? <img src={hide} alt="Hide" />
                            : illustration === 'grin'
                            ? <img src={grin} alt="Grin" />
                            : <img src={wave} alt="ArmadaWave" />}
                      </Box>
                      <Typography sx={{ fontSize: '22px', fontWeight: 700, marginTop: '15px',
                      }} color="text.primary" >
                        {title}
                     </Typography>
                     <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#888888',
                   }} color="#888888" >
                       { !checkedDate
                         ? ''
                         : daysDiff(new Date(fromDate), new Date(toDate))
                         ? `${formatDate(new Date(fromDate))} - ${formatTime(new Date(fromTime))} to`
                         : `${formatDate(new Date(fromDate))} - ${formatTime(new Date(fromTime))} to ${formatTime(new Date(toTime))}`}

                    </Typography>
                    <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#888888',
                  }} color="#888888" >
                      { daysDiff(new Date(fromDate), new Date(toDate)) && checkedDate
                        ? `${formatDate(new Date(toDate))} - ${formatTime(new Date(toTime))}`
                        : ''
                      }

                   </Typography>
                    <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#888888', padding: '17px',
                  }} color="#888888" >
                      {message}
                   </Typography>
                   <Box sx={{width: '280px',
                       height: '60px',
                       justifyContent: 'center',
                       backgroundColor: '#F1045C',
                       borderRadius: '15px',
                       color: '#FFFFFF',
                       alignItems: 'center',
                       display: 'flex'}}>
                       <Typography sx={{ fontSize: '18px', fontWeight: 700, color: '#FFFFFF',
                     }} color="#FFFFFF" >
                         {button}
                      </Typography>
                   </Box>
                    </Stack>
                   </Box>
                </Box>
              </Stack>
              </Stack>
            </Grid>
          </Grid>

      </Stack>
      </>
  }
    </Box>
  )
}
