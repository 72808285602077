import axios from 'axios';
import { getHeaders,  baseUrl } from './utils';
import { processResponse, toCamelCase } from './utils'

export async function playersCall({ unAuthCallback, filter }) {

  try {

    const response  = await fetch(`${baseUrl}/players`,{
      method: "POST",
      credentials: 'include',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify(
        {filter}
      )
    })

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 401 };

  }
}

export async function playersSearchCall(params) {

  try {
    const response  = await fetch(`${baseUrl}/players`,{
      method: "POST",
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(
        params
      )

    })

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 401 };
  }
}

export async function completeSpotlight(spotlight){
  const postData = {
    data: {
      offer_id: spotlight.offerId,
      device_id: spotlight.deviceId,
      external_device_id: spotlight.externalDeviceId,
      offer_bonus_id: spotlight.offerBonusId
    }
  }

  try {
    const response  = await fetch(`${baseUrl}/spotlight/edit/spotlight`,{
      method: "POST",
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(postData)
    })

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 401 };
  }
}
