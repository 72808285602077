import React, { useContext, useState, useEffect } from "react";

import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import Tooltip from "@mui/material/Tooltip";

import { styled } from '@mui/material/styles';

const SpotlightOfferCell = (props) => {
  const spotlight = props.spotlight

  const OfferCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      paddingTop: 20,
      paddingBottom: 20,
      display: 'flex',
      justifyContent: 'left',
      minHeight: '105px',
      maxWidth: '450px'
    },
  }))

  const imageContainerStyles = {
    flexDirection: 'column',
    display: 'flex',
    background: `url(${spotlight.imageUrl}) no-repeat`,
    backgroundSize: '100%',
    minWidth: 50,
    minHeight: 50,
  }

  const outerDot = {
    position: "relative",
    bottom: -39,
    right: -37,
    width: 16,
    height: 16,
    backgroundColor: 'white',
    borderRadius: 10,
  }

  // This is inaccurate right now - needs to be matched against confirmedAt explicitly
  const innerDotColor = spotlight.confirmedAt ? 'green' : '#D32F2F'
  // const innerDotShow =

  const innerDot = {
    position: "relative",
    bottom: -3,
    right: -3,
    width: 10,
    height: 10,
    backgroundColor: innerDotColor,
    borderRadius: 10,
  }

  function displayStatusBubble(){
    return spotlight.confirmedAt || !!spotlight.unconfirmedAt
  }

  function dotLabel(){
    if (displayStatusBubble()){
      return !!spotlight.confirmedAt ? "Confirmed" : "Not Confirmed"
    }

  }

  return(
        <OfferCell component="th" scope="row">
          <div style={imageContainerStyles}>
              {
                  displayStatusBubble() &&
                  <Tooltip title={dotLabel()}>
                    <div style={outerDot}>
                      <div style={innerDot}></div>
                    </div>
                  </Tooltip>
              }
          </div>

          <div style={{marginLeft: 10, display: 'inline-block'}}>
            <p style={{margin:0, fontSize: '16px',display: 'inline-block'}}>
              {spotlight.installedAppName}
            </p>
            <p style={{margin: 0, fontSize: '10px', fontSmooth: 'auto', maxWidth: '70%'}}>
              {spotlight.splDisplayText}
            </p>
          </div>

        </OfferCell>

  )
}

export default SpotlightOfferCell;