import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { indigo } from '@mui/material/colors';

import { getPlayerPoints } from '../../../models/Player/Points'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IMSkeletonLoader from '../../common/IMSkeletonLoader'

import Grid from '@mui/material/Grid';


const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '24px',

  lineHeight: '133.4%',
  marginBottom: 15
}))

const GridItemTitle = styled(Grid)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '100%',
  /* identical to box height, or 12px */

  letterSpacing: '1px',

  /* Gray/600 */
  color: '#999999',
  marginBottom: 5
}))

const GridItemPoints = styled(Grid)(({ theme }) => ({
  fontWeight: 400,
  // fontSize: '34px',
  fontSize:' x-large',
  lineHeight: '123.5%',
  /* or 42px */

  letterSpacing: '0.25px',

  /* Light/Other/Rating Active */

  color: '#FFB400'
}))

const GridItemValue = styled(Grid)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '143%',
  /* identical to box height, or 20px */

  letterSpacing: '0.17px',
  // textDecorationLine: 'underline',

  /* Theme/Dark */

  color: '#212529'
}))


export default function Points(props) {

  const [ mount, setMount ] = useState(false)
  const [ loading, setLoading ] = useState(true)

  const [ summaryPoints, setSummaryPoints ] = useState({})

  useEffect(() => {
    if (!mount) {
      setMount(true)
      setupPoints()
    }

  })

  const setupPoints = async () => {
    setLoading(true)
    const response = await getPlayerPoints({ playerId: props.player.id });

    if (response.summary) {
      let summary = {}
      Object.keys(response.summary).forEach((pointSum) => {
        summary[pointSum] = { value: response.summary[pointSum], info: '' }
        }
      )
      setSummaryPoints(summary)
    }
    setLoading(false)
    // console.log('RESPONSE SUMMARY POINTS ', response)
  }

  return (

    <Box sx={{ flexGrow: 1, marginLeft: '23px', }}>
      <Title>Points</Title>
      {loading
        ? <IMSkeletonLoader itemsNumber={12} columns={4}/>
        : <><Grid container spacing={0.5}>
        <GridItemTitle item xs={6}>
          Current Total
        </GridItemTitle>
        <GridItemTitle item xs={6}>
          Earned Today
        </GridItemTitle>
      </Grid>
      <Grid container spacing={0.5} sx={{ marginBottom: 2 }}>
        <GridItemPoints item xs={6}>
          { summaryPoints['currentTotal'] && summaryPoints['currentTotal'].value ? summaryPoints['currentTotal'].value.toLocaleString() : 0}
        </GridItemPoints>
        <GridItemPoints item xs={6} sx={{color: '#ff9301'}}>
          {summaryPoints['earnedToday'] && summaryPoints['earnedToday'].value ? summaryPoints['earnedToday'].value.toLocaleString() : 0}
        </GridItemPoints>
      </Grid>
      <Grid container spacing={0.5} sx={{ marginBottom: 2, display: 'flex',
    alignItems: 'baseline' }}>
      { Object.keys(summaryPoints).map((key) =>
          key !== 'earnedToday' && key !== 'currentTotal' && <><GridItemTitle item xs={6} key={`${key}KeyItem`}>
              {`${key.split(/(?=[A-Z])/).join(' ')}`}
            </GridItemTitle>
            <GridItemValue item xs={6} key={`${key}KeyItemValue`}>
              {summaryPoints[key].value.toLocaleString() } { summaryPoints[key].info ? `(${summaryPoints[key].info})` : ''}
            </GridItemValue>
        </>
        )
      }
      </Grid>
      </>}
    </Box>
  )
}
