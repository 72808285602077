import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import OnboardingStatusChip from './Onboarding/OnboardingStatusChip';
import ConstructionIcon from "@mui/icons-material/Construction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PlayerTabs(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {props.tabs.map((tab, i) => {
          if (tab === "Onboarding") {
            return (
              <Tab
                {...a11yProps(i)}
                component={({ ...props }) => (
                  <Stack direction="row" spacing={1} {...props}>
                    <span>Onboarding</span>
                    <OnboardingStatusChip size="small" />
                  </Stack>
                )}
              />
            );
          }
          if (tab === "Dev Mode") {
            return (
                <Tab
                {...a11yProps(i)}
                component={({ ...props }) => (
                    <Stack direction="row" spacing={1} {...props}>
                    <ConstructionIcon fontSize="small"/>
                    <span>Dev Mode</span>
                    </Stack>
                )}
                />
            );
          }
          return <Tab label={tab.toUpperCase()} {...a11yProps(i)} />;
        })}

        </Tabs>
      </Box>
      {props.tabs.map((tab, i) =>
        <TabPanel value={value} index={i}>
          {props.tabsComp[tab] ? props.tabsComp[tab] : 'TBD'}
        </TabPanel>
      )}
    </Box>
  );
}
