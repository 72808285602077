import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SpotlightStatusFilter = ({selectedStatus, setSelectedStatus}) => {

  const spotlightLabels = [
    'Completed',
    'Expired',
    'In Progress',
    'Offer Available'
  ]

  return(
    <Box sx={{ minWidth: 150, marginRight: '30px' }}>
      <FormControl variant="filled" fullWidth>
        <InputLabel>Status</InputLabel>
        <Select
          value={selectedStatus}
          label="Status"
          onChange={(e) => {
            const selected = e.target.value
            setSelectedStatus(selected)
            // filterByState(selected)
          }}
        >
          <MenuItem key={'all'} value={'all'} >
            {'All'}
          </MenuItem>
          {spotlightLabels.map((option) => (
            <MenuItem key={option} value={option} >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SpotlightStatusFilter