import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { onUpdateAnnouncement, getAnnouncement } from '../../models/Announcement'

import listPlugin from '@fullcalendar/list'
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IMLoader from '../common/IMLoader'

import wave from '../../assets/ArmadaWave.png';
import grin from '../../assets/mobile/grin.png';
import hide from '../../assets/mobile/Hide.png';
import auFlag from '../../assets/AU.png';
import usFlag from '../../assets/US.png';
import caFlag from '../../assets/CA.png';
import gbFlag from '../../assets/GB.png';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { red } from '@mui/material/colors';

import dayjs from 'dayjs'

export default function AnnouncementsCalendar(props) {

  const [ events, setEvents ] = useState(null)
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ currentEvent, setCurrentEvent ] = React.useState(null);
  const [ loading, setLoading ] = useState(false)

  const now = new Date()
  const theme = createTheme({
    typography: {
      poster: {
        fontSize: '4rem',
        color: 'red',
      },
      // Disable h3 variant
      h3: undefined,
    },
  });

  const setupEvents = () => {
    const newEvents = props.events.map((event) => (
      {
        id: event.id,
        title: event.title,
        allDay: true,
        start: new Date(Date.parse(event.fromDate)),
        end: new Date(Date.parse(event.toDate)).setHours(24, 59, 59, 999),
        // new Date(Date.parse(event.toDate)).endOf('day'),
        color: event.color,
        when: event.when,
        illustration: event.illustration,
        country: event.country,
        dynamoid: event.dynamoid,
        // message: event.message,
        // actions: event.actions
      }
    ))
    // console.log('setup events ', newEvents)
    setEvents(newEvents)
  }

  useEffect(() => {
    if (events === null && props.events) {
      setupEvents();
    }
  });

const handleClose = () => {
  setCurrentEvent(null)
}
const eventClick = (info) => {
  let eve = props.events.filter((ev) => ev.id === info.event.id)[0]
  let updatedEve = events.filter((eve) => eve.id === info.event.id)[0]

  eve.dynamoid = updatedEve.dynamoid

  setCurrentEvent(eve)

  }
  const getActionIcon = (action) => {
    switch (action) {
      case 'View':
      case 'Edit':
      case 'Review / Edit':
        return '👁️'
      case 'Stop':
      case 'Delete':
        return '🛑'
      case 'Clone':
        return '🔃'
    }
  }
  const onMenuItemClicked = (action) => (event) => {
    const { row } = props;
    currentEvent.onAnnouncementActionCall(currentEvent, action)
  }
  const syncDynamo = async () => {
    setLoading(true)
    const response = await onUpdateAnnouncement({ announcement: currentEvent, announcementId: currentEvent.id })
    if (response && response.announcements && response.announcements.length === 1) {
      const responseSched = await getAnnouncement({ announcementType: 'scheduled' });

      if (responseSched.announcements && responseSched.announcements.length > 0) {
        const editedAnnouncement = responseSched.announcements.filter((announce) => announce.id === response.announcements[0].id)

        let updatedEvents = []
        if (editedAnnouncement.length === 1) {
          events.forEach((event) => {
            if (event.id === `${editedAnnouncement[0].id}-active`) {
              event.dynamoid = editedAnnouncement[0].dynamoid
            }
            updatedEvents.push(event)
          })

          setEvents(updatedEvents);
        }
      }


    }
    handleClose()
    setLoading(false);
  }
  const formatEventCardTime = (whenTime) => {
    const dates = whenTime.split('to')
    const datesTo = whenTime.split('-')
    if (datesTo.length === 2) {
      return whenTime;
    } else {
      return (
        <Stack sx={{width: 'inherit', alignItems: 'center', alignItems: 'baseline'}}>
        <Box>
          <ThemeProvider theme={theme} >
            <Typography sx={{display: 'inline', fontWeight: 700}} variant="subtitle1">{'FROM: '}</Typography>
            <Typography sx={{display: 'inline', fontWeight: 400}} variant="subtitle1">{dates[0]}</Typography>
          </ThemeProvider>
        </Box>
        <Box>

            <Typography sx={{display: 'inline', fontWeight: 700}} variant="subtitle1">{'TO: '}</Typography>
            <Typography sx={{display: 'inline', fontWeight: 400}} variant="subtitle1">{dates[1]}</Typography>

       </Box>
     </Stack>)
    }
  }

  return (
    <div>
      {events
        ? loading
          ? <IMLoader message={'Loading Annonecements'} mini/>
          :
      <><FullCalendar
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          weekends={true}
          events={events}
          height={700}
          headerToolbar={{
            start: "today prev next",
            end: "dayGridMonth dayGridWeek dayGridDay",
          }}
          views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
          eventContent={(arg) => (



              <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '16x',
                cursor: 'pointer',
                justifyContent: 'center',
                fontWeight: 700, backgroundColor: `${arg.color} !important`,
                '&:hover': { backgroundColor: arg.color,  opacity: .9},
                '.img': { height: 10, width: 10},
                opacity: .3,
                transition: 'opacity .15s ease-in-out',

                }} color="text.primary">

                {  arg.event.extendedProps.country === 'CA'
                  ? <img src={caFlag} alt="caFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/>
                  : arg.event.extendedProps.country === 'AU'
                  ? <img src={auFlag} alt="auFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/>
                  : arg.event.extendedProps.country === 'GB'
                  ? <img src={gbFlag} alt="gbFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/>
                  : <img src={usFlag} alt="usFlag" style={{ height: '20px', width: '40px', paddingRight: '11px' }}/>}
                  <Box sx={{display: 'inline-block',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '230px',
                        fontSize: '14px',
                        marginLeft: '5px',
                        whiteSpace: 'nowrap'}}>{arg.event.title}</Box>
                  { new Date(arg.event.end)  < new Date().setHours(24, 59, 59, 999)
                    ? <></>
                    : arg.event.extendedProps.dynamoid
                      ? <></>
                      : <Box>
                          <Button sx={{ fontSize: 14 }}  >
                            <WarningRoundedIcon sx={{ color: red[500], fontSize: 20 }} />
                          </Button>
                        </Box>}
              </Typography>

        )}
        eventClick={eventClick}
      />
    {  currentEvent && <Popover
           id={currentEvent.id}
           open={currentEvent !== null}
           onClose={handleClose}
           anchorOrigin={{
             vertical: 'center',
             horizontal: 'center',
           }}
            sx={{ '& .MuiPopover-paper':
              {
                width: '480px !important'
              }
              }}


         >
         <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingRight: 2, paddingLeft: 2, paddingTop: 1, paddingBottom: 1, fontSize: 24, fontWeight: 700, backgroundColor: currentEvent.color }}>
           <Grid item xs={2}>
             <Box sx={{width: '50px', height: '50px',
                 backgroundColor: currentEvent.color,

                 justifyContent: 'center',
                 display: 'flex'}}>
             {  currentEvent.country === 'CA'
               ? <img src={caFlag} alt="caFlag" />
               : currentEvent.country === 'AU'
               ? <img src={auFlag} alt="auFlag" />
               : currentEvent.country === 'GB'
               ? <img src={gbFlag} alt="gbFlag" />
               : <img src={usFlag} alt="usFlag" />}
             </Box>

           </Grid>
           <Grid item xs={8} sx={{
             display: 'flex',
              alignItems: 'center',
           }}>
             <Typography sx={{ fontSize: 24,

                fontWeight: 700 }}>{currentEvent.title}</Typography>

           </Grid>

           <Grid item xs={2}>
             <Box sx={{width: '50px', height: '50px',
                 backgroundColor: currentEvent.color,

                 justifyContent: 'center',
                 display: 'flex'}}>
             {  currentEvent.illustration === 'hide'
               ? <img src={hide} alt="Hide" />
               : currentEvent.illustration === 'grin'
               ? <img src={grin} alt="Grin" />
               : <img src={wave} alt="ArmandaWave" />}
             </Box>
           </Grid>
         </Grid>

         <Box sx={{ display: 'flex', padding: 2, alignItems: 'center',whiteSpace: 'pre-line' }}>
           {formatEventCardTime(currentEvent.when)}
           { currentEvent.toDate  < new Date().setHours(24, 59, 59, 999)
             ? <></>
             : currentEvent.dynamoid
               ? <></>
               :  <Tooltip title={'Needs To Be Synced in Dynamo'} key={`announcementSyncError${currentEvent.id}Tooltip`}>

               <Box sx={{position: 'absolute', right: 5}}>
                   <Button sx={{ fontSize: 14, cursor: currentEvent.actions.length === 0 ? 'not-allowed' : 'pointer' }} onClick={syncDynamo} disabled={currentEvent.actions.length === 0}>
                     <WarningRoundedIcon sx={{ color: red[500], fontSize: 40 }} />
                   </Button>
                 </Box></Tooltip>}
         </Box>
         <Box sx={{ display: 'flex', padding: 2, alignItems: 'center',whiteSpace: 'pre-line' }}>

           <Typography sx={{display: 'inline'}} >{` ${currentEvent.message.substring(0, 150)}...`}</Typography>
          </Box>

           <Stack direction="row" sx={{width: 'inherit', padding: 3, alignItems: 'center'}}>
           {
             currentEvent.actions.map((action) =>
               <MenuItem key={`menuItem${action}`} onClick={onMenuItemClicked(action)}>

                 <ListItemIcon>
                   {getActionIcon(action)}
                 </ListItemIcon>
                 <ListItemText>{action}</ListItemText>

               </MenuItem>
             )
           }
           </Stack>
         </Popover>}
      </>
    :<></>}
    </div>
  )
}
