import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { indigo } from '@mui/material/colors';

import { getPlayerPoints } from '../../../models/Player/Points'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IMSkeletonLoader from '../../common/IMSkeletonLoader'

import Grid from '@mui/material/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 50
      },
    paperScrollPaper: {
      background: 'transparent',
       boxShadow: 'none'
    },
    messageStyled: {
      fontWeight: '700',
      color: indigo[900],
      fontSize: '20px'
    }
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '24px',

  lineHeight: '133.4%',
  marginBottom: 15
}))

const GridItemTitle = styled(Grid)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '100%',
  /* identical to box height, or 12px */

  letterSpacing: '1px',

  /* Gray/600 */
  color: '#999999',
  marginBottom: 5
}))

const GridItemPoints = styled(Grid)(({ theme }) => ({
  fontWeight: 400,
  // fontSize: '34px',
  fontSize:' x-large',
  lineHeight: '123.5%',
  /* or 42px */

  letterSpacing: '0.25px',

  /* Light/Other/Rating Active */

  color: '#FFB400'
}))

const GridItemValue = styled(Grid)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '143%',
  /* identical to box height, or 20px */

  letterSpacing: '0.17px',
  // textDecorationLine: 'underline',

  /* Theme/Dark */

  color: '#212529'
}))


export default function BusinessMetrics(props) {

  const [ mount, setMount ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ lrPlayer, setLRPlayer ] = useState(null)
  const [ summaryMetrics, setSummaryMetrics ] = useState({})

  const classes = useStyles();

  useEffect(() => {
    if (!mount ) {
      setMount(true)
      setupMetrics(props.player)
    }

    if (!lrPlayer && props.lrPlayer) {
      setLRPlayer(props.lrPlayer)
      setupMetrics(props.lrPlayer)
    }

  })

  const setupMetrics = (summaryPlayer) => {
    setLoading(true)
    if (summaryPlayer) {
      const summary = {
        'Acquisition Ch': { value: summaryPlayer.acquisitionSource || 'Not Specified', type: '' },
        'Revenue': { value: summaryPlayer.totalRevenue || 0, type: '$' },
        'Profit': { value: summaryPlayer.profit || 0, type: '$' },
        'Purchases': { value: summaryPlayer.inAppPurchaseRevenue  || 0, type: '$' },
        // 'Install CONFIRMATION Rate'
      }
      setSummaryMetrics(summary)
    }


    setLoading(false)
  }

  return (
    <>{
      <Box sx={{ flexGrow: 1, marginLeft: '23px', }}>
      <Title>Business Metrics</Title>
      {loading
        ? <IMSkeletonLoader key={`skeletonKeyItem`} itemsNumber={8} columns={2} variant={'rectangular'}/>

        :  <Grid container spacing={0.5} sx={{ marginBottom: 2, display: 'flex',
                  alignItems: 'baseline' }}>
      { Object.keys(summaryMetrics).map((key) =>
          <><GridItemTitle item xs={6} key={`${key}KeyItem`}>
              {`${key.split(/(?=[A-Z])/).join(' ')}`}
            </GridItemTitle>
            <GridItemValue item xs={6} key={`${key}KeyItemValue`}>
              { summaryMetrics[key].type } {summaryMetrics[key].value.toLocaleString() }
            </GridItemValue>
        </>
        )
      }
      </Grid>}
    </Box>}</>
  )
}
