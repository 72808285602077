import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { grey } from '@mui/material/colors';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';

const NotesContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const DateItem = styled(Typography)(({ theme }) => ({
  fontWeight: 450,
  fontSize: '12px',
  lineHeight: '266%',
  /* or 32px */

  letterSpacing: '1px',
  textTransform: 'uppercase',

  /* Theme/Secondary */

  color: '#6C757D',
  // width: '120%',
}));
const ContentItem = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  /* or 24px */

  letterSpacing: '0.15px',

  /* Body Text/Body Color */

  color: '#212529',
  // display: 'inline-block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',

}));

const darkTheme = createTheme({ palette: { mode: 'dark' } });
const lightTheme = createTheme({ palette: { mode: 'light' } });

export default function Notes(props) {

  const [ notes, setNotes ] = useState(null)
  const [ refresh, setRefresh ] = useState(false)

  const [ player, setPLayer ] = useState(props.player)


  useEffect(() => {
    if (!notes || notes.length !== props.notes.length || refresh !== props.refresh) {
      setRefresh(props.refresh)
      setNotes(props.notes)
    }
  })

  function Note(props) {
    const { index, style } = props;
    const note = notes[index]
    const today  =  (new Date(note.createdAt)).toLocaleString("sv-SE", {
      timeZone: "America/Los_Angeles"
    })
    const date = `${today} PDT - ${note.admin === 'SponsorGuard' ? 'Sponsor Guard' : note.admin.firstName}`

    const content = note.note
    const ticket = note.zendeskTicketNumber
    const ticketRef = ticket ? `https://influencemobile.zendesk.com/agent/tickets/${ticket}` : ''


    return (
      <ListItem  key={index} component="Box" >
        <Grid container spacing={2} key={`noteContainer${index}`}>
          <Divider sx={{width: '-webkit-fill-available', marginLeft: '2px'}}/>
          <Grid item xs={8} sx={{paddingBottom: '16px'}}>
            <Stack spacing={0.5} >
              <DateItem sx={{width: ticket ? '120%' : '130%'}}>{date}</DateItem>


            </Stack>

          </Grid>
          {ticket && <Grid item xs={4} sx={{justifyContent: 'flex-end', display: 'flex', height: 'fit-content'}}>
            <Button
              variant="contained"
              onClick={() => window.open(ticketRef, "_blank")}
              sx={{background: '#42A5F5', padding: '3px', width: '100px'}}>
                {ticket}
            </Button>
          </Grid>}
          <Grid item xs={12} sx={{width: '-webkit-fill-available', marginTop: '-35px', marginBottom: '4px'}}>
           <Tooltip title={content}>
            <ContentItem sx={{marginBottom: 1}}>
              {content}
            </ContentItem>
            </Tooltip>
          </Grid>
        </Grid>

      </ListItem>
    );
  }

  return (
    <Box>

    { !notes
      ? <></>
      :  <NotesContainer sx={{ height: '100%' }}>
           <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: '100%',
                // overflow: 'hidden',
                // marginTop: 5
              }}
            >
            <List dense sx={{
              height: '530px',
              overflowY: 'scroll',
              width: '100%', bgcolor: 'background.paper' }}>
              {
                notes.map((note, i) =>
                <Note index={i} />
                )
              }

             </List>
         </StyledBox>
       </NotesContainer>}
    </Box>

  );
}
