import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IMHelpText from "../../../common/IMHelpText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {NumericFormat} from "react-number-format";
import React, {useState} from "react";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Divider from '@mui/material/Divider';
import SaveDevSetting from "./SaveDevSetting";
import {toCamelCase} from "../../../../models/Utils";

export default function SpenderSettings({settings, player, edit}) {
  const [iap7Value, setIap7Value] = useState(settings[toCamelCase("iap_spend.last_7_days")]?.["typedValue"] ?? null)
  const [iap30Value, setIap30Value] = useState(settings[toCamelCase("iap_spend.last_30_days")]?.["typedValue"] ?? null)
  const [iap60Value, setIap60Value] = useState(settings[toCamelCase("iap_spend.last_60_days")]?.["typedValue"] ?? null)
  const [iapLifetimeValue, setIapLifetimeValue] = useState(settings[toCamelCase("iap_spend.lifetime")]?.["typedValue"] ?? null)
  const [iapDate, setIapDate] = useState(settings[toCamelCase("last_in_app_purchase")]?.["typedValue"] ?? null)
  const [sessionDate, setSessionDate] = useState(settings[toCamelCase("last_in_app_session")]?.["typedValue"] ?? null)

  const iapConfig = {
    "iap_spend.last_7_days": {type: "Float", value: iap7Value},
    "iap_spend.last_30_days": {type: "Float", value: iap30Value},
    "iap_spend.last_60_days": {type: "Float", value: iap60Value},
    "iap_spend.lifetime": {type: "Float", value: iapLifetimeValue},
  };

  const dateConfig = {
    "last_in_app_session": {type: "Date", value: iapDate},
    "last_in_app_purchase": {type: "Date", value: sessionDate}
  };

  return (
    <div>
      <Stack direction="row" spacing={3}>
        <Stack spacing={2}>
          <IMHelpText>
            <p>
              Inform the total IAP mock value we want to test with
            </p>
          </IMHelpText>
          <NumericFormat
            customInput={TextField}
            disabled={!edit}
            label="Total spent - Last 7 days"
            variant="outlined"
            size="small"
            value={iap7Value}
            onChange={(e) => setIap7Value(e.target.value)}
          />
          <NumericFormat
            customInput={TextField}
            disabled={!edit}
            label="Total spent - Last 30 days"
            variant="outlined"
            size="small"
            value={iap30Value}
            onChange={(e) => setIap30Value(e.target.value)}
          />
          <NumericFormat
            customInput={TextField}
            disabled={!edit}
            label="Total spent - Last 60 days"
            variant="outlined"
            size="small"
            value={iap60Value}
            onChange={(e) => setIap60Value(e.target.value)}
          />
          <NumericFormat
            customInput={TextField}
            disabled={!edit}
            label="Total spent - Lifetime"
            variant="outlined"
            size="small"
            value={iapLifetimeValue}
            onChange={(e) => setIapLifetimeValue(e.target.value)}
          />
        </Stack>
        <Divider orientation="vertical" flexItem/>
        <Stack spacing={2}>
          <IMHelpText>
            <p>
              Inform required dates for Boost Challenge
            </p>
          </IMHelpText>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(params) => <TextField {...params} />}
              label="Last In-App Session"
              value={sessionDate}
              disabled={!edit}
              onChange={(value) => setSessionDate(value)}
            />
            <DatePicker
              renderInput={(params) => <TextField {...params} />}
              label="Last In-App Purchase"
              value={iapDate}
              disabled={!edit}
              onChange={(value) => setIapDate(value)}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>

      <Box sx={{mt: 4}}>
        <SaveDevSetting config={{...iapConfig, ...dateConfig}} playerId={player.id} edit={edit} />
      </Box>
    </div>
  );
}