// import { processResponse, toCamelCase, getDateTime, processParamsToServer } from './Utils'
import { getHeaders,  baseUrl } from '../utils';

export async function getSpotlights(playerId){

    try {
      const response  = await fetch(`${baseUrl}/spotlight/read/${playerId}`,{
        method: "GET",
        credentials: 'include',
        headers: getHeaders(),
      })

      const responseBack = await response.json()
      console.log(responseBack)
      return responseBack.data
  } catch (err) {
    return { status: 401 };
  }
}