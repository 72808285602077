import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table'

import { editScoreFlag, newScoreFlag } from '../../models/Health/ScoreFlag'
import ProgressScoreBar from './ProgressScoreBar'
import ConfirmDialog from '../common/ConfirmDialog'
import IMLoader from '../common/IMLoader'
import IMAlerts from '../common/IMAlerts';
import { createStyles, makeStyles } from "@material-ui/core";

import Switch from '@mui/material/Switch';
import SyncIcon from '@mui/icons-material/Sync';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material-next/Chip';
import {
  useGridApiRef,
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { formatDate, formatTime } from '../common/Utils'


function EditToolbar(props) {

}


export default function ScoreFlagsTable(props) {
  const [ rows, setRows ] = useState(null);
  const [ rowModesModel, setRowModesModel ] = useState({});
  const [ editedRow, setEditedRow ] = useState(null);
  const [ globalFilter, setGlobalFilter ] = useState('')
  const [ filteredFlags, setFilteredFlags ] = useState(null)
  const [ refresh, setRefresh ] = useState(false)
  const [ statusFiltered, setStatusFiltered ] = useState(0)
  const [ typeFiltered, setTypeFiltered ] = useState(0)
  // const [ cellModesModel, setCellModesModel ] = useState({});
  const [ sumStrike, setSumStrike ] = useState(0);
  const [ sumStars, setSumStars ] = useState(0);
  const [ checkedScores, setCheckedScores ]  = useState([]);
  const [ dialogProps, setDialogProps ] = useState(null);
  const [ loading, setLoading ] = useState(false)
  const [ alert, setAlert ] = useState(null)
  const [ loadingMessage, setLoadingMessage ] = useState('')
  const [ editedRows, setEditedRows ] = useState({})
  const [ pending, setPending ] = useState(props.pending)
  const [ newFlag, setNewFlag ] = useState({})
  const [ backfilledWeightChange, setBackfilledWeightChange ] = useState(true)

  const apiRef = useGridApiRef();

  const setupScoreFlagsRows = () => {
    const flagsRows = props.scoreFlags.map((scoreFlag) => ({
      id: scoreFlag.id,
      activeStatus: pending && pending.id === scoreFlag.id ? pending.activeStatus : scoreFlag.activeStatus,
      description: scoreFlag.description,
      weight: pending && pending.id === scoreFlag.id ? pending.weight : scoreFlag.weight,
      scoreType: scoreFlag.scoreType.charAt(0).toUpperCase() + scoreFlag.scoreType.slice(1),
      lastEdited: `${formatDate(new Date(scoreFlag.lastEdited))} - ${formatTime(new Date(scoreFlag.lastEdited))}`,
      editedBy: `${scoreFlag.editedBy.firstName} ${scoreFlag.editedBy.lastName}`,
      categories: scoreFlag.categories
    }))

    setRows(flagsRows)
    setFilteredFlags(flagsRows)
    setLoading(false)
    setLoadingMessage(null)

  }
  useEffect(() => {
    if (!rows && props.scoreFlags) {
      setLoading(true)
      setLoadingMessage('Loading Score Flags')
      setupScoreFlagsRows()
    }
  })


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
    setEditedRow(null)
  };

  const handleRowEditStart = (params, event) => {
    setEditedRow(params.id)
  };

  const handleEditClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, fieldToFocus: 'activeStatus' } });
    setEditedRow(id)
  };

  const handleOnCellModesModelChange = (cellModesModel, gridCallbackDetails) => {
    // console.log('handleOnCellModesModelChange', cellModesModel, gridCallbackDetails)
  }

  const saveRowChanges = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  }

  const handleSaveClick = (id) => () => {
    saveRowChanges(id)
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

  };

  const cancelRowChanges = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = filteredFlags.find((row) => row.id === id);
    if (editedRow.isNew) {
      setFilteredFlags(filteredFlags.filter((row) => row.id !== id));
    }

    setEditedRow(null)
  }

  const handleCancelClick = (id) => () => {
    cancelRowChanges(id)
  };


  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function ActiveStatusCell(props) {
    if (props.value == null) {
      return null;
    }

    return (
      <Chip label={props.value} color={"error"} disabled={props.value === 'Inactive'}/>
    );
  }
  function WeightCell(props) {
    if (props.value == null) {
      return null;
    }

    return (
      <Chip label={props.value} color={props.value > 0 ? "success" : "error"} />
    );
  }
  const onClickScore = (props) => (event) => {
    const checked = event.target.checked;
    const weight = props.row.weight;

    let currentChecked = checkedScores

    if (currentChecked.includes(props.id)) {
      currentChecked = currentChecked.filter((id) => id !== props.id)
    } else {
      currentChecked.push(props.id)
    }
    setCheckedScores(currentChecked)
    if (weight < 0) {
      checked ? setSumStrike(sumStrike + weight) : setSumStrike(sumStrike - weight)
    } else {
      checked ? setSumStars(sumStars + weight) : setSumStars(sumStars - weight)
    }
  }
  function DescriptionCell(props) {
    if (props.value == null) {
      return null;
    }
    return (
      <FormControlLabel control={<Checkbox  onClick={onClickScore(props)} checked={checkedScores.includes(props.id)}/>} label={props.value} />

    )

  }

  const columns = [
    { field: 'activeStatus', headerName: 'Status', width: 120, editable: props.edit && (pending === null),
      type: 'singleSelect',
      valueOptions: ['Active', 'Inactive'],
      renderCell: (params) => <ActiveStatusCell {...params} /> },

    { field: 'description', headerName: 'Description', width: 580, renderCell: (params) => <DescriptionCell{...params} />,},
    { field: 'scoreType', headerName: 'Type'},
    {
      field: 'weight',
      headerName: 'Weight',
      type: 'number',
      width: 80,
      align: 'left',
      headerAlign: 'left',
      editable: props.edit && (pending === null),

      renderCell: (params) => <WeightCell {...params} />
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit || editedRow === id;
        // const row = initialRows.filter((row) => row.id === id)
        const rowSelected = filteredFlags.filter((row) => row.id === id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              disabled={pending !== null}
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              disabled={pending !== null}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            disabled={pending !== null}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={rowSelected.activeStatus === 'Active' ? <StopCircleOutlinedIcon /> : <PlayArrowIcon />}
          //   label={rowSelected.activeStatus === 'Active' ? "Stop" : "Activate"}
          //   onClick={handleStopPlayClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
    {
      field: 'lastEdited',
      headerName: 'Last Edited',
      // type: 'date',
      width: 280,
    },
    {
      field: 'editedBy',
      headerName: 'Edited By',
      width: 220,

    },

  ];

  const filterByDescription = (rowsToFilter, value) => {
    if (value) {
      rowsToFilter = rowsToFilter.filter((flag) => flag.description.toLowerCase().includes(value.toLowerCase()));
    }
    return rowsToFilter
  }
  const filterByType = (rowsToFilter, nextType) => {
    if (nextType === 0) {
      // setFilteredFlags(rowsToFilter)
    } else {

      rowsToFilter = nextType === 1
        ? rowsToFilter.filter((row) => row.scoreType === 'Strike')
        : rowsToFilter.filter((row) => row.scoreType === 'Stars')
    }
    return rowsToFilter
  }
  const filterByStatus = (rowsToFilter, nextStatus) => {
    if (nextStatus === 0) {
      // setFilteredFlags(rowsToFilter)
    } else {

      rowsToFilter = nextStatus === 1
        ? rowsToFilter.filter((row) => row.activeStatus === 'Active')
        : rowsToFilter.filter((row) => row.activeStatus === 'Inactive')
    }
    return rowsToFilter
  }

  const handleChangeTypeFilter = (event) => {
    const filterTypeValue = event.target.value
    setTypeFiltered(filterTypeValue)

    let newFilteredFlags = filterByDescription(rows, globalFilter)
    newFilteredFlags = filterByStatus(newFilteredFlags, statusFiltered)

    newFilteredFlags = filterByType(newFilteredFlags, filterTypeValue)

    setFilteredFlags(newFilteredFlags)
  }
  const handleChangeStatusFilter = (event) => {
    const filterStatusValue = event.target.value
    setStatusFiltered(filterStatusValue)

    let newFilteredFlags = filterByDescription(rows, globalFilter)
    newFilteredFlags = filterByType(newFilteredFlags, typeFiltered)

    newFilteredFlags = filterByStatus(newFilteredFlags, filterStatusValue)

    setFilteredFlags(newFilteredFlags)

  }

  const globalFilterChange = (globalFilterValue) => {
    let newFilteredFlags = filterByStatus(rows, statusFiltered)
    newFilteredFlags = filterByType(newFilteredFlags, typeFiltered)

    newFilteredFlags = filterByDescription(newFilteredFlags, globalFilter)

    setFilteredFlags(newFilteredFlags)
    setRefresh(!refresh)
  }

  const onChangeText = useAsyncDebounce(value => {
    setGlobalFilter(value.trim() === true ? '' : value || '')
    globalFilterChange(value)

  }, 200)

  const onCloseAlert = () => {
    setAlert(null)
  }

  const onCloseDialog = () => {
    setDialogProps(null)
    // setEnableDialogEditing(false)
  }
  const onDialogCancel = (dialogEdited) => {
    onCloseDialog()

    setRowModesModel({
      ...rowModesModel,
      [dialogEdited.id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = filteredFlags.find((row) => row.id === dialogEdited.id);
    const rollbackRows = filteredFlags.map((row) => row.id === dialogEdited.id ? editedRow : row )
    setFilteredFlags(rollbackRows)
    setEditedRow(null)
    setNewFlag({})
  }

  const onConfirmDialog =  async (editedDialog) => {

    if (dialogProps && Object.keys(dialogProps).length > 0) {
      const dialogType = dialogProps.type
      const newFlag = editedDialog.newFlag

      setLoading(true)
      let response = null
      let message = ''

      switch (dialogType) {
        case 'New':
          onConfirmNewFlag()
          return
          break;
        case 'Edit':
          setLoadingMessage('Editing Score Flag')
          const flag = filteredFlags.filter((filterFlag) => filterFlag.id === newFlag.id)[0]
          let changes = ''

          changes = flag.weight !== newFlag.weight ? `Weight: ${flag.weight} -> ${newFlag.weight}` : ''
          changes += flag.activeStatus !== newFlag.activeStatus ? `Active Status: ${flag.activeStatus} -> ${newFlag.activeStatus}` : ''

          const data = {
            id: newFlag.id,
            weight: newFlag.weight,
            activeStatus: newFlag.activeStatus,
            editedBy: props.currentUser.id,
            backfilledWeightChange,
            changes
          }

          response = await editScoreFlag({ data })

          if (response.status === 200) {
            message = 'Score Flag is being updated!'
            apiRef.current.updateRows([{ id: flag.id, editedBy: `${props.currentUser.firstName} ${props.currentUser.lastName}` }]);
            props.refetchLogs()
            // props.refetchScores()
            setPending(newFlag)
            props.setPending(true)
          } else {

            apiRef.current.updateRows([{ id: flag.id, activeStatus: flag.activeStatus, weight: flag.weight }]);
            message = response.error || 'Failed to update values!'

          }
          setEditedRow(null)

          break;

        default:
          console.log("un supported dialog type");



      }
      if (response) {

        const severity = response.status === 200 ? 'success' : 'error'

        setAlert({ display: true, severity, message })

      }
      onCloseDialog()

    }
    setLoading(false)
    setLoadingMessage('')
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(filteredFlags.map((row) => (row.id === newRow.id ? updatedRow : row)));
    onEditScoreFlag(newRow)
    return updatedRow;
  };

  const loaderSync = {

         animation: "spin 2s linear infinite",

        "@keyframes spin": {
          "0%": {
            transform: "rotate(360deg)"
          },
          "100%": {
            transform: "rotate(0deg)"
          }
        }
  }
  const newFlagChange = (field) => (e) => {
    const value = e.target.value
    let newFlagUpdate = newFlag
    newFlagUpdate[field] = value
    setNewFlag(newFlagUpdate)
  }

  const onConfirmNewFlag = async () => {

    if (newFlag && Object.keys(newFlag).length > 0) {
      setLoading(true)

      const response = await newScoreFlag({ data: newFlag })
      let message = ''
      if (response.status === 200) {
        message = 'Score Flag is being created!'
        props.refetchLogs()
        props.refetchScores()
      } else {

        message = response.error || 'Failed to create new  flag!'
      }
      const severity = response.status === 200 ? 'success' : 'error'

      setAlert({ display: true, severity, message })
      setLoading(false)
    }

    setNewFlag({})
    onCloseDialog()
  }

  const syncNewFlag = () => {
    const content = <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
    <TextField
       required
       id="outlined-required"
       label="Name"
       onChange={newFlagChange('name')}
     />
     <TextField
        required
        id="outlined-required"
        label="Description"
        onChange={newFlagChange('description')}

      />
      <TextField
        required
        id="outlined-number"
        label="Weight"
        type="number"
        onChange={newFlagChange('weight')}

        InputLabelProps={{
          shrink: true,
        }}
      />

      <FormControl sx={{minWidth: '250px'}} >
       <InputLabel id="demo-simple-select-label">Category</InputLabel>
       <Select
         labelId="demo-simple-select-label"
         id="demo-simple-select"
         value={newFlag['category']}
         label="Catgegory"
         onChange={newFlagChange('category')}
       >
         <MenuItem value={'device'}>device</MenuItem>
         <MenuItem value={'behaviors'}>behaviors</MenuItem>
         <MenuItem value={'external_factors'}>external_factors</MenuItem>
         <MenuItem value={'internal_standards'}>internal_standards</MenuItem>

       </Select>
     </FormControl>

    </Box>

    const dialogProps = {
      content,
      title: 'Sync New Score Flag',
      type: 'New'
    }
    setDialogProps(dialogProps)
  }

  const handleChangeWeightBackfill = (event) => {
    const backfilled = event.target.value;
    setBackfilledWeightChange(backfilled === "0")
  };


  const onEditScoreFlag = (flag) =>  {

    if (flag) {
      const oldFlag = rows.filter((oldFlag) => oldFlag.id === flag.id)[0]
      if (oldFlag) {

        const content = <Stack spacing={4}>
        {oldFlag.activeStatus !== flag.activeStatus &&
          <Typography component="span">
           The status will be changed from <Typography variant="subtitle2" component="span" sx={{fontWeight: 700}}>{oldFlag.activeStatus}</Typography> to <Typography variant="subtitle2" component="span" sx={{fontWeight: 900, color: 'green'}}>{flag.activeStatus}</Typography>
          </Typography>}
          {oldFlag.weight !== flag.weight &&
          <FormControl>
           <FormLabel id="chanegWeightIdForm">
            <Typography variant="subtitle1" component="span">
            You’re about to update the values for  <Typography variant="subtitle2" component="span" sx={{fontWeight: 700, textDecoration: 'underline'}}>"{flag.description}"</Typography> from <Typography variant="subtitle2" component="span" sx={{fontWeight: 700}}>{oldFlag.weight}</Typography>  to  <Typography variant="subtitle2" component="span" sx={{fontWeight: 900, color: 'green'}}>{flag.weight}</Typography>. Are you sure you want to continue with this change?
            </Typography>
           </FormLabel>
           <RadioGroup
             aria-labelledby="changeWeightLabelId"
             defaultValue="0"
             name="chanegWeightGroup"
             onChange={handleChangeWeightBackfill}
           >
             <FormControlLabel value="0" control={<Radio />} label="Recalculate score for players that currently have this flag." />
             <FormControlLabel value="1" control={<Radio disabled/>} label="Consider new score for new cases only." />
           </RadioGroup>
         </FormControl>}
        </Stack>
        if (oldFlag.activeStatus !== flag.activeStatus || oldFlag.weight !== flag.weight) {
          setDialogProps(
            { content,
              id: flag.id,
              newFlag: flag,
              title: 'Edit Score Flag - Please Confirm the following changes',
              type: 'Edit',
              contentConfirm: '',
              enableConfirm: true,
              confirmBtnText: 'Confirm and continue',
             })
        }

       }

    }
  }
  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
    {loadingMessage && <IMLoader message={loadingMessage}/>}
    {alert && alert.display &&
      <IMAlerts
        onClose={onCloseAlert}
        severity={alert.severity}
        message={alert.message}/>}
    {dialogProps && <ConfirmDialog
        onCancel={onDialogCancel}
        contentConfirm={dialogProps.contentConfirm}
        {...dialogProps}
        onConfirm={onConfirmDialog}
        enableConfirm={true}
        />}
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0}>
         <Grid item xs={3}>
           <Typography sx={{fontSize: '34px', fontWeight: 700,
             }} color="text.primary" >
               Health Score
            </Typography>
         </Grid>
         <Grid item xs={4}>
           <ProgressScoreBar
             error
             total={-1 * props.totalNegative}
             precentage={100 * -1 * (sumStrike) / props.totalNegative}
             sum={sumStrike}
             title={"Strike(-)"}/>
         </Grid>
         <Grid item xs={4}>
          <ProgressScoreBar
            success
            total={props.totalPositive}
            precentage={100 * sumStars/props.totalPositive}
            sum={sumStars}
            title={"Stars(+)"}/>
         </Grid>
         <Grid item xs={1}>
           <Box
             sx={{
               display: 'flex',
               flexWrap: 'wrap',

               '& > :not(style)': {
                 borderRadius: '10px',
                 m: 1,
                 width: 71,
                 height: 68,
                 display: 'flex', alignItems: 'center', justifyContent: 'center'
               },
             }}
           >
              <Paper elevation={0}>
                <Box sx={{}}>
                <Typography sx={{fontSize: '20px', fontWeight: 700,
                  }} color="text.primary" >
                  {sumStars + sumStrike}
                  </Typography>
                </Box>
              </Paper>
            </Box>
         </Grid>
       </Grid>
       <Button variant="outlined" onClick={props.refetchScores} startIcon={<SyncIcon sx={loading ? loaderSync : {}}/>}>
          ReSync
        </Button>
        <Button variant="outlined" onClick={syncNewFlag} startIcon={<AddCircleIcon sx={loading ? loaderSync : {}}/>}>
           SYNC NEW SCORE FLAG
         </Button>
     </Box>
      <Box  sx={{backgroundColor: 'white', marginTop: 5
      }}>
      <Grid container spacing={0}>
       <Grid item xs={2}>
         <TextField
            label="Search by description"
            id="filled-start-adornment"
            sx={{ m: 1, width: '25ch' }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            variant="outlined"
            onChange={e => {
              onChangeText(e.target.value);
            }}
          />
       </Grid>
       <Grid item xs={1}>
         <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="selectScoreStatusIL">Status</InputLabel>
            <Select
              labelId="selectScoreStatus"
              id="selectScoreStatusId"
              value={statusFiltered}
              label="Status"
              onChange={handleChangeStatusFilter}
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={2}>Inactive</MenuItem>
            </Select>
          </FormControl>
       </Grid>
       <Grid item xs={1}>
         <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="selectScoreTypeIL">Type</InputLabel>
            <Select
              labelId="selectScoreType"
              id="selectScoreTypeId"
              value={typeFiltered}
              label="Type"
              onChange={handleChangeTypeFilter}
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>Strike</MenuItem>
              <MenuItem value={2}>Stars</MenuItem>
            </Select>
          </FormControl>
       </Grid>
       <Grid item xs={8}>
        {pending && <Box sx={{background: '#FFF9C4',
                  height: '56px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '8px'}}>
        <Typography sx={{fontSize: '14px', fontWeight: 400, margin: 2
          }} >
            Player’s score currently being retroactively recalculated. Once this process is complete, you will regain the ability to edit the checks.
         </Typography>
        </Box>}

       </Grid>
      </Grid>

      </Box>

      {rows && <DataGrid
        apiRef={apiRef}
        editMode="row"
        rows={filteredFlags}
        columns={props.edit ? columns : columns.filter((col) => col.type !== 'actions')}
        sx={{backgroundColor: 'white', borderColor: 'transparent'
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        onRowEditStart={handleRowEditStart}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, setFilteredFlags },
        }}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount
        onCellModesModelChange={handleOnCellModesModelChange}
      />}
    </Box>
  );
}
