import React, { useContext } from "react";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Stack,
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import ChallengeActivityGroupRow from "./ChallengeActivityGroupRow";
import {ChallengesContext} from "./ChallengesContext";

const ChallengesTable = () => {
  const { shownChallengeGroups } = useContext(ChallengesContext);

  console.log("shownChallengeGroups in ChallengesTable", shownChallengeGroups)

  const HeaderTableCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.root}`]: {
      fontWeight: 'bold',
      border: 'none',
      textAlign: "center"
    },
  }))

  return(
    <>
    {  shownChallengeGroups.length > 0 &&
      <Stack>
        <TableContainer>
           <TableHead>
              <TableRow>
                <HeaderTableCell />
                <HeaderTableCell style={{textAlign: "left"}}>Created</HeaderTableCell>
                <HeaderTableCell>Type</HeaderTableCell>
                <HeaderTableCell>Must Complete</HeaderTableCell>
                <HeaderTableCell>Prize</HeaderTableCell>
                <HeaderTableCell>Status</HeaderTableCell>
                <HeaderTableCell>Completed</HeaderTableCell>
                <HeaderTableCell />
              </TableRow>
            </TableHead>
          <TableBody>
            {shownChallengeGroups.map((group) => (
              <ChallengeActivityGroupRow
                key={group.challengeId}
                group={group}
              />
            ))}
          </TableBody>
        </TableContainer>
      </Stack>
    }
    </>


  )
}

export default ChallengesTable