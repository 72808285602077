import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => (
  <Box sx={{display: "flex", p: 12, justifyContent: "center"}}>
    <CircularProgress/>
  </Box>
);

export default Loading;
