import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import ScoreFlagsTable from './ScoreFlagsTable'
import PlayersScoreFlags from './PlayersScoreFlags'
import ScoreFlagsLogs from './ScoreFlagsLogs'
import PlayersHealth from './PlayersHealth'
import RedemptionsStatus from './RedemptionsStatus'

import { getScoreFlags } from '../../models/Health/ScoreFlag'
// import { getAllPlayersScoreFlags } from '../../models/Health/PlayerScoreFlag'
import { getUserLogs } from '../../models/UserLog'

import IMLoader from '../common/IMLoader'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function TabPanel(props) {
  const { children, tabView, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabView !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabView === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  tabView: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function HealthContainer(props) {


  const [ loading, setLoading ] = useState(false)
  const [ score, setScore ] = useState(null)
  const [ playerHealthRecords, setPlayerHealthRecords ] = useState(null)
  const [ scoreFlagsLogs, setScoreFlagsLogs ] = useState(null)
  const [ scoreFlags, setScoreFlags ] = useState(null)
  const [ read, setRead ] = useState(false)
  const [ edit, setEdit ] = useState(false)
  const [ tabView, setTabView ] = React.useState(0)
  const [ playersHealth, setPlayersHealth ] = useState(null)
  const [ pending, setPending ]  = useState(null)
  const [ totalNegative, setTotalNegative ] = useState(0)
  const [ totalPositive, setTotalPositive ] = useState(0)

    const handleChangeTab = (event, newValue) => {
      setTabView(newValue);
    };


  const refetchLogs = async () => {
    const response = await getUserLogs({ logsType: 'health'})

    if (response.status === 200 && response.logs) {
      setScoreFlagsLogs(response.logs)
    }
  }

  const fetchScores = async () => {
    setLoading(true)
    const response = await getScoreFlags()

    console.log('ALL SF RESPONSE ', response)
    if (response.status === 200 ) {
      setScoreFlags(response.scoreFlags)
      setPending(response.pendingRecord)
      // setPending(true)
      setTotalNegative(response.totalNegative)
      setTotalPositive(response.totalPositive)
      setRead(response.read)
      setEdit(response.edit)
    } else {
      setScoreFlags([])
    }

     setLoading(false)
  }
  const healthSetup = async () => {
    if (loading || scoreFlags) {
      return
    }

    setLoading(true)
    fetchScores()
    const response2 = await getUserLogs({ logsType: 'health'})

    if (response2 && response2.status === 200 && response2.logs) {
      setScoreFlagsLogs(response2.logs)
    }
      setLoading(false)
  }

  useEffect(() => {
    if (!loading && scoreFlags === null && props.currentUser) {
      setLoading(true)
      healthSetup()
    }

  })


  return (

    <Box sx={{ marginLeft: 8}}>
      <Box sx={{ width: '100%' }}>
       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
         <Tabs value={tabView} onChange={handleChangeTab} aria-label="basic tabs example">
           <Tab label="Score Flags Configs" {...a11yProps(0)} />
           <Tab label="Score Flags User Logs" {...a11yProps(1)} />
           <Tab label="Players Health" {...a11yProps(2)} />
           <Tab label="Players Redemptions Status" {...a11yProps(3)} />

         </Tabs>
       </Box>
       <TabPanel tabView={tabView} index={0}>
         <Box sx={{margin: 1
         }}>{
           loading
             ? <IMLoader message={'Loading Score Flags...'} mini/>
             : scoreFlags && read
               ? <ScoreFlagsTable
                   currentUser={props.currentUser}
                   edit={edit}
                   pending={pending}
                   setPending={setPending}
                   refetchScores={fetchScores}
                   refetchLogs={refetchLogs}
                   totalNegative={totalNegative}
                   totalPositive={totalPositive}
                   scoreFlags={scoreFlags}/>
               : <></>

               }
        </Box>
       </TabPanel>

       <TabPanel tabView={tabView} index={1}>
         {scoreFlagsLogs && <ScoreFlagsLogs allRows={scoreFlagsLogs} />}
       </TabPanel>

       <TabPanel tabView={tabView} index={2}>
         <PlayersHealth currentUser={props.currentUser} setPending={setPending} />
       </TabPanel>

       <TabPanel tabView={tabView} index={3}>
         <RedemptionsStatus currentUser={props.currentUser} setPending={setPending} />
       </TabPanel>
     </Box>

    </Box>

  )
}

// <TabPanel tabView={tabView} index={1}>
//   {playerHealthRecords && <PlayersScoreFlags allRows={playerHealthRecords} refetchLogs={refetchLogs}/>}
// </TabPanel>
