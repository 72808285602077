import React, {useContext, useState} from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import {buttonClasses} from "@mui/material/Button";
import { styled } from '@mui/material/styles';


const OfferBonusRow = (props) => {
  const bonus = props.bonus
  const highlightColor = '#f6f1bd'

  const BonusTableCell = styled(TableCell)(({})=>({
    [`&.${tableCellClasses.root}`]: {
      padding: "5px 10px"
    },
  }))

  const CompleteButton = styled(Button)(({})=>({
    [`&.${buttonClasses.root}`]: {
      width: "80%",
      fontSize: "12px",
      padding: 0,
      margin: 0,
      "&.Mui-disabled": {
        backgroundColor: '#BDBDBD',
        color: "white"
      }
    },
  }))

  function rowStyles(){
    let backgroundColor = "inherit"
    if(bonus.highlighted){backgroundColor = highlightColor}
    return {
      backgroundColor: backgroundColor,
    }
  }

  function cappedDisplay(){
    if(bonus.showButton && !bonus.capped){
      return <CompleteButton variant="contained">
              COMPLETE
            </CompleteButton>
    }
    if(bonus.capped && !bonus.showButton){return <span>Capped</span>}
    if (!bonus.capped && !bonus.showButton){return <span>Incomplete</span>}
  }

  function typeDisplay(){
    if(bonus.icon === "dartboard"){
      return <span role="img" aria-label="dartboard" style={{fontSize: 15, textAlign: "center", marginLeft: "5px"}}>🎯</span>
    } else {
      return ""
    }
  }

  return(
    <TableRow style={rowStyles()}>
      <BonusTableCell>{bonus.title}</BonusTableCell>
      <BonusTableCell align="center">{typeDisplay()}</BonusTableCell>
      <BonusTableCell align="center">{bonus.points}</BonusTableCell>
      <BonusTableCell align="center">{cappedDisplay()}</BonusTableCell>
    </TableRow>
  )
}

export default OfferBonusRow