import { editScoreFlagCall, getScoreFlagsCall, newScoreFlagCall } from '../../api/Health/ScoreFlag'
import { processResponse, toCamelCase, processParamsToServer } from '../Utils'



export async function getScoreFlags() {
  const response = await getScoreFlagsCall();
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
export async function editScoreFlag({ data }) {
  const dataToServer = processParamsToServer(data)
  const response = await editScoreFlagCall({ data: dataToServer });
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function newScoreFlag({ data }) {
  const dataToServer = processParamsToServer(data)
  const response = await newScoreFlagCall({ data: dataToServer });
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
