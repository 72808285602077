import React, { useEffect, useState } from 'react';
import { getPlayerScoreFlags } from '../../../../models/Health/PlayerScoreFlag'

import Box from '@mui/material/Box';
import HomeHeader from './HomeHeader'
import PlayerTimeline from './PlayerTimeline'
import HealthCard from './HealthCard'
export default function HomeContainer(props) {
  const [ loading, setLoading ] = useState(false)
  const [ playerHealth, setPlayerHealth ] = useState(null)
  const [ totalScore, setTotalScore ] = useState(null)
  const [ totalScoresSum, setTotalScoresSum ] = useState(null)
  const healthSetup = async () => {
    if (loading) {
      return
    }

    setLoading(true)
    const response = await getPlayerScoreFlags(props.player.id)
    console.log('HEALTH PLAUYER RESPONSE ', response)


    if (response.status === 200 && response.playersHealth) {
        setPlayerHealth(response.playersHealth)
        setTotalScore(response.healthRecord.totalScore)
        setTotalScoresSum({totalNegative: response.totalNegative, totalPositive: response.totalPositive})

      // setScoreFlags(response.badHealthRecords)
      // setRead(response.read)
      // setEdit(response.edit)
    } else {
      setPlayerHealth([])
    }
      setLoading(false)
  }

  useEffect(() => {
    if (!loading && playerHealth === null && props.player) {
      healthSetup()
    }

  })
  return (
    <Box >
      <HomeHeader player={props.player}/>
      {playerHealth && Object.keys(playerHealth).length > 0 &&
        <HealthCard playerHealth={playerHealth} totalScore={totalScore} totalScoresSum={totalScoresSum}/>}
    </Box>
  )
}
