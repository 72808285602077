import axios from 'axios';
import { getHeaders,  baseUrl } from './utils';

export async function getAnnouncementCall({ announcementType }) {

  try {
    // const params = updateParams
    const response  = await fetch(`${baseUrl}/announcements/${announcementType}`,{
      method: "GET",
      credentials: 'include',
      headers: getHeaders(),


    })


    const responseBack = await response.json()
    return responseBack
  } catch (err) {
    return { status: 500 };

  }
}

export async function newAnnouncementCall({ announcement }) {
  try {
    const response  = await fetch(`${baseUrl}/new_announcement`,{
      method: "POST",
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(
        announcement
      )

    })

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 401 };

  }
}

export async function updateAnnouncementCall({ announcement, announcementId }) {
  try {
    const response  = await fetch(`${baseUrl}/edit_announcement/${announcementId}`,{
      method: "POST",
      credentials: 'include',
      headers: getHeaders(),
      body: JSON.stringify(
        announcement
      )

    })

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 401 };

  }
}

export async function deleteAnnouncementCall({ announcementId }) {
  try {
    const response  = await fetch(`${baseUrl}/delete_announcement/${announcementId}`,{
      method: "PUT",
      credentials: 'include',
      headers: getHeaders()

    })

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 401 };

  }
}
