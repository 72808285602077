import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

export default function IMSkeletonLoader({ width, height, variant, itemsNumber, columns }) {



  // const getColumnSize = (index) => {
  //   return index % 2 === 0 ? (12 / columns) / :
  // index % 2 === 0 ? 1 : 5
  // }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0.5} sx={{margin: 2}}>

      {Array.from(Array(itemsNumber)).map((_, index) => (
        <Grid item xs={ index % 2 === 0 ? 12 / columns - 2 : 12 / columns  + 2} key={index}>
          <Skeleton
              animation="wave"
              variant={index % 2 === 0 ? variant || "circular" : variant || 'rounded'}
              width={index % 2 === 0 ? width || 20 : width || 60}
              height={height || 20} />
         </Grid>
      ))
    }
     </Grid>
    </Box>

  );
}
