import React, {useEffect, useState} from "react";
import {getDevSettings} from "../../../../models/DevSetting";
import Box from "@mui/material/Box";
import IMLoader from "../../../common/IMLoader";
import LimitedPermissionPage from "../../../common/LimitedPermissionPage";
import Accordion from "@mui/material/Accordion";
import RedemptionSettings from "./RedemptionSettings";
import SpenderSettings from "./SpenderSettings";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function DevContainer({player}) {

  const [loading, setLoading] = useState(true)
  const [devSettings, setDevSettings] = useState(null)
  const [unauth, setUnauth] = useState(null)
  const [user, setUser] = useState(null)
  const [edit, setEdit] = useState(null)
  const setupDevSettings = async () => {
    const response = await getDevSettings(player);

    if (response && !response.error && response.dev_settings) {
      setEdit(response.edit)

      setDevSettings(response.dev_settings)
    } else {
      if (response.status === 401) {
        setUnauth(true)
        setUser(response.user)
      }
      setDevSettings([])
    }
    setLoading(false)
  }

  useEffect(() => {
    if (devSettings === null) {
      setupDevSettings()
    }
  });

  return (
    <Box>
      {loading
        ? <IMLoader message={'Loading Settings...'} mini/>
        : unauth
          ? <LimitedPermissionPage/>
          : <Box sx={{width: '90%', padding: 5, backgroundColor: "white"}}>
            <div>
              <Typography variant="h5" sx={{mb: 2}}><b>Boost Challenges</b></Typography>
              <Accordion>
                <AccordionSummary expandIcon={<ArrowDropDownIcon/>}>
                  <Typography>Boost Redemption States</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{borderTop: 1, borderColor: "divider"}}>
                  <RedemptionSettings settings={devSettings} player={player} edit={edit}/>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ArrowDropDownIcon/>}>
                  <Typography>Spender Profile Variables</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{borderTop: 1, borderColor: "divider"}}>
                  <SpenderSettings settings={devSettings} player={player} edit={edit}/>
                </AccordionDetails>
              </Accordion>
            </div>
          </Box>
      }

    </Box>
  )
}
