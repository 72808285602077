import { v2Client } from "./client";

function deserializeDate(data, key) {
  if (data[key] == null) {
    return;
  }
  data[key] = new Date(data[key]);
}

export async function getOnboarding(playerId) {
  const response = await v2Client.get(`players/${playerId}/onboarding`);
  const { data } = response;
  deserializeDate(data, "started_at");
  deserializeDate(data, "completed_at");
  deserializeDate(data, "tlc_credit_at");
  deserializeDate(data, "double_rewards_at");
  return data;
}

export async function getOnboardingStatus(playerId) {
  const response = await v2Client.get(`players/${playerId}/onboarding/status`);
  return response.data.status;
}
