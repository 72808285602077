import { getRangedPlayersHealthScoreCall, editPlayersHealthRangeCall } from '../../api/Health/PlayerHealthScore'
import { processResponse, processHealthResponse, toCamelCase, processParamsToServer } from '../Utils'



export async function getRangedPlayersHealthScore({ range }) {
  const response = await getRangedPlayersHealthScoreCall({ range });
  // console.log('RESOINSE ????', response, response.status)
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) =>  res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function editPlayersHealthRange({ range, changes }) {
  const response = await editPlayersHealthRangeCall({ range, changes });
  // console.log('RESOINSE ????', response)
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) =>  res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
