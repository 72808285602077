import axios from 'axios';
import { getHeaders,  baseUrl } from './utils';


export async function initPrizesKeysCall() {
  try {
    const response  = await fetch(`${baseUrl}/setup_prize_keys`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      // body: JSON.stringify({
      //   data
      // })
      }
    )

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}
export async function getPrizesCall({ type }) {

  try {
    const response  = await fetch(`${baseUrl}/prizes/${type}`,{
      method: "GET",
      credentials: 'include',
      headers: getHeaders(),


    })

    let responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { status: 500 };

  }
}

export async function editCardCall({ data }) {

  try {
    const response  = await fetch(`${baseUrl}/edit_tango_card_denomination`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data
      })
      }
    )

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}

export async function newCardCall({ data }) {

  try {
    const response  = await fetch(`${baseUrl}/new_tango_card_denomination`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data
      })
      }
    )

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}

export async function purchaseBoostCall({ data }) {

  try {
    const response  = await fetch(`${baseUrl}/purchase/boost`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data
      })
      }
    )

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}

export async function purchaseEditCall({ type, action }) {

  try {
    const response  = await fetch(`${baseUrl}/purchase/${type}`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data: { type, action }
      })
      }
    )

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}
