import {useEffect, useState} from 'react';
import IMLoader from "../../../common/IMLoader";
import { getPlayerActivityGroups } from "../../../../models/Player/ChallengeActivityGroups";

import ChallengesTable from "./ChallengesTable";
import { ChallengesContext } from "./ChallengesContext";
import ChallengeFilters from "./ChallengeFilters";

const ChallengesContainer = () => {
  const [challengeGroups, setChallengeGroups] = useState([])
  const [shownChallengeGroups, setShownChallengeGroups] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    getPlayerActivityGroups().then((activityGroups)=>{
      console.log("initial api call", activityGroups)
      setInitialStates(activityGroups)
      setLoading(false)
    }).catch((error)=>{
      console.error(error)
      setLoading(false)
    })

  },[])

  function setInitialStates(activityGroups){
    setChallengeGroups(activityGroups)
    setShownChallengeGroups(activityGroups)
  }

  const noContentStyles = {
    fontSize: 20,
    color: 'grey',
    width: 'inherit',
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 20
  }

  return (
  <>
    { loading &&
        <IMLoader message={"Loading Boost Challenges..."} mini={true}/>
    }
    {
      challengeGroups.length > 0 && !loading &&
      <ChallengesContext.Provider value={{
        challengeGroups,
        setChallengeGroups,
        shownChallengeGroups,
        setShownChallengeGroups
      }}>
        <ChallengeFilters/>
        <ChallengesTable></ChallengesTable>
      </ChallengesContext.Provider>
    }
    {
      shownChallengeGroups.length === 0 && !loading &&
      <div style={noContentStyles}>No Boost Challenges to Show</div>
    }
  </>
  )
}

export default ChallengesContainer