import * as React from 'react';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

const newlyCreatedMarks = [
  {
    value: 0,
    label: '',
  },
  {
    value: 7,
    label: 'Onboarding Completed',
  },
  {
    value: 24,
    label: '24h',
  },
  ,
  {
    value: 35,
    label: 'Redeemed GiftCard',
  },
  {
    value: 48,
    label: '48h',
  },
  {
    value: 72 ,
    label: '72h',
  }
];
const newlyCreatedBadMarks = [
  {
    value: 0,
    label: '',
  },
  {
    value: 10,
    label: 'Redeemed GiftCard',
  },
  {
    value: 24,
    label: '24h',
  },

  {
    value: 48,
    label: '48h',
  },
  {
    value: 72 ,
    label: '72h',
  }
];
const newlyCreatedBadPBMarks = [
  {
    value: 0,
    label: '',
  },
  {
    value: 5,
    label: 'Onboarding Completed',
  },
  {
    value: 17,
    label: 'More than 5 pg in an hour',
  },
  {
    value: 24,
    label: '24h',
  },
];
const oneMonthMarks = [
  {
    value: 0,
    label: '',
  },
  {
    value: 7,
    label: '1w',
  },
  {
    value: 14,
    label: '2w',
  },
  {
    value: 21 ,
    label: '3w',
  },
  {
    value: 30 ,
    label: '4w',
  },

];

function valuetext(value: number) {
  return `${value}`;
}

export default function PlayerHealth() {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ m: 3 }} />
       <Typography gutterBottom>Newly Created - GOOD!</Typography>
        <Slider
          aria-label="Custom marks"
          defaultValue={20}
          getAriaValueText={valuetext}
          step={1}
          valueLabelDisplay="auto"
          marks={newlyCreatedMarks}
        />
        <Box sx={{ m: 5 }} />
        <Typography gutterBottom>Newly Created - BAD!</Typography>
         <SliderBad
           aria-label="Custom marks"
           defaultValue={10}
           getAriaValueText={valuetext}
           step={1}
           valueLabelDisplay="auto"
           marks={newlyCreatedBadMarks}
         />
         <Box sx={{ m: 5 }} />
         <Typography gutterBottom>Newly Created - BAD!</Typography>
          <SliderBad
            aria-label="Custom marks"
            defaultValue={25}
            getAriaValueText={valuetext}
            step={1}
            valueLabelDisplay="auto"
            marks={newlyCreatedBadPBMarks}
          />
      <Box sx={{ m: 5 }} />
       <Typography gutterBottom>One Month Timeline</Typography>
        <AirbnbSlider
          aria-label="Custom marks"
          defaultValue={20}
          getAriaValueText={valuetext}
          step={7}
          valueLabelDisplay="auto"
          marks={oneMonthMarks}
        />
    </Box>
  );
}
const SliderBad = styled(Slider)(({ theme }) => ({
  color: 'red',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}
