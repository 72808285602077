import { getMemberRedemptionsStatusCall, syncMemberRedemptionsStatusCall } from '../../api/Health/MemberRedemptionsStatus'
import { processResponse, processHealthResponse, toCamelCase, processParamsToServer } from '../Utils'



export async function getMemberRedemptionsStatus({ status, player }) {
  const response = await getMemberRedemptionsStatusCall({ status, player });
  // console.log('RESOINSE ????', response, response.status)
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) =>  res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function syncMemberRedemptionsStatus() {
  const response = await syncMemberRedemptionsStatusCall();
  // console.log('RESOINSE ????', response, response.status)
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) =>  res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
