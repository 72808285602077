import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { PatternFormat } from 'react-number-format';


function DisableConfirmContent({ disabledReasons, reason, check, onDisableReason }) {
  const [ disabledReason, setDisabledReason ] = useState(reason)
  const [ internalNotes, setInternalNotes ] = useState('')

  const handleSelectCauseOfDisable = (event) => {
    const newValue = event.target.value
    setDisabledReason(newValue);
    onDisableReason({ disabledReason: newValue, internalNotes })
  }

  const handleAddingNotes = (event) => {
    const notes = event.target.value
    const timer = setTimeout(() => {
      setInternalNotes(notes)
      onDisableReason({ disabledReason, internalNotes: notes })
    }, 1000);
    return () => clearTimeout(timer);
  }

  return (
    <>
    {check
      ? <Stack spacing={4}>
      <Typography>You are about to disable this account, please provide additional information  </Typography>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="ReasonfordisablingLable">Reason for disabling</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="ReasonfordisablingSelectId"
          value={disabledReason}
          onChange={handleSelectCauseOfDisable}
          label="Reason for disabling"
        >{
          disabledReasons.map((reason) =>
            <MenuItem key={`${reason}Key`} value={reason}>{reason.replaceAll('_', ' ').toUpperCase()}</MenuItem>
          )}

        </Select>
        <TextField
           id="internalNotesId"
           label="Internal Notes"
           placeholder="Use this field to provide more details"
           multiline
           variant="standard"
           onChange={handleAddingNotes}
         />
      </FormControl>
    </Stack>
  : <Typography>You are about to enable this account, are you sure you want to continue?</Typography>}</>
  )
}



function OptOutDialogContent({ setTicketNumber, check, toggleConfirmEnable }) {
  const [ ticket, setTicket ] = useState('')

  const onTicketEnter = (event) => {
    const ticketNumber = event.floatValue;
    const timer = setTimeout(() => {
      setTicket(ticketNumber)
      setTicketNumber({ ticket: ticketNumber })
      toggleConfirmEnable(ticketNumber.toString().length === 6 || ticketNumber.toString().length === 7)
    }, 100);
    return () => clearTimeout(timer);

  }

  return (
  <Stack spacing={4}>
       <Typography>{`Turninig Opt Out Status Value ${check ? 'ON' : 'OFF'}`}</Typography>
       <Box
         component="form"
         noValidate
         autoComplete="off"
       >
         <PatternFormat
           label="Ticket Number"
           id="zendesk#id"
           customInput={TextField}
           value={ticket}
           allowNegative={false}
           onValueChange={onTicketEnter}
           format={'#######'}
         />

       </Box>
   </Stack>

  )
}


function DeleteDialogContent({ playerId, toggleConfirmEnable }) {

  const onPlayerIdEntered = (event) => {
    const id = event.target.value;
    const enable = id.toString() === playerId.toString()
    const timer = setTimeout(() => {
      toggleConfirmEnable(enable)
    }, 1000);
    return () => clearTimeout(timer);

  }

  return (
    <Stack spacing={4}>
       <Typography>Are you sure you want to delete this player and all the associated members? This action cannot be undone!</Typography>
       <Box
         component="form"
         noValidate
         autoComplete="off"
       >
         <TextField
           id="Player#ID"
           label="Confirm Player ID"
           placeholder="123456"
           type="number"
           onChange={onPlayerIdEntered}
         />
       </Box>
   </Stack>



  )
}

function NewNoteContent({ setAddedNewNote, setAddedTicket, addedTicket }) {

  const [ noteChar,setNoteChar ] = useState(0)
  const CHARACTER_LIMIT = 500

  const onAddedNote = (type) => (event) => {
    const value = type === 'note' ? event.target.value : event.floatValue

    const timer = setTimeout(() => {
      if (type === 'note' && value.length <= CHARACTER_LIMIT) {
        setNoteChar(value.length)
        setAddedNewNote(value)
      } else {
        setAddedTicket(value)
      }

    }, 100);
    return () => clearTimeout(timer);

  }

  return (
    <Stack>
      <TextField
         id="filled-multiline-static"
         label="Note"
         multiline
         rows={10}
         inputProps={{
            maxlength: CHARACTER_LIMIT
          }}
         sx={{width: '342px'}}
         variant="filled"
         helperText={`${noteChar} / ${CHARACTER_LIMIT}`}
         onChange={onAddedNote('note')}
         focused
       />
       <PatternFormat
         label="Ticket #"
         id="zendesk#id"
         customInput={TextField}
         value={addedTicket}
         allowNegative={false}
         onValueChange={onAddedNote('ticket')}
         sx={{width: '342px'}}
         variant="filled"
         format={'#######'}
       />

     </Stack>
  )
}


export default function ConfirmPlayerDialogsContents(props) {

  return (
    props.dialog === 'disable'
      ? <DisableConfirmContent {...props} />
      : props.dialog === 'optOut'
        ? <OptOutDialogContent {...props}/>
        : props.dialog === 'delete'
        ? <DeleteDialogContent {...props}/>
        : props.dialog === 'newNote'
        ? <NewNoteContent {...props}/>
        : <></>
  )
}
