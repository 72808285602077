export function toCamelCase(word) {
	return word.toLowerCase().replace(/(_\w)/g, m => m.toUpperCase().substr(1))
}

export function camelToUnderscore(key) {
	return key.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase())
}

export function processParamsToServer(params) {
	let paramsToServer = {}
	Object.keys(params).forEach((key) => {
		paramsToServer[camelToUnderscore(key)] = params[key]
	})
	return paramsToServer;
}
export function processHealthResponse(healthResponse, healths = {}) {

	try {
		if (!healthResponse || healthResponse == null ||
			typeof healthResponse === 'number' ||
			typeof healthResponse === 'string' ||
			typeof healthResponse === 'boolean') {
			return healthResponse
		}
		if (Array.isArray(healthResponse)) {
			healths = []
			healthResponse.forEach((item) => {
				if (typeof item === 'object') {
					healths.push(processResponse(item))
				} else {
					healths.push(item)
				}
			})
		} else {
			if (!Object.keys(healthResponse) || Object.keys(healthResponse).length === 0 ) {
				return healthResponse
			} else {
				Object.keys(healthResponse).forEach((key) => {

					if ( typeof healthResponse[key] === 'number' ||
						typeof healthResponse[key] === 'string' ||
						typeof healthResponse[key] === 'boolean') {
						if (typeof key !== 'number' && isNaN(key)) {
							healths[toCamelCase(key)] = healthResponse[key]
						}
					} else {
						if (key) {
							healths[key.includes('player') ? key : toCamelCase(key)] = processResponse(healthResponse[key])
						}
					}
				})
			}

		}
		return healths;
	} catch (err) {
		console.log('ERROR ???? ', err)
		return healths;
	}
}
export function processResponse(pgUserResponse) {

	let user = {}
	try {
		if (!pgUserResponse || pgUserResponse == null ||
			typeof pgUserResponse === 'number' ||
			typeof pgUserResponse === 'string' ||
			typeof pgUserResponse === 'boolean') {
			return pgUserResponse
		}
		if (Array.isArray(pgUserResponse)) {
			user = []
			pgUserResponse.forEach((item) => {
				if (typeof item === 'object') {
					user.push(processResponse(item))
				} else {
					user.push(item)
				}
			})
		} else {
			if (!pgUserResponse || !Object.keys(pgUserResponse) || Object.keys(pgUserResponse).length === 0 ) {
				return pgUserResponse
			} else {

				Object.keys(pgUserResponse).forEach((key) => {

					if ( typeof pgUserResponse[key] === 'number' ||
						typeof pgUserResponse[key] === 'string' ||
						typeof pgUserResponse[key] === 'boolean') {
						if (typeof key !== 'number' && isNaN(key)) {
							user[toCamelCase(key)] = pgUserResponse[key]
						}
					} else {
						if (key) {
							user[toCamelCase(key)] = processResponse(pgUserResponse[key])

						}
					}
				})
			}

		}
		return user;
	} catch (err) {
		console.log('ERROR ???? ', err)
		return user;
	}
}

export function processSpotlightResponse(data){
	try{
		let res = []
		data.forEach((spotlights)=>{
			const tempSpotlight = {}

			for (const [key, value] of Object.entries(spotlights)) {
				let newKey = toCamelCase(key)
				tempSpotlight[newKey] = value
			}

			res.push(tempSpotlight)
		})

		console.log(res)
		return res
	} catch(err) {
		console.error(err)
		return []
	}
}

export function deepCamelCaseKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(deepCamelCaseKeys);
  }
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        [toCamelCase(key)]: deepCamelCaseKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
}

export function processActivityGroups(data){
	try{
		return deepCamelCaseKeys(data).activityGroups
	} catch(err){
		console.log(err)
		return []
	}
}

export function getDateTime(date, time) {
	const hours = new Date(time).getHours();
	const minutes = new Date(time).getMinutes();
	const dateDay = new Date(date).getDate();
	const monthIndex = new Date(date).getMonth()
	const year = new Date(date).getFullYear();
	return new Date(year, monthIndex, dateDay, hours, minutes, 0);
}
