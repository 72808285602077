import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { grey, amber, deepOrange, blue } from '@mui/material/colors';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Tooltip from '@mui/material/Tooltip';


const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function Notes(props) {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <Box
      sx={{
        height: '50px',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        color: 'text.primary',
        borderRadius: 1,
        p: 1,
        justifyContent: 'space-between',
      }}
    >
    <Tooltip title="Add A New NOTE">
      <IconButton sx={{ ml: 1 }} onClick={props.onAddNote} color="inherit">
        <NoteAddIcon />
      </IconButton>
    </Tooltip>
      {props.title}
      <IconButton sx={{ ml: 1 }} onClick={props.onMinize} color="inherit">
        <MinimizeIcon sx={{ ml: 1, marginBottom: '50%' }}/>
      </IconButton>
    </Box>
  );
}

export default function NotesHeader(props) {
  const [ mode, setMode ] = React.useState('dark');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary: amber,
            divider: amber[200],
            text: {
              primary: grey[900],
              secondary: grey[800],
            },
          }
        : {
            // palette values for dark mode
            primary: blue,
            divider: blue[700],
            background: {
              default: blue[700],
              paper: blue[700],
            },
            text: {
              primary: '#fff',
              secondary: grey[500],
            },
          }),
    },
  });
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Notes {...props}/>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
