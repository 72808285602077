import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import ReactiveButton from 'reactive-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faThumbsUp, faBomb } from '@fortawesome/free-solid-svg-icons'

const BtnContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
}))
export default function GroupedPlayersContainer(props) {
  const [ btnState, setBtnState ] = useState('idle')

  const onClickHandler = async () => {
    setBtnState('loading')
    const success  = await props.getPlayers({ filter: props.filter })
    const nextState = success ? 'success' : 'error'

    setBtnState(nextState);
     setTimeout(() => {
       setBtnState('idle');
     }, 2000);
  }
  return (
    <Box >

      <ReactiveButton
         key={`btnAwesomeReactBtn${props.title}`}
         buttonState={btnState}
         onClick={onClickHandler}
         color={props.colorBtnType}
         idleText={
            <BtnContainer>
              <Box sx={{marginRight: '4px', display: 'flex'}}>{props.icon}</Box><span> {props.title}</span>
            </BtnContainer>
          }
         loadingText={
           <>
             <FontAwesomeIcon icon={faCircleNotch} spin /> Loading
           </>
         }
         successText={
           <>
             <FontAwesomeIcon icon={faThumbsUp} /> Success
           </>
         }
         errorText={
           <>
             <FontAwesomeIcon icon={faBomb} /> Error
           </>
         }
         type={'button'}
         style={{
           borderRadius: '5px',
           height: 44

         }}
         outline={false}
         shadow={true}
         rounded={false}
         size={'normal'}
         block={false}
         messageDuration={2000}
         disabled={false}
         buttonRef={null}
         width={null}
         height={null}
         animation={true}
       />

    </Box>
  );
}
