import React, { useState } from 'react';
import PropTypes from "prop-types";
import CssBaseline from '@mui/material/CssBaseline';
import logo from '../../assets/JarisLogo.png';
import Box from '@mui/material/Box';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
import MuiAppBar from '@mui/material/AppBar';

import { signIn } from '../../models/Auth'
import { useNavigate } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
        padding: '55px'
    },
  },
  login: {
    justifyContent: 'center',
    minHeight: '90vh',
    padding: '55px'
  },
  btnBlock: {
    width: '100%'
  },
  loginBackground: {

    justifyContent: 'center',
    // minHeight: '30vh',
    // padding: '50px'
  }


}));

function SignIn({setToken}) {
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const navigate = useNavigate();

  const classes = useStyles();


  const handleSubmit = async (event) => {
    // clearToken()
    event.preventDefault();
    const response = await signIn({ email, password })
    // console.log('SIGN IN ', response)

    if (response.ok  ) {
      // if (response.status === 200) {
        const newToken = response.headers.get("Authorization");
        // localStorage.setItem("token", response.headers.get("Authorization"));
        setToken(newToken);
        navigate('/');
      // }
    }
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <MuiAppBar position="static" alignitems="center" sx={{ backgroundColor: '#1976d2' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
              <img src={logo} alt="Logo" />;
            </Box>
          </Typography>
          <Grid container justifyContent="center" wrap="wrap">
            <Grid item>
              <Typography variant="h6">{''}</Typography>
            </Grid>
          </Grid>
       </Toolbar>
      </MuiAppBar>
      <Grid
        container spacing={0}
        justifyContent="center"
        direction="row">
        <Grid item>
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={2}
        style={{margin: '35px'}}

        >
      <Paper
        variant="elevation"
        elevation={2}
      >
        <Grid item>
          <Typography component="h1" variant="h5">
          Sign in
          </Typography>
        </Grid>
        <Grid item style={{

          padding: '50px'}}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  type="email"
                  placeholder="Email"
                  fullWidth
                  name="user[email]"
                  variant="outlined"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)
                  }
                  required
                  autoFocus
                  />
              </Grid>
              <Grid item>
                <TextField
                  type="password"
                  placeholder="Password"
                  fullWidth
                  name="user[password]"
                  variant="outlined"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  required
                />
              </Grid>
              <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{width: '100%'}}
                    type="submit"
                    onClick={handleSubmit}
                    >
                    Submit
                  </Button>
              </Grid>
            </Grid>
          </Grid>

        { false && <Grid item>
            <Link href="https://influencemobile.awsapps.com/start/" variant="body2">
            SSO Log In
            </Link>
          </Grid>}
      </Paper>
    </Grid>
  </Grid>
</Grid>
</div>
  );
}

SignIn.propTypes = {
  // token: PropTypes.string,
  setToken: PropTypes.func.isRequired,
  // clearToken: PropTypes.func.isRequired
};

export default SignIn;
