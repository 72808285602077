import React, { useContext, useState, useEffect } from "react";

import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, {tableRowClasses} from '@mui/material/TableRow';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import AdUnitsIcon from '@mui/icons-material/AdUnits';

import { SpotlightContext } from "./SpotlightContext";

import SpotlightOfferCell from "./SpotlightOfferCell";
import SpotlightOfferStatusCell from "./SpotlightOfferStatusCell";
import SpotlightCompletedCell from "./SpotlightCompletedCell";
import SpotlightDeviceIcon from "./SpotlightDeviceIcon";


const theme = createTheme({
  palette: {
    pending: {
      main: grey[500],
      light: grey[300],
      dark: grey[700],
      contrastText: "#fff",
    },
  },
});

const SpotlightTableCell = styled(TableCell)(({theme})=>({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const SpotlightHeaderTableCell = styled(TableCell)(({theme})=>({
  [`&.${tableCellClasses.root}`]: {
    fontWeight: 'bold',
    border: 'none'
  },
}))

const bonusPointsStyle = {
  borderRadius: 15,
  margin: '0px 0px',
  padding: '3px 0px',
  backgroundColor: 'rgb(214 214 214)',
}

function formatDate(epoch){
  if(isNaN(epoch)){
    return "-"
  }
  try {
    let date = new Date(new Date(epoch*1000).toGMTString())
    return date.toLocaleString("en-US", { hour12: true })
  } catch(err) {
    console.error(err)
    return new Date(epoch*1000).toGMTString()
  }

}

const SpotlightTable = () => {
  const { shownSpotlights, originSpotlights } = useContext(SpotlightContext);

  return(
    <ThemeProvider theme={theme}>
      <Stack>
        <TableContainer>
          <TableHead>
            <TableRow>
              <SpotlightHeaderTableCell align="left">Offer</SpotlightHeaderTableCell>
              <SpotlightHeaderTableCell align="left">Device</SpotlightHeaderTableCell>
              <SpotlightHeaderTableCell align="center">Bonus Points</SpotlightHeaderTableCell>
              <SpotlightHeaderTableCell align="center">Shown to player on</SpotlightHeaderTableCell>
              <SpotlightHeaderTableCell align="center">Installed On</SpotlightHeaderTableCell>
              <SpotlightHeaderTableCell align="center">Completed On</SpotlightHeaderTableCell>
              <SpotlightHeaderTableCell align="center">Offer Status</SpotlightHeaderTableCell>
              <SpotlightHeaderTableCell align="center">Completed By</SpotlightHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shownSpotlights.map((entry) => (
              <TableRow key={entry.offerId}>
                <SpotlightOfferCell component="th" scope="row" offer={entry.offer} spotlight={entry}></SpotlightOfferCell>
                <SpotlightTableCell align="center">
                  <SpotlightDeviceIcon spotlight={entry}></SpotlightDeviceIcon>
                </SpotlightTableCell>
                <SpotlightTableCell align="center">
                  <p style={bonusPointsStyle}>{entry.bonusPoints.toLocaleString()}</p>
                </SpotlightTableCell>
                <SpotlightTableCell align="left">{formatDate(entry.presentedAt)}</SpotlightTableCell>
                <SpotlightTableCell align="left">{formatDate(entry.installedAt)}</SpotlightTableCell>
                <SpotlightTableCell align="left">{formatDate(entry.convertedAt)}</SpotlightTableCell>
                <SpotlightOfferStatusCell align="left" spotlight={entry}/>
                <SpotlightCompletedCell spotlight={entry}></SpotlightCompletedCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Stack>
    </ThemeProvider>
  )
}

export default SpotlightTable;