import React, { useContext, useState, useEffect } from "react";
import ChallengeStatusFilter from "./Filters/ChallengeStatusFilter";
import { ChallengesContext } from "./ChallengesContext";

const ChallengeFilters = () => {
  const {shownChallengeGroups, challengeGroups, setShownChallengeGroups } = useContext(ChallengesContext);
  const [selectedStatus, setSelectedStatus] = useState('all')
  const [filteredChallengeGroups, setFilteredChallengeGroups] = useState([])

  // console.log("initial filtered groups", filteredChallengeGroups)

  const filterStyles = {
    display: 'flex'
  }

  useEffect(()=>{
    let groups = filterChallengeGroups()
    // console.log("filtered groups", groups)
    setShownChallengeGroups(groups)
  },[selectedStatus, filteredChallengeGroups])

  useEffect(()=>{
    // console.log("shownChallengeGroups", shownChallengeGroups)
    setFilteredChallengeGroups(shownChallengeGroups)
  },[])

  function filterChallengeGroups(){
    const stateFiltered = []

    for (let i = 0; i < filteredChallengeGroups.length; i++) {
      let group = filteredChallengeGroups[i]
      console.log("group.status === selectedStatus", group.status === selectedStatus)
      if( group.status === selectedStatus || selectedStatus === 'all'){
        console.log("group in loop", group)
        stateFiltered.push(group)
      }
    }
    return stateFiltered
  }

  return(
    <div style={filterStyles}>
      <ChallengeStatusFilter
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
    </div>
  )
}

export default ChallengeFilters