import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import AnnouncementsCalendar from './AnnouncementsCalendar'
import { formatDate, formatTime, daysDiff } from '../common/Utils'
import LimitedPermissionPage from '../common/LimitedPermissionPage'

import { getAnnouncement } from '../../models/Announcement'

import IMLoader from '../common/IMLoader'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function Announcements(props) {

  const [ loading, setLoading ] = useState(true)
  const [ announcements, setAnnouncements ] = useState(null)
  const [ unauth, setUnauth]  = useState(null)
  const [ user, setUser ] = useState(null)
  const [ edit, setEdit ] = useState(null)

  const getWhen = (startAt, endDate) => {
    const start = new Date(Date.parse(startAt))
    const end = new Date(Date.parse(endDate))

    return daysDiff(start, end)
      ? `${formatDate(start)} - ${formatTime(start)} to ${formatDate(end)} - ${formatTime(end)}`
      : `${formatDate(start)} - ${formatTime(start)} to ${formatTime(end)}`

  }

  const setupAnnouncements = async () => {
    const response = await getAnnouncement({ announcementType: 'all' });
    if (response && !response.error && response.announcements) {
       setEdit(response.edit)
       const announcementsToTable = response.announcements.map((announce) => (
        { id: announce.id,
          color: announce.color,
          title: announce.title,
          when: getWhen(announce.startAt, announce.endAt),
          type: announce.announcementType,
          fromDate: dayjs(new Date(announce.startAt)),
          toDate: dayjs(new Date(announce.endAt)),
          fromTime: new Date(announce.startAt),
          toTime: new Date(announce.endAt),
          message: announce.body,
          displaySchedule: announce.displaySchedule,
          illustration: announce.image,
          actions: announce.actions,
          country: announce.country,
          button: announce.cta,
          dynamoid: announce.dynamoid
          }
        )
      )

      setAnnouncements(announcementsToTable)
    } else {
      if (response.status === 401) {
        setUnauth(true)
        setUser(response.user)
      }
      setAnnouncements([])
    }
    setLoading(false)
  }

  useEffect(() => {
    if (announcements === null) {
      setupAnnouncements()
    }
  });


  const createNew = () => {
    props.onNewAnnouncementCall()
  }


  const setCalRows = () => {
    let newRows = []
    announcements.forEach((row) => {
      let newRow = {};

      Object.keys(row).forEach((rowKey) => {
        newRow[rowKey] = row[rowKey]
      })
      newRow['id'] = `${row['id']}-active`
      newRow['onAnnouncementActionCall'] = props.onAnnouncementActionCall
      newRows.push(newRow)

      }
    )
    return newRows;
  }

  return (
    <Box>
    { loading
      ? <IMLoader message={'Loading Announcements...'} mini/>
      : unauth
        ? <LimitedPermissionPage />
        : <Box sx={{width: '90%', padding: 5}}>

             <Button variant="text" startIcon={<CalendarMonthIcon />}
             sx={{position: 'absolute', top: '120px',
                   left: '30px', paddingBottom: 2
                   }}>
               Announcements Calendar
             </Button>
            {edit  && <Button
              onClick={createNew}
              sx={{position: 'absolute', top: '120px',
                    right: '70px', width: '98px', height: '50px',
                    borderRadius: '64px'}}
                   variant="contained" endIcon={<AddIcon />}
            >New</Button>}
            <Box sx={{paddingTop: 5}}>
              <AnnouncementsCalendar events={setCalRows()}/>
            </Box>
          </Box>

  }


    </Box>
  )
}
