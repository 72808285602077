import React, {useContext} from "react";

import TextField, {textFieldClasses} from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { SpotlightContext } from "./SpotlightContext";

const SpotlightSearchField = styled(TextField)(({})=>({
  [`&.${textFieldClasses.root}`]: {
    width: '20%',
    marginRight: 30
  },
}))

const SearchField = ({filterFunction}) => {
  return (
    <SpotlightSearchField
      variant="filled"
      label="Filter By"
      placeholder="Keywords"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        filterFunction(e)
      }}
    >
    </SpotlightSearchField>
  )

}

const SpotlightTextFilter = ({setTextFilteredSpotlights}) => {
  const {originSpotlights} = useContext(SpotlightContext);

  function filterByKeyword(keyword){
    let results = []
    const matcher = keyword.toLowerCase()

    if(matcher === '' || matcher === null){
      return originSpotlights
    }

    for (let i = 0; i < originSpotlights.length; i++) {
      const spotlight = originSpotlights[i]
      const tokens = [
        spotlight.installedAppName.toLowerCase().split(' '),
        spotlight.splDisplayText.toLowerCase().split(' ')
      ].flat(Infinity)

      for (let j = 0; j < tokens.length; j++) {
        const token = tokens[j]
        if((matcher === token) && matcher.length > 2){
          results.push(originSpotlights[i])
        }
      }
    }

    results = results.filter((item, index) => results.indexOf(item) === index)
    return results
  }

  function filterSpotlights(e){
    const keyword = e.target.value
    const results = filterByKeyword(keyword)
    setTextFilteredSpotlights(results)
  }

  return(
    <>
      <SearchField filterFunction={filterSpotlights}/>
    </>
  )
}

export default SpotlightTextFilter