import React, { useEffect, useState } from 'react';
// import { getPlayers } from '../models/Players'
import { getUser } from '../models/Users';
import { signIn } from '../models/Auth'
import { getAnnouncement } from '../models/Announcement'
// import { getPrizes } from '../models/Prize'

import SSOUnauth from './Auth/SSOUnauth'
import Header from './common/Header'
import IMAlerts from './common/IMAlerts';

import PlayerContainer from './PlayerContainer/PlayerContainer'
import AnnouncementsContainer from './Announcements/AnnouncementsContainer'
import HealthContainer from './Health/HealthContainer'
import RewardMaxContainer from './RewardMax/RewardMaxContainer'
import { useNavigate } from "react-router-dom";
import PlayersTable from './PlayersContainer/PlayersTable'
import LimitedPermissionPage from './common/LimitedPermissionPage'
import ConfirmDialog from './common/ConfirmDialog'
import IMLoader from './common/IMLoader'
import { playersSearch } from '../models/Players'

import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';

import Container from '@mui/material/Container';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Jaris = (props) => {

  const [ searchedPlayers, setSearchedPlayers ] = useState([])
  const [ searchedPlayersType, setSearchedPlayersType ] = useState(null)
  const [ currentUser, setCurrentUser ] = useState(null)
  const [ mount, setMount ] = useState(false)
  const [ alertMessage, setAlertMessage ]  = useState('');
  const [ disabled, setDisabled ] = useState(props.disabled)
  const [ loading, setLoading ] = useState(false)
  const [ loadingMessage, setLoadingMessage ] = useState('')
  const [ unAuth, setUnAuth ] = useState('')
  const [ testSaml, setTestSaml ] = useState(null)
  const [ displayAlert, setDisplayAlert ] = useState(true);
  const [ selectedPlayer, setSelectedPlayer ] = useState(null);
  const [ playerSearchEnable, setPlayerSearchEnable ] = useState(true)
  // const [ dialogContent, setDialogContent ] = useState('')
  // const [ dialogTitle, setDialogTitle ] = useState('')
  // const [ dialogAction, setDialogAction ] = useState('')
  const [ currentView, setCurrentView ] = useState('PlayersContainer');
  const [ toggleHeaderSearchedPlayers, setToggleHeaderSearchedPlayers ] = useState(false)
  const [ forceLoad, setForceLoad ] = useState(false)
  const [ forceLoadMessage, setForceLoadMessage ] = useState('Loading')
  const [ env, setEnv ] = useState(null)
  const [ avatar, setAvatar ] = useState(null)
  const [ dialogProps, setDialogProps ] = useState(null)
  const [ notifications, setNotifications ] = useState({ activeAnnouncements: -1 })
  const [ unauthTabs, setUnauthTabs ] = useState({ activeAnnouncements: false })
  const navigate = useNavigate();


  const setupUser = async () => {
     setLoadingMessage('Loading User...');
     setLoading(true);

     const response = await getUser({ unAuthCallback: unAuthResponse })
     if (!response) {
       return
     }
     console.log('RESPONSE ', response)

     if (response.error || response.status === 500) {
        if (response.status === 500) {
          setTestSaml(response.development_test_saml)

          setUnAuth(500);
          setLoading(false);
          return;
        }

        const message = response.error.message
        const disabled = response.error.disabled
        const user = response.error.user
        const env = response.env

        let alertMessageUpdate = "User logged in is not authorized to view this page."
        if (disabled) {
          alertMessageUpdate += "Needs to be enabled!"
          setDisabled(true)
        }
        if (user) {
          setCurrentUser(user)
          setEnv(env)
        }

        setUnAuth(401)
        setAlertMessage(alertMessageUpdate)
    //
      } else {
        setCurrentUser(response.user)
        setAvatar(response.avatar)

        setEnv(response.env)
        setUnauthTabs(response.unauthTabs)
        props.clearToken();

      }
      setLoadingMessage('');
    //
      setLoading(false)
    //
  }


  const onSearch = (response) => {
    setDisabled(response.disabled)
  }


  const unAuthResponse = async (response = null) => {
    setLoading(true)
    if ( currentUser && currentUser.jti) {
      const reAuthUser = await signIn({ jti: currentUser.jti });
      navigate('/')
    } else {
      if (response && response.development_test_saml && response.env === 'development') {
          setTestSaml(response.development_test_saml)
      }
      setUnAuth(500)
    }
    //
    setLoading(false)

  }
  useEffect(() => {
    let view = ''
    const pathname = window.location.pathname.split('/')
    const playersView = view === 'players' || view === ''

    if (!mount && !currentUser && !loading) {
      setMount(true);
      setupUser();
      // setupTangoCards()
    }
    if (props.disabled && !disabled) {
      setDisabled(true)
    }
    checkUrl()

    if ( notifications ['activeAnnouncements']=== -1 ) {
      onAnnouncementChange()
    }
  })

  const checkUrl = () => {

    let view = ''
    const pathname = window.location.pathname.split('/')
    switch (pathname[1]) {
      case '':
        view = 'PlayersContainer'
        break;
      case 'players':
        view = 'PlayersContainer'
        break;
      case 'player':
        view = 'PlayerContainer'
        break;
      case 'announcements':
        view = 'Announcements'
        break;
      case 'health':
        view = 'Health'
        break;
      case 'rewardMax':
        view = 'RewardMax'
        break;

    }

    if (view !== currentView && view !== '') {
      setCurrentView(view)
    }
  }


  // const setupTangoCards = async () => {
  //   const response = await getPrizes({ type: 'tango' });
  //   console.log('CARDS????? ', response)
  //   if (response && !response.error && response.cards) {
  //
  //   } else {
  //
  //   }
  //
  // }

  const onAnnouncementChange = async () => {
    const response = await getAnnouncement({ announcementType: 'active' });
    if (response && !response.error && response.announcements) {
      // setActiveAnnouncements(response.announcements.length)
      let newNotifications = {}
      Object.keys(notifications).forEach((not) =>
        newNotifications[not] = not === 'activeAnnouncements' ? response.announcements.length : notifications[not]
      )
      setNotifications(newNotifications)
    } else {
      if (response && response.status === 401){
        let updatedUnath = unauthTabs
        updatedUnath['activeAnnouncements'] = true
        setUnauthTabs(updatedUnath)
      }
    }

  }

  const getCurrentViewContainer = () => {

    let container = currentUser
    && <Container sx={{ height: 630.5, textAlign: 'center' }}>
        <LimitedPermissionPage
          user={currentUser.firstName}
        />
      </Container>

    switch (currentView) {
      case '':
      case 'PlayersContainer':

        if (!playerSearchEnable) {
          setPlayerSearchEnable(true)
        }

        container = <PlayersTable
            user={currentUser}
            unAuthCallback={unAuthResponse}
            onChoosePlayer={onChoosePlayer}
            forceLoad={forceLoad}
            forceLoadMessage={forceLoadMessage}
            searchHeaderFilterButtonClicked={searchHeaderFilterButtonClicked}
            toggleHeaderSearchedPlayers={toggleHeaderSearchedPlayers}
            searchedPlayersType={searchedPlayersType}
            searchedPlayers={searchedPlayers}/>
          break;
      case 'PlayerContainer':
        if (!playerSearchEnable) {
          setPlayerSearchEnable(true)
        }
        container = <PlayerContainer
            env={env}
            currentUser={currentUser}
            forceLoad={forceLoad}
            forceLoadMessage={forceLoadMessage}
            searchHeaderFilterButtonClicked={searchHeaderFilterButtonClicked}
            searchedPlayersType={searchedPlayersType}
            selectedPlayer={selectedPlayer}
            getPlayer={getPlayer}/>
        break;
      case 'Announcements':
          if (playerSearchEnable) {
            setPlayerSearchEnable(false)
          }
          container = <AnnouncementsContainer
                env={env}
                user={currentUser}
                onAnnouncementChange={onAnnouncementChange}
          />;
          break;
       case 'Health':
         if (playerSearchEnable) {
           setPlayerSearchEnable(false)
         }
          container = <HealthContainer
                env={env}
                currentUser={currentUser}
          />;
          break;
        case 'RewardMax':
          if (playerSearchEnable) {
            setPlayerSearchEnable(false)
          }
           container = <RewardMaxContainer
                 env={env}
                 currentUser={currentUser}
                 unAuthCallback={unAuthResponse}
           />;
           break;
    }
    return container;

  }

  const headerSearchFields = [
      {label: 'Player ID', name: 'ID', icon: <FingerprintIcon />},
      {label: 'Phone Number', name: 'Phone', icon: <PhoneIcon />},
      {label: 'IP Address', name: 'IP', icon: <NetworkCheckIcon />},
      {label: 'Name', name: 'Name', icon: <PersonIcon />}
      // {label: 'Search', name: 'search'}

    ]


  const searchHeaderFilterButtonClicked = async (headerSearched) => {

    setForceLoad(true)
    setForceLoadMessage('Searching Players...')
    const response2 = await playersSearch({ unAuthCallback: unAuthResponse, params: { 'search': headerSearched } })
    // console.log('RESPONSE FROM HEADER ', response2)
    if (response2.error) {
      const message = response2.error.message
      const disabled = response2.error.disabled
      const user = response2.error.user
      if (props.onSearch) {
        props.onSearch(response2.error)
      }
      let alertMessageUpdate = "User logged in is not authorized to view this page."
      if (disabled) {
        setDisabled(true)
        alertMessageUpdate += "Needs to be enabled!"
        // setDisabled(true)
        // setUser(user)

      }

      setAlertMessage(alertMessageUpdate)
      setSearchedPlayers([])
      setSearchedPlayersType(null)
      setToggleHeaderSearchedPlayers(!toggleHeaderSearchedPlayers)
    } else {

      // const iPermResponse = response2.response_i_perm.data.im_app_users[0]
      // const userRole = iPermResponse.im_app_role.role
      // const disabled = iPermResponse.disabled

      if (props.onSearch) {
        props.onSearch(response2)
      }
      const pathname = window.location.pathname.split('/')

      if (pathname[1] === 'player') {

       if(response2.players.length === 1) {
          const playerId = response2.playerType === 'PlayerGraveyards'
            ? response2.players[0].playerId
            : response2.players[0].id

          navigate(`/player/${playerId}`)
       } else {
         const searchPlayers = () => {
           if (response2.players.length === 0) {
             resetDialog()
           } else {
             navigate(`/players/?${headerSearched.filter}=${headerSearched.value}`)
           }
         }
        const content = response2.players.length === 0
            ? "Can't find any player matching the entered filter"
            : 'More than one player was found. Would you like to be redirect to the players view with the results ?'
        const confirmBtnText = response2.players.length === 0
            ? ''
            : 'View Players'
        const dialog = {
           content,
           title: `Search Player`,
           onCancel: resetDialog,
           onConfirm: searchPlayers,
           enableConfirm: true,
           confirmBtnText
         }
         setDialogProps(dialog)

       }

      } else {
        setSearchedPlayers(response2.players)
        setSearchedPlayersType(response2.playerType)
        setToggleHeaderSearchedPlayers(!toggleHeaderSearchedPlayers)

      }

      // setAlertMessage(`User logged as ROLEL: ${userRole} and status DISABLED: ${disabled}`)
      // setDisplayAlert(true)
    }
    setForceLoadMessage('Loading')

    setForceLoad(false)
  }

  const onCloseAlert = () => {
    setAlertMessage("")
    setDisplayAlert(false)
  }


  const onChoosePlayer = (playerId) => {
    navigate(`/player/${playerId}`)
  }

  const resetDialog = () => {
    setDialogProps(null)
  }


  const getPlayer = async () => {
    const pathname = window.location.pathname.split('/')

    let player = selectedPlayer
    let playerType = searchedPlayersType
    if (player && pathname[pathname.length - 1] === player.id.toString()) {
      // console.log('LREADY SELECTED ', player)

      return ;
    }


    if (pathname[2]) {
      setLoading(true)
      let playerType = 'notFound'
      const response = await playersSearch({ unAuthCallback: unAuthResponse, params: { search: { filter: 'id', value: pathname[pathname.length - 1]}}})
      // console.log('GET PLAYER ???? ', response)
      if (response.status === 200 && response.players.length > 0) {

        const playerBack = response.players && response.players.length === 1 ? response.players[0] : null
        if (playerBack && (!selectedPlayer || playerBack.id !== selectedPlayer.id)) {
          setLoadingMessage('Loading Player')
          playerType = response.playerType
          setSearchedPlayersType(playerType)
          setSelectedPlayer(playerBack)
        }
        playerType = response.playerType
      } else {
        navigate('/players')
      }

      setLoading(false)
      return playerType
     }
    }



  return (
    <div>
    { dialogProps && <ConfirmDialog
        {...dialogProps}
      />}
    {alertMessage && displayAlert && <IMAlerts  onClose={onCloseAlert} severity="info" message={alertMessage}/>}
    {currentUser && <Header
        refreshUser={setupUser}
        env={env}
        searchHeaderFilterButtonClicked={searchHeaderFilterButtonClicked}
        playerSearchEnable={playerSearchEnable}
        user={currentUser}
        unAuthResponseCallback={unAuthResponse}
        alertMessage={alertMessage}
        onSearch={onSearch}
        player={selectedPlayer}
        currentView={currentView}
        avatar={avatar}
        setAvatar={setAvatar}
        notifications={notifications}
        unauthTabs={unauthTabs}
        headerSearchFields={headerSearchFields}/> }
     { loading
      ? <IMLoader message={loadingMessage || 'Loading...'}/>

      : unAuth === 500
        ? <SSOUnauth testSaml={testSaml}/>
        : unAuth === 401
          ? <Container sx={{ height: 630.5, textAlign: 'center' }}>
              <LimitedPermissionPage
                user={currentUser.firstName}
              />
            </Container>
          : getCurrentViewContainer()

      }
    </div>
  )
}

export default Jaris;
