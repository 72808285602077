import AdUnitsIcon from '@mui/icons-material/AdUnits';
import Tooltip from "@mui/material/Tooltip";

const SpotlightDeviceIcon = (props) => {
    const spotlight = props.spotlight

    return(
     <div>
        <Tooltip
          onClick={() => {navigator.clipboard.writeText(spotlight.externalDeviceId)}}
          title={
            <>
              <span><u>Name:</u> {spotlight.deviceName}</span>
              <br/>
              <span><u>Model:</u> {spotlight.deviceModel}</span>
              <br/>
              <span><u>External Device ID:</u> {spotlight.externalDeviceId}</span>
              <br/>
              <small style={{color: "yellow"}}>Click to Copy the External Device Id</small>
            </>
          }
        >
          <AdUnitsIcon
            onClick={() => {navigator.clipboard.writeText(spotlight.externalDeviceId)}}
          ></AdUnitsIcon>
        </Tooltip>
      </div>
    )
}

export default SpotlightDeviceIcon;