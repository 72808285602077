import React, {useContext, useState} from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableHead from "@mui/material/TableHead";
import ChallengeActivityRow from "./ChallengeActivityRow"
import BaseSupportModal from "./SupportModal/BaseSupportModal";
import {SupportModalContext} from "./SupportModal/SupportModalContext";


const ChallengeActivityTable = (props) => {
  const detailsOpen = props.detailsOpen
  const activities = props.activities
  const group = props.group
  const [modalOpen, setModalOpen] = useState(false)

  const headerYellow = '#FDD835'
  const bgYellow = '#f6f1bd'

  const tableCellStyles = {
    minWidth: "150px",
    maxWidth: "200px"
  }

  const HeaderTableCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.root}`]: {
      fontWeight: 'bold',
      // border: 'none',
      textAlign: "center",
      backgroundColor: headerYellow,
      padding: "7px 10px"
    },
  }))

  return(
    <SupportModalContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        group
      }}
    >
      <BaseSupportModal/>
      <TableRow>
        <TableCell style={{ backgroundColor: bgYellow, padding: 0 }} colSpan={8}>
          <Collapse in={detailsOpen} timeout='auto' unmountOnExit>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderTableCell align="center">Activity</HeaderTableCell>
                    <HeaderTableCell align="center">Type</HeaderTableCell>
                    <HeaderTableCell align="center">Prize</HeaderTableCell>
                    <HeaderTableCell align="center">Status</HeaderTableCell>
                    <HeaderTableCell align="center">Completed On</HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activities.map((activity) => (
                    <ChallengeActivityRow
                      activity={activity}
                      key={activity.goalId}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </SupportModalContext.Provider>

  )
}

export default ChallengeActivityTable