import React, {useContext} from "react";

import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import OnboardingStatusChip from "./OnboardingStatusChip";

import {OnboardingContext} from "./OnboardingContext";

const StepListStyles = styled(Stack)`
  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    content: " ";
    background: #ccc;
    height: 1.5px;
    width: 100%;
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
    background: #fff;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const StepList = ({...attrs}) => (
  <StepListStyles direction="row" spacing={6} {...attrs} />
);

const Step = ({type, title, ...attrs}) => {
  let decoration;
  if (type === "icon") {
    const Icon = attrs.icon;
    decoration = <Icon sx={{fontSize: 28}} {...attrs} />;
  } else if (type === "chip") {
    decoration = <Chip size="small" {...attrs} />;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {decoration}
      <Typography>{title}</Typography>
    </Stack>
  );
};

const STEP_STATUS_COLORS = {
  pending: "pending",
  in_progress: "success",
  completed: "primary",
};

const OnboardingStepper = () => {
  const onboarding = useContext(OnboardingContext);

  return (
    <Card sx={{p: 2}}>
      <Stack direction="row" spacing={2}>
        <Stack direction="row" spacing={1}>
          <Typography variant="h5">Onboarding</Typography>
          <OnboardingStatusChip/>
        </Stack>

        <StepList>
          {onboarding.steps.map((step, index) => {
            if (step.status === "completed") {
              return (
                <Step
                  type="icon"
                  title={step.title}
                  icon={CheckCircleIcon}
                  color={STEP_STATUS_COLORS.completed}
                />
              );
            }
            return (
              <Step
                type="chip"
                title={step.title}
                label={(index + 1).toString()}
                color={STEP_STATUS_COLORS[step.status] || "pending"}
              />
            );
          })}
        </StepList>
      </Stack>
    </Card>
  );
};

export default OnboardingStepper;
