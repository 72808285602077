import IMHelpText from "../../../common/IMHelpText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import {useState} from "react";
import SaveDevSetting from "./SaveDevSetting";
import {toCamelCase} from "../../../../models/Utils";

export default function RedemptionSettings({settings, player, edit}) {
  const [status, setStatus] = useState(settings[toCamelCase("redemption_status")]?.["typedValue"] ?? null)
  const config = { "redemption_status": {type: "String", value: status} };

  const redemptionStates = {
    "gc_granted": "Prize - Success - GC Granted",
    "processing": "Prize - Processing",
    "in_review": "Prize - In Review",
    "points_granted": "Prize - Success - Points Granted",
    "other": "Prize -Other",
  };

  return (
    <div>
      <IMHelpText>
        <p>
          Use these settings to define the redemption state to be tested.
        </p>
      </IMHelpText>

      <Stack spacing={2} sx={{mt: 2}}>
        <FormControl fullWidth>
          <InputLabel id="redemption-states-dropdown-label">Boost Redemption State</InputLabel>
          <Select
            disabled={!edit}
            labelId="redemption-states-dropdown-label"
            label="Boost Redemption State"
            sx={{width: 300}}
            size="small"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            { Object.entries(redemptionStates).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          <SaveDevSetting config={config} playerId={player.id} edit={edit} />
        </div>
      </Stack>
    </div>
  );
}
