import axios from 'axios';
import { getHeaders,  baseUrl } from './utils';

export async function getUserLogsCall({ logsType }) {

  try {
    // const params = updateParams
    const response  = await fetch(`${baseUrl}/user_logs/${logsType}`,{
      method: "GET",
      credentials: 'include',
      headers: getHeaders(),


    })

    let responseBack = await response.json()
    // let logs = responseBack.logs.filter((record) => record.log_object !== null)
    // responseBack.logs = logs
    return responseBack
  } catch (err) {
    return { status: 500 };

  }
}
