import { getPlayerScoreFlagsCall, getAllPlayersScoreFlagsCall } from '../../api/Health/PlayerScoreFlag'
import { processResponse, processHealthResponse, toCamelCase, processParamsToServer } from '../Utils'



export async function getPlayerScoreFlags(playerId) {
  const response = await getPlayerScoreFlagsCall(playerId);
  // console.log('RESOINSE ????', response, response.status)
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function getAllPlayersScoreFlags({ range }) {
  const response = await getAllPlayersScoreFlagsCall({ range });
  // console.log('RESOINSE ????', response, response.status)
  if (response ) {
    if (response && response.status !== 401 ) {

      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) =>  res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      // unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
