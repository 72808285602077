import React from 'react';
import Button, {buttonClasses} from "@mui/material/Button";
import { styled } from '@mui/material/styles';

const ActivityModalButton = (props) => {
  const buttonState = props.buttonState
  const goalType = props.goalType
  const disabled = buttonState === "disabled"

  const ActionButton = styled(Button)(({})=>({
    [`&.${buttonClasses.root}`]: {
      width: "60%",
      fontSize: "12px",
      padding: "2px",
      maxHeight: '40px',
      marginLeft: '25px',
      "&.Mui-disabled": {
        backgroundColor: '#BDBDBD',
        color: "white"
      }
    },
  }))

  function handleClick(){
    props.toggleModalVisibility()
  }

  function displayText(){
    if(goalType === "goal"){
      return "REPORT PROGRESS"
    } else if (goalType === "iap_purchase"){
      return "REPORT PURCHASE"
    }
  }

  return(
    <ActionButton
      variant="contained"
      disabled={disabled}
      onClick={handleClick}
    >
      {displayText()}
    </ActionButton>
  )
}

export default ActivityModalButton