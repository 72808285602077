import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';

import { getProgramImage } from '../../common/Utils'

import { makeStyles } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatDate, getAdminDomain } from '../../common/Utils'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Grid from '@mui/material/Grid';
import IMSkeletonLoader from '../../common/IMSkeletonLoader'



const MemberButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#3D8BFD',
  borderColor: '#0063cc',
  marginTop: '20px',
  width: '100%',
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});


const GridItemTitle = styled(Grid)(({ theme }) => ({
  // textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '100%',
  /* identical to box height, or 12px */

  letterSpacing: '1px',

  /* Gray/600 */
  color: '#999999',
  // marginBottom: 5
  alignItems: 'center',
    display: 'flex',
}))

const GridItemValue = styled(Grid)(({ theme }) => ({
  fontWeight: 550,
  fontSize: '12px',
  // lineHeight: '143%',
  /* identical to box height, or 20px */

  letterSpacing: '1px',

  /* Theme/Dark */

  color: '#212529'
}))

const ProgramBtn = styled(Button)(({theme}) => ({
  justifyContent: 'space-between',
  color: '#1976D2',
  width: '260px',
  height: '48px',
  textTransform: 'none',
  background: 'rgba(0, 0, 0, 0.06)',
  borderRadius: '4px 4px 0px 0px',

  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '24px',

}))

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '24px',

  lineHeight: '133.4%',
  marginBottom: 15
}))


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({

  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    boxSizing: 'border-box',

    position: 'absolute',
    width: '260px',
    left: '20px',
    top: '778px',
    padding: '15px',
    textTransform: 'none',
    /* Gray/White */

    background: '#FFFFFF',
    /* Gray/400 */

    border: '1px solid #CED4DA',
    /* Regular Shadow */

    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
    borderRadius: '0px 0px 5px 5px',

    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function Program(props) {
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ summary, setSummary ] = useState({
    'Gift Card': props.prize && props.prize.name ? props.prize.name : 'Unknown',
    'Member ID': props.id,
    'Last Updated At': formatDate(new Date(props.updatedAt))
  })
  const open = Boolean(anchorEl);


  const buttonImg = getProgramImage(props.program)

 const handleClick = (event) => {
   setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
   setAnchorEl(null);
 };

 return (
     <div>
       <ProgramBtn
         id={`${props.id}ProgramBtn`}
         key={`${props.id}ProgramBtnKey`}
         aria-controls={open ? 'menu-program' : undefined}
         aria-haspopup="true"
         aria-expanded={open ? 'true' : undefined}
         variant="outlined"
         disableElevation
         onClick={handleClick}
         startIcon={<img src={buttonImg} alt={props.program.name} />}
         endIcon={<KeyboardArrowDownIcon sx={{right: '21.5px', position: 'absolute', height: '100%'}}/>}
         sx={{'& .MuiButton-endIcon': {
           height: '100%',
         }}}
       >
         {`${props.program.name} (${props.buildVersion})`}
       </ProgramBtn>
       <StyledMenu
         id={`${props.id}MenuProgram`}
         MenuListProps={{
           'aria-labelledby': 'menu-program',
         }}
         anchorEl={anchorEl}
         open={open}
         onClose={handleClose}
       >
       <Grid container spacing={0.5} sx={{ marginBottom: 2 }}>
     { Object.keys(summary).map((key) =>
         <><GridItemTitle item xs={6} key={`${key}KeyItem`}>
             {key}
           </GridItemTitle>
           <GridItemValue item xs={6} key={`${key}KeyItemValue`}>
             { summary[key] }
           </GridItemValue>
       </>
       )
     }

     <MemberButton
        onClick={() => window.open(`${getAdminDomain(props.env)}/dashboard/admin/members/${props.id}`, "_blank")}
        variant="contained"
        disableRipple>
       View Member Page
     </MemberButton>
     </Grid>
       </StyledMenu>
     </div>
   );
}
export default function ProgramsContainer(props) {

  const [ mount, setMount ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ members, setMembers ] = useState([])


  useEffect(() => {
    if (!mount && 'members' in props.player) {
      setMembers(props.player.members)
      setLoading(false)
    }

  })

  return (
    <>{loading
      ? <IMSkeletonLoader columns={1} itemsNumber={1} variant={'rectangular'} width={260} height={48}/>
      : <Box sx={{ flexGrow: 1, marginLeft: '13px', }}>
      {loading
        ? <></>
        :  <Box
              sx={{
                display: 'flex',
                '& > *': {
                  m: 1,
                },
              }}
            >
            <Stack spacing={2}>
                { members.length === 0
                  ? <Title>No programs</Title>
                  : members.map((member) =>
                      <Program
                        env={props.env}
                        {...member}
                        />
                    )
                  }
            </Stack>
          </Box>
        }
        </Box>
    }
    </>
)
}
