import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


export default function IMAlerts(props) {
  const [ message, setMessage ] = useState(props.message);

  const onCloseAlert = () => {
    setMessage('');
    if (props.onClose) {
      props.onClose();
    }
  }


  return (
    <>
      <Snackbar
        open={true}
        key={`transition${props.severity}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{marginTop: 5}}>
           <Alert
            onClose={onCloseAlert}
            severity={props.severity}>
            <AlertTitle>{props.title || ''}</AlertTitle>
            {props.message}
          </Alert>
      </Snackbar>
    </>
  );
}


// : <Snackbar
// open={true}
// key={`transition${props.severity}`}
// onClose={onCloseAlert}
// autoHideDuration={6000}
// anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{marginTop: 5}}>
//      <Alert  severity={props.severity}>{props.message}</Alert>
// </Snackbar>}
