import React, { useEffect, useState } from 'react';
import { indigo } from '@mui/material/colors';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAsyncDebounce } from 'react-table'
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// import Input from '@mui/material/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import DatePickerPopover from './DatePickerPopover';
import FaceIcon from '@mui/icons-material/Face';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SecurityIcon from '@mui/icons-material/Security';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 50
      },
    paperScrollPaper: {
      background: 'transparent',
       boxShadow: 'none'
    },
    messageStyled: {
      fontWeight: '700',
      color: indigo[900],
      fontSize: '20px'
    }
}));

export default function NotesFilters(props) {

  const [ loading, setLoading ] = useState(true)
  const [ refresh, setRefresh ] = useState(false)
  const [ notes, setNotes ] = useState(null)
  const [ filteredNotes, setFilteredNotes ] = useState(null)

  const [ authorFilter, setAuthorFilter ] = useState('')
  const [ dateFilter, setDateFilter ] = useState(null)
  const [ globalFilter, setGlobalFilter ] = useState('')
  const [ ticketFilter, setTicketFilter ] = useState('')


  const classes = useStyles();


  const getAuthors = () => {
    if (!notes || notes.length === 0) {
      return [];
    }

    let authourNotes = [  {
        value: 'humans',
        label: 'All Humans',
        icon: <FaceIcon fontSize="small"/>
      },
      {
       value: 'automated',
       label: 'All Automated',
       icon: <SmartToyIcon fontSize="small"/>
       },
      {
      value: 'sponsorGuard',
      label: 'SponsorGuard',
      icon: <SecurityIcon fontSize="small"/>
      },
      {
      value: 'divider',
      label: '',
      },
    ]

    let authors = ['All Humans', 'All Automated', 'SponsorGuard'];

    notes.forEach((note) => {
      if ( note.admin && note.admin != 'SponsorGuard' &&
        Object.keys(note.admin).length > 0 &&
        !authors.includes(`${note.admin.firstName} ${note.admin.lastName}`)) {
        authors.push(`${note.admin.firstName} ${note.admin.lastName}`);
        authourNotes.push({
          value: `${note.admin.id}`,
          label: `${note.admin.firstName} ${note.admin.lastName}`,
          icon: <SecurityIcon fontSize="small"/>
        })
      }
     }
    )
    return authourNotes;
  }


  useEffect(() => {
    if (props.notes && (props.refresh !== refresh || !notes || notes.length !== props.notes.length)) {
      setNotes(props.notes)
      setFilteredNotes(props.filteredNotes)
      setRefresh(props.refresh)
    }
    setLoading(false)
  })

  const onChangeText = useAsyncDebounce(value => {
    setGlobalFilter(value.trim() === true ? '' : value || '')
    globalFilterChange(value)

  }, 200)

  const onChangeTicket = useAsyncDebounce(value => {
    setTicketFilter(value.toString().trim() === true ? '' : value || '')
    ticketFilterChange(value)

  }, 200)


  const filterByAuthor = (notesToFilter, authorFilter) => {

    if (!authorFilter) {
      return notesToFilter;
    }
    notesToFilter = notesToFilter.filter((note) =>
      authorFilter === 'sponsorGuard' && note.admin === 'SponsorGuard'
      || authorFilter === 'humans' && note.admin.id
      || note.admin.id && authorFilter === note.admin.id.toString())

    return notesToFilter;
  }

  const filterAuthorNotes = (event) => {
    const authorFilterEvent = event.target.value
    let newFilteredNotes = notes;

    if (authorFilterEvent) {
      newFilteredNotes = filterByAuthor(newFilteredNotes, authorFilterEvent);
    }

    setAuthorFilter(authorFilterEvent)
    newFilteredNotes = filterByDate(newFilteredNotes, dateFilter)
    newFilteredNotes = filterByText(newFilteredNotes, globalFilter)


    setFilteredNotes(newFilteredNotes)
    console.log('FILTERED NOTES BY AUTHOR ', newFilteredNotes, notes)

    props.onFilter(newFilteredNotes)
    // return filteredNotes;
  }

  const filterByDate = (notesToFilter, newDateFilter) => {

    return newDateFilter === null
      ? notesToFilter
      : notesToFilter.filter((note) =>
              note.createdAt && new Date(note.createdAt).getTime() >= newDateFilter.from && new Date(note.createdAt).getTime() <= newDateFilter.to)

  }

  const onDateFilter = (from, to) => {
    let newFilteredNotes = notes;
    let newDateFilter = { from, to }
    newFilteredNotes = filterByDate(newFilteredNotes, newDateFilter)
    setDateFilter({ from , to })
    newFilteredNotes = filterByAuthor(newFilteredNotes, authorFilter);
    newFilteredNotes = filterByText(newFilteredNotes, globalFilter)

    // newFilteredNotes = newFilteredNotes.filter((note) => note.createdAt && new Date(note.createdAt).getTime() >= from && new Date(note.createdAt).getTime() <= to)
    setFilteredNotes(newFilteredNotes)
    props.onFilter(newFilteredNotes)
  }

  const filterByText = (notesToFilter, text) => {
    return text === null || !text ? notesToFilter : notesToFilter.filter((note) => note.note.toLowerCase().includes(text.toLowerCase()));

  }



  const globalFilterChange = (globalFilterValue) => {
    let newFilteredNotes = filteredNotes && filteredNotes.length > 0 ? filteredNotes : notes;

    if (globalFilterValue) {
      newFilteredNotes = newFilteredNotes.filter((note) => note.note.toLowerCase().includes(globalFilterValue.toLowerCase()));
    } else {
      newFilteredNotes = notes;
      // console.log('GLOBAL FILTER new when emoty ', authorFilter, dateFilter)


    }
    // console.log('GLOBAL FILTER new ', newFilteredNotes)
    newFilteredNotes = filterByAuthor(newFilteredNotes, authorFilter);
    newFilteredNotes = filterByDate(newFilteredNotes, dateFilter)
    if (ticketFilter && ticketFilter.trim() !== '') {
      newFilteredNotes = newFilteredNotes.filter((note) => note.zendeskTicketNumber.toString().includes(globalFilter.toString()));
    }
    setFilteredNotes(newFilteredNotes)
    props.onFilter(newFilteredNotes)
    setRefresh(!refresh)
  }

  const ticketFilterChange = (ticketFilterValue) => {
    let newFilteredNotes = filteredNotes && filteredNotes.length > 0 ? filteredNotes : notes;

    if (ticketFilterValue) {
      newFilteredNotes = newFilteredNotes.filter((note) => note.zendeskTicketNumber && note.zendeskTicketNumber.toString().includes(ticketFilterValue.toString()));
    } else {
      newFilteredNotes = notes;
      // console.log('GLOBAL FILTER new when emoty ', authorFilter, dateFilter)


    }
    // console.log('GLOBAL FILTER new ', newFilteredNotes)
    newFilteredNotes = filterByAuthor(newFilteredNotes, authorFilter);
    newFilteredNotes = filterByDate(newFilteredNotes, dateFilter)
    if (globalFilter && globalFilter.trim() !== '') {
      newFilteredNotes = newFilteredNotes.filter((note) => note.zendeskTicketNumber && note.note.toLowerCase().includes(globalFilter.toLowerCase()));
    }

    setFilteredNotes(newFilteredNotes)
    props.onFilter(newFilteredNotes)
    setRefresh(!refresh)
  }





  return (
    <Box>

      <Stack
        spacing={1}

        >
      <Box
       component="form"
       sx={{
         justifyContent: 'space-evenly',
         display: 'flex',
         '& > :not(style)': { m: 1, width: '30ch' },
         '& .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input': {
           display: 'flex',
           minWidth: 'fit-content'
         }
       }}
       noValidate
       autoComplete="off"
     >
     <DatePickerPopover onFilter={onDateFilter}/>
     <TextField
       id="filled-select-author"
       select
       label="Author"

       sx={{ m: 1, width: '30ch', display: 'flex',
       '& .MuiSelect-select': {
         display: 'flex !important',
         }
        }}
       onChange={filterAuthorNotes}
       defaultValue="humans"
       variant="filled"
       InputProps={{
         // startAdornment: <InputAdornment position="start"><AttributionOutlinedIcon /></InputAdornment>,
       }}
     >
       {getAuthors().map((option) => (
         option.value === 'divider'
         ?  <Divider key={'dividerAuthorsMenuItem'} sx={{width: '80%', display: 'block', margin: 'auto'}}/>
         : <MenuItem key={option.value} value={option.value}>
             <ListItemIcon >
               {option.icon ? option.icon : <></>}
             </ListItemIcon>
            <Typography >{option.label} </Typography>
         </MenuItem>
       ))}
     </TextField>
     </Box>
     <Box
      component="form"
      sx={{
        justifyContent: 'space-evenly',
        display: 'flex',
        '& > :not(style)': { m: 1, width: 'fit-content' },
        '& .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input': {
          display: 'flex',
          minWidth: 'fit-content'
        }
      }}
      noValidate
      autoComplete="off"

    >
       <TextField
          id="ticket-num-id"
          type="number"
          label="Ticket #"
          variant="filled"
          onChange={e => {
            onChangeTicket(e.target.value);
          }}
          InputProps={{
           startAdornment: <InputAdornment position="start"><ConfirmationNumberOutlinedIcon /></InputAdornment>,
         }}/>

       <TextField
          label="Search"
          id="filled-start-adornment"
          sx={{ m: 1, width: '25ch' }}
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          variant="filled"
          onChange={e => {
            onChangeText(e.target.value);
          }}
        />

     </Box>
     </Stack>
     {!loading
       ?  <></>
       :  <div className={classes.root}>
            <CircularProgress color={indigo[200]}/>
          <div className={classes.messageStyled}>{'Loading Notes'}</div>
        </div>}
    </Box>

  )

}
