import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

const Header = styled('Typography')(({ theme }) => ({
  fontFamily: 'Helvetica Neue',
  fonStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '150%',
/* or 15px */
  marginLeft: '20px',

  display: 'flex',
  alignItems: 'center',
  letterSpacing: '1px',

/* Theme/Secondary */

  color: '#6C757D'
}));



export default function SideMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [ permittedActions, setPermittedActions ] = React.useState(props.playerActions.filter((action) => props.permittedActions.includes(action.action)))

  const handleClick = (event) => {
    console.log('PROPS MENU ', props)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickMenu = () => {

  }


  const handleChange = (event) => {
    if (!props.disableEditing) {
      props.onStatusChange({ status: event.target.name, check: event.target.checked })
    }
  };
  const handleAction = (action) => (event) => {
    if (!props.disableEditing) {
      props.onActionMade(action)
    }
  }

  const relatedStatusDisbaled = (status) => {
    // trying to ENABLE  a Player with blocked IP should be disabled!
    const blockedIp = props.playerStatus.filter((stat) => stat.name === 'blockIp')[0]
    const disabled = props.playerStatus.filter((stat) => stat.name === 'disabled')[0]

    return (status === 'disabled' && blockedIp.check  && disabled.check  )
  }



  return (
    <React.Fragment >
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        sx={{
          '& .MuiPaper-root': {
            width: '366px',
            height: permittedActions.length > 0 ? '350px' : '250px',
            marginLeft: '15%',

            top: '60px !important'
          },

        }}
        open={open}
        onClose={handleClose}
        onClick={onClickMenu}
        PaperProps={{
          elevation: 0,
          sx: {

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
              },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateX(-2900%) translateY(150%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Header >
          CHANGE STATUS
        </Header>
        <MenuItem >
        <FormControl component="fieldset" variant="standard">
          <FormGroup >
          {props.playerStatus.map((status) =>
            props.permittedActions.includes(status.name) && <FormControlLabel
              control={
                <Switch disabled={props.disableEditing || relatedStatusDisbaled(status.name)} sx={{ marginRight: '20px' }} checked={status.check} onChange={handleChange} name={status.name} color={status.color}/>
              }
              label={status.label}
            />
          )}
          </FormGroup>
          <FormHelperText></FormHelperText>
        </FormControl>
        </MenuItem >
        <Divider sx={{width: '80%', display: 'block', margin: 'auto'}}/>
        {permittedActions.length > 0 && <Header >
          TAKE ACTION
        </Header>}
        {
          permittedActions
          ? permittedActions.map((action) =>
             <MenuItem disabled={props.disableEditing} onClick={handleAction(action)}>
              <ListItemIcon>
                {action.icon}
              </ListItemIcon>
              <Typography sx={{ textDecoration: action.textDecoration }}>  {action.title}  </Typography>
            </MenuItem>
          )
          : <></>
        }
      </Menu>
    </React.Fragment>
  );
}
