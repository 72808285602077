import { updateUserCall, getUserCall } from '../api/Users'
import { processResponse, toCamelCase } from './Utils'


export async function getUser({ unAuthCallback }) {

  const response = await getUserCall();

  // data.append('delivery_id', deliveryId);
  // data.append('description', 'test description');
  if (response ) {
    if (response && !response.error && response.status === 200) {
      // localStorage.setItem("token", newToken);
      let res = {}

      Object.keys(response).map((key) => res[toCamelCase(key)] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        unAuthCallback(response)
        return;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function updateUser({ id, params, avatar }) {


  const formData = new FormData();
  formData.append("avatar", avatar);
  formData.append("user_id", id);

  const response = await updateUserCall({ id, formData });

  if (response ) {
    if (response && !response.error) {
      return response
    } else {
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
