import { signInCall, signInToIPermCall, signSamlICall } from '../api/Auth'

export async function signIn({ jti }) {

  const response = await signInCall({ jti });
 // console.log('RESOINSE ????', response)
  if (response ) {
    if (response && !response.error) {
      const newToken = response.headers.get("Authorization");
      // localStorage.setItem("token", newToken);
      return response
    } else {
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}

export async function signSamlIn({ jti }) {
  const response = await signSamlICall({ jwt: jti });

  return response
}


export async function signInToIPerm() {
  // Cookies.remove('_user_permissions_session')
  const response = await signInToIPermCall();
 // console.log('RESOINSE ????', response)
  if (response ) {
    if (response && !response.error) {
      // const newToken = response.headers.get("Authorization");
      // console.log('RESOINSE ????', newToken)

      // localStorage.setItem("token", newToken);
      return response
    } else {
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
