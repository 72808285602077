import axios from 'axios';
import { getHeaders,  baseUrl } from '../utils';



export async function getScoreFlagsCall() {
  try {
    const response  = await fetch(`${baseUrl}/get_score_flags`,{
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',

      }
    )

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}
export async function editScoreFlagCall({ data }) {

  try {
    const response  = await fetch(`${baseUrl}/edit_score_flag`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data
      })
      }
    )

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}

export async function newScoreFlagCall({ data }) {

  try {
    const response  = await fetch(`${baseUrl}/new_score_flag`,{
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data
      })
      }
    )

    const responseBack = await response.json()

    return responseBack
  } catch (err) {
    return { err };

  }
}
