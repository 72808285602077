import React, {useContext, useState} from 'react';
import {
  TableCell,
  TableRow,
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";
import ActivityModalButton from "./ActivityModalButton";
import BaseSupportModal from "./SupportModal/BaseSupportModal";
import {SupportModalContext} from "./SupportModal/SupportModalContext";

const ChallengeActivityRow = (props) => {
  const activity = props.activity
  const {modalOpen, setModalOpen} = useContext(SupportModalContext)

  // console.log("activity", activity)

  const imageContainerStyles = {
    flexDirection: 'column',
    display: 'flex',
    background: `url(${activity.appIcon}) no-repeat`,
    backgroundSize: '100%',
    minWidth: 40,
    minHeight: 40,
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "5px"
  }

  const wrapperStyles={
    display: "flex"
  }

  const ActivityCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.body}`]: {
      minHeight: '105px',
      maxWidth: '150px'
    },
  }))

  function formatDate(epoch){
    console.log(epoch)
    if(isNaN(epoch)){
      return "-"
    }
    try {
      let date = new Date(new Date(epoch*1000).toGMTString())
      return date.toLocaleString("en-US", { hour12: true })
    } catch(err) {
      console.error(err)
      return new Date(epoch*1000).toGMTString()
    }
  }

  function chipBackgroundColor(){
    let color = '#B0BEC5'
    if(activity.status === "Started"){color = '#FFCC00'}
    if(activity.status === "Completed"){ color = '#406AB4'}
    if(activity.status === "Pending"){color = '#C62828'}
    if(activity.status === "Uninstalled"){color = '#C62828'}
    return color
  }

  const statusChipStyles = {
    backgroundColor: chipBackgroundColor(),
    margin: "0",
    justifyContent: 'center',
    minHeight: 25,
    display: 'flex',
    borderRadius: 25,
    alignItems: 'center',
    paddingLeft: '5px',
    maxHeight: '35px',
    color: 'white',
  }

  const statusChipCellStyles = {
    padding: '0'
  }

  function toggleModalVisibility(){
    console.log("called the toggle", modalOpen)
    if(modalOpen){
      setModalOpen(false)
    } else {
      setModalOpen(true)
    }
  }

  function handleModalClose(){
    console.log('sent close')
    toggleModalVisibility()
  }

  const statusChip = () => {
    return(
      <TableCell style={statusChipCellStyles}  align="center">
        <p style={statusChipStyles}>
          {activity.status}
        </p>
      </TableCell>
    )
  }

  function competedOnIcon() {
    if (activity.completedBy){
     return(
       <Tooltip title={activity.completedBy}>
        <span role="img" aria-label="person" style={{fontSize: 15, textAlign: "center", marginLeft: "5px"}}>🧑</span>
      </Tooltip>
     )
    } else {
      return <span role="img" aria-label="robot" style={{fontSize: 15, textAlign: "center", marginLeft: "5px"}}>🤖</span>
    }
  }


  function statusChipCell(){
    if(activity.statusTooltip){
      let tooltip = activity.statusTooltip
      if(!isNaN(activity.statusTooltip)){
        tooltip = formatDate(tooltip)
      }
      return(
        <Tooltip title={tooltip}>
          {statusChip()}
        </Tooltip>
      )
    } else {
      return(
        <>
          {statusChip()}
        </>
      )
    }

  }

  return(
    <>
      <TableRow>

        <ActivityCell style={{padding: "6px 10px"}} component="th" scope="row" align="center">
          <div style={wrapperStyles}>
            <div style={imageContainerStyles}/>
            <div style={{marginLeft: "10px", textAlign: "left"}}>
              <p style={{margin: 0, padding: 0}}>
                {activity.displayTitle}
              </p>
              <small>by {formatDate(activity.dateToNextState)}</small>
            </div>
          </div>
        </ActivityCell>

        <TableCell align="center">
          {activity.typeLabel}
        </TableCell>

        <TableCell align="center">
          {activity.prizeLabel}
        </TableCell>

        {statusChipCell()}

        <TableCell align="center">
          { activity.completedOn &&
            <p style={{margin: 0, padding: 0}}>
              {formatDate(activity.completedOn)}
              {competedOnIcon()}
            </p>
          }
          { activity.buttonState !== "hidden" &&
            <ActivityModalButton
              buttonState={activity.buttonState}
              goalType={activity.goalType}
              toggleModalVisibility={toggleModalVisibility}
              handleModalClose={handleModalClose}
            />
          }

        </TableCell>
      </TableRow>
    </>
  )
}

export default ChallengeActivityRow