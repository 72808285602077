import auFlag from '../../assets/AU.png';
import usFlag from '../../assets/US.png';
import caFlag from '../../assets/CA.png';
import gbFlag from '../../assets/GB.png';

export const COUNTRY_MAP = {
  'US': { icon: usFlag,  currency: '$'},
  'CA': { icon: caFlag,  currency: '$'},
  'GB': { icon: gbFlag,  currency: '$'},
  'AU': { icon: auFlag,  currency: '$'},

}
