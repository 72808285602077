import React, { useContext } from "react";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { PlayerContext } from "./PlayerContext";

const DEFAULT_TIME_ZONE = "America/Los_Angeles";

const PlayerTimestamp = ({ date, dateFormatter, children, ...props }) => {
  const player = useContext(PlayerContext);

  if (dateFormatter == null) {
    dateFormatter = (date, options) =>
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
        ...options,
      }).format(date);
  }

  const defaultTime = dateFormatter(date, {
    timeZone: DEFAULT_TIME_ZONE,
  });

  let playerTime = ''
  if (player.ipTimeZone == null || player.ipTimeZone === 'Unknown'){
    playerTime = 'Player Timezone Unknown'
  } else {
    playerTime = dateFormatter(date, { timeZone: player.ipTimeZone });
  }

  if (children == null) {
    children = <Typography>{defaultTime}</Typography>;
  }

  return (
    <Tooltip title={playerTime} placement="top" arrow {...props}>
      {children}
    </Tooltip>
  );
};

export default PlayerTimestamp;
