import { getAnnouncementCall, newAnnouncementCall, updateAnnouncementCall, deleteAnnouncementCall } from '../api/Announcement'
import { processResponse, toCamelCase, getDateTime } from './Utils'


export async function getAnnouncement({ announcementType }) {

  const response = await getAnnouncementCall({ announcementType });

 // console.log('RESOINSE ????', response)
  if (response ) {
    if (response && !response.error && response.status === 200) {
      // localStorage.setItem("token", newToken);
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        // unAuthCallback(response)
        return;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function onNewAnnouncement({announcement}) {
  const serverAnnouncement = toServer(announcement)
  const response = await newAnnouncementCall({ announcement: serverAnnouncement });

 // console.log('RESOINSE ????', response)
  if (response ) {
    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        let res = {}
        Object.keys(response).map((key) => res[key] = processResponse(response[key]))

        return res;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function onUpdateAnnouncement({ announcement, announcementId }) {
  const serverAnnouncement = toServer(announcement)
  const id = announcementId.split('-active')[0]
  const response = await updateAnnouncementCall({ announcement: serverAnnouncement, announcementId: id });

 // console.log('RESOINSE ????', response)
  if (response ) {
    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        let res = {}
        Object.keys(response).map((key) => res[key] = processResponse(response[key]))

        return res;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}

export async function onDeleteAnnouncement({ announcementId }) {
  const id = announcementId.split('-active')[0]
  const response = await deleteAnnouncementCall({ announcementId: id });

 // console.log('RESOINSE ????', response)
  if (response ) {
    if (response && !response.error && response.status === 200) {
      let res = {}

      Object.keys(response).map((key) => res[key] = processResponse(response[key]))

      return res
    } else {
      if (response.status === 500) {
        let res = {}
        Object.keys(response).map((key) => res[key] = processResponse(response[key]))

        return res;
      }
        return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { status: 500, error: `ERROR! ${response}`};
  }
}




export function toServer(announcement) {
  const start = getDateTime(announcement.fromDate, announcement.fromTime)
  const end = getDateTime(announcement.toDate, announcement.toTime)

  return {
    title: announcement.title,
    body: announcement.message,
    announcement_type: announcement.type,
    color: announcement.type === 'alert' ? '#F8DB83' : announcement.type === 'critical' ? '#DDACAC' : '#BDD28F',
    cta: announcement.button,
    image: announcement.illustration,
    start_at: start,
    end_at: end,
    display_schedule: announcement.displaySchedule,
    country: announcement.country
  }
}
