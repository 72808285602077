import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  IconButton
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ChallengesActivityStatusCell from "./ChallengesActivityGroupStatusCell";
import ChallengeActivityTable from "./ChallengeActivityTable";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const ChallengeActivityGroupRow = (props) => {
  const [ detailsOpen, setDetailsOpen ] = useState(false)
  const [ group, setGroup ] = useState(props.group)

  useEffect(()=>{
    setGroup(props.group)
  })

  const bgYellow = '#f6f1bd'

  const ChallengeTableCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      minWidth: "200px",
      textAlign: "center"
    },
  }))

  const prizeStyle = {
    borderRadius: 15,
    margin: '0px 0px',
    padding: '3px 0px',
    backgroundColor: 'rgb(214 214 214)',
    width: "70%",
    marginLeft: "15%"
  }

  function selectBackgroundColor(){
    if(detailsOpen){
      return bgYellow
    } else {
      return "initial"
    }
  }

  function formatDate(epoch){
    if(isNaN(epoch)){
      return
    }
    try {
      let date = new Date(new Date(epoch*1000).toGMTString())
      return date.toLocaleString("en-US", { hour12: true })
    } catch(err) {
      console.error(err)
      return new Date(epoch*1000).toGMTString()
    }
  }

  function dateToNextState() {
    let epoch = 0
    for (const activity of group.activities) {
      let unlocking = ["Inv. Locked", "Unlocking"].includes(group.status)
      if(unlocking && activity.goalType === "install_challenge"){
        epoch = activity.dateToNextState
        break;
      } else if (!unlocking && activity.goalType !== "install_challenge"){
        epoch = activity.dateToNextState
        break;
      }
    }
    return epoch
  }

  function timingLabel() {
    return group.status === "Inv. Locked" ? "Unlocks" : "Expires"
  }

  const FormattedDatesDisplay = (props) => {
    return(
      <>
        <p style={{margin: 0, padding: 0}}>
          {formatDate(props.initialEpoch)}
        </p>
        <small><b>{timingLabel()} on:</b> {formatDate(dateToNextState())}</small>
      </>
    )
  }

  function toggleActivityDetails(){
    if (detailsOpen){
      setDetailsOpen(false)
    } else {
      setDetailsOpen(true)
    }
  }

  return(
    <>
      <TableRow style={{backgroundColor: selectBackgroundColor()}} key={1}>
        <ChallengeTableCell style={{minWidth:0, maxWidth: '30px', paddingRight: '10px'}}>
          <ViewModuleIcon style={{color: "grey", maxWidth: '30px'}}></ViewModuleIcon>
        </ChallengeTableCell>

        <ChallengeTableCell style={{textAlign: "left"}}>
          <FormattedDatesDisplay
            initialEpoch={group.groupStartDate}
            status={group.status}
          />
        </ChallengeTableCell>

        <ChallengeTableCell>
          <p>Boost</p>
        </ChallengeTableCell>

        <ChallengeTableCell>
          <p>{group.mustCompleteLabel}</p>
        </ChallengeTableCell>

        <ChallengeTableCell align="center">
          <p style={prizeStyle}>{group.prizeLabel}</p>
        </ChallengeTableCell>

        <ChallengesActivityStatusCell
          status={group.status}
        />

        <ChallengeTableCell style={{textAlign: "center", minWidth: "150px"}}>
        <p style={{margin: 0, padding: 0}}>
          {formatDate(props.completionDate)}
        </p>
        </ChallengeTableCell>

        <ChallengeTableCell style={{minWidth: "50px"}}>
          <IconButton
            onClick={toggleActivityDetails}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ChallengeTableCell>
      </TableRow>

      <ChallengeActivityTable
        detailsOpen={detailsOpen}
        activities={group.activities}
        group={group}
      />
    </>
  )

}

export default ChallengeActivityGroupRow