import axios from 'axios';
import { getHeaders,  baseUrl } from '../utils';

export async function playerPointsCall(playerId) {

  try {

    const response  = await fetch(`${baseUrl}/player_points/${playerId}`,{
      method: "GET",
      credentials: 'include',
      headers: getHeaders(),
    })


    const responseBack = await response.json()
    // console.log('BACK ???? ', responseBack)
    return responseBack
  } catch (err) {
    return { status: 401 };

  }
}
