import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { grey } from '@mui/material/colors';

import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';

import Notes from './Notes'
import NotesFilters from './NotesFilters'
import NotesHeader from './NotesHeader'
const NotesContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  // height: 60,
  lineHeight: '60px',
}));



const darkTheme = createTheme({ palette: { mode: 'dark' } });
const lightTheme = createTheme({ palette: { mode: 'light' } });

export default function NotesSideContainer(props) {

  const [ notes, setNotes ] = useState(null)
  const [ player, setPLayer ] = useState(props.player)
  const [ filteredNotes, setFilteredNotes ] = useState(null)
  const [ refreshNotes, setRefreshNotes ] = useState(false)

  useEffect(() => {
    if (!notes || notes.length !== props.notes.length || refreshNotes !== props.refreshNotes) {
      setNotes(props.notes)
      setFilteredNotes(props.notes)
      setRefreshNotes(props.refreshNotes)
    }
  })

  const onAddNote = () => {
    props.onAddNote()
  }

  const onFilter = (filtered) => {
    console.log('FILTERED NOTES ', filtered)
    setFilteredNotes(filtered)
  }

  const onMinize = () => {
    props.onMinize()
  }


  return (

    <Box
      ref={props.ref}
      sx={{
       // marginRight: '20px',
       zIndex: 99999,
       top: '140px',
       right: 0,
       position: 'fixed',

       maxWidth: 480,
       bgcolor: 'background.default',
       }}
    >
    <NotesHeader title={'Notes'} onAddNote={onAddNote} onMinize={onMinize}/>
    <NotesContainer elevation={24} >

      <NotesFilters notes={notes} filteredNotes={filteredNotes} onFilter={onFilter} refresh={refreshNotes}/>
    </NotesContainer>
    { !notes
      ? <></>
      :  <NotesContainer >

          <Notes notes={filteredNotes} refresh={refreshNotes}/>
         </NotesContainer>}
    </Box>

  );
}
