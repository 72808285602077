import React, {useContext, useState } from 'react';
import {
  TextField,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {buttonClasses} from "@mui/material/Button";
import {SupportModalContext} from "./SupportModalContext";

const IapValueForm = () => {
  const {group} = useContext(SupportModalContext)
  const [value, setValue] = useState('');

  const AddButton = styled(Button)(({})=>({
    [`&.${buttonClasses.root}`]: {
      width: "80px",
      maxHeight: "40px",
      marginLeft: "20px",
      fontSize: "12px",
      padding: "2px",
      "&.Mui-disabled": {
        backgroundColor: '#BDBDBD',
        color: "white"
      }
    },
  }))

  const iapContainerStyles = {
    display: 'flex',
    justifyContent: 'flex-start',
  }

  const handleChange = (event) => {
    const rawValue = event.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
    const formattedValue = rawValue ? (parseInt(rawValue, 10) / 100).toFixed(2) : '';

    setValue(formattedValue);
  };

  return (
    <div style={iapContainerStyles}>
      <TextField
        id="addIapValueField"
        label="Additional value to be reported"
        value={value}
        defaultValue="0.00"
        onChange={handleChange}
        helperText={group.iapCurrency}
        variant="outlined"
      />
      <AddButton
        variant="contained"
      >ADD
      </AddButton>
    </div>

  );
};

export default IapValueForm;