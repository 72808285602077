import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ButtonGroup from '@mui/material/ButtonGroup';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InputAdornment from '@mui/material/InputAdornment';

export default function DatePickerPopover(props) {
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ fromValue, setFromValue ] = useState(dayjs(new Date()));
  const [ toValue, setToValue ] = useState(dayjs(new Date()));
  const [ minToValue, setMinToValue ] = useState(dayjs(new Date()));
  const [ inputText, setInputText ] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: theme.palette.text.secondary,
  }));

  const filterDate = (from, to) => {
    setInputText(`${dayjs(from).format('YYYY-MM-DD')} - ${dayjs(to).format('YYYY-MM-DD')}`)
    if (props.onFilter) {
      props.onFilter(from, to)
    }
  }


  const choosePickerDate = (to) => {
    filterDate(new Date(fromValue), new Date(to))
  }


  const pickDate = (dateString) => () => {
    const todayStart = dayjs.utc().startOf('day');
    const todayEnd = dayjs().endOf('day')

    const yesterdayStart = dayjs().subtract(1, 'day').startOf('day');
    const yesterdayEnd = dayjs().subtract(1, 'day').endOf('day');

    const weekAgoStart = dayjs().subtract(7, 'day').startOf('day');
    const weekStart = dayjs().startOf('week')
    const monthStart = dayjs().startOf('month')
    const monthAgo = dayjs().subtract(30, 'day').startOf('day');

    const threeMonthsStart = dayjs().subtract(3, 'months').startOf('month')

    switch (dateString) {
      case 'yesterday': filterDate(yesterdayStart, yesterdayEnd);
      break;
      case 'today': filterDate(todayStart, todayEnd);
      break;
      case 'weekAgo': filterDate(weekAgoStart, todayEnd);
      break;
      case 'month': filterDate(monthAgo, todayEnd);
      break;
      case 'threeMonths': filterDate(threeMonthsStart, todayEnd);
      break;
    }
  }

  return (
    <div>
    <TextField
      id="filled-select-date"
      label="Date"
      variant="filled"
      sx={{
        width: '224px',
        '& .MuiInputBase-input': {
          fontSize: '12px',
          minWidth: '140px',
          height: '23px',
        }
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start"><CalendarMonthOutlinedIcon /></InputAdornment>,
      }}
      value={inputText}
      onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{padding: 2}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>

            <Stack direction="row" spacing={2}>
              <DesktopDatePicker
                 label="From"
                 value={fromValue}
                 minDate={dayjs('2021-01-01')}
                 onChange={(newValue) => {
                   setFromValue(newValue);
                   setMinToValue(newValue)
                 }}
                 renderInput={(params) => <TextField {...params} />}
               />
               <DesktopDatePicker
                  label="To"
                  value={toValue}
                  minDate={minToValue}
                  onChange={(newValue) => {
                    setToValue(newValue);
                    choosePickerDate(newValue)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
             </Stack>
          </LocalizationProvider>
          <Stack
            spacing={1}
            sx={{display: 'flex', marginTop: 4}}
            aria-label="date note picker"
            size="large">
              <Button variant="outlined" onClick={pickDate('today')}>Today</Button>
              <Button variant="outlined" onClick={pickDate('yesterday')}>Yesterday</Button>
              <Button variant="outlined" onClick={pickDate('weekAgo')}>Last 7 Days</Button>
              <Button variant="outlined" onClick={pickDate('month')}>Past 30 Days</Button>
              <Button variant="outlined" onClick={pickDate('threeMonths')}>Past 3 Months</Button>
          </Stack>
        </Box>
      </Popover>

    </div>
  );
}
