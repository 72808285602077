import React, {useContext} from "react";

import Chip from "@mui/material/Chip";

import {OnboardingContext} from "./OnboardingContext";

const STATUS_PROPS = {
  pending: {
    label: "Pending",
    color: "warning",
  },
  in_progress: {
    label: "In Progress",
    color: "success",
  },
  completed: {
    label: "Completed",
    color: "primary",
  },
};

const OnboardingStatusChip = ({...props}) => {
  const onboarding = useContext(OnboardingContext);
  if (onboarding == null) {
    return null;
  }
  const statusProps = STATUS_PROPS[onboarding.status];
  if (statusProps == null) {
    return null;
  }
  return <Chip {...props} {...statusProps} />;
};

export default OnboardingStatusChip;
