import { getSpotlights } from '../../api/Player/Spotlights'
import { processSpotlightResponse } from '../Utils'

export async function getPlayerSpotlights() {
  const pathname = window.location.pathname.split('/')
  const playerId = pathname[2]

  const response = await getSpotlights(playerId);
  console.log('models spotlight res')
  console.log(response)

  return processSpotlightResponse(response)
}