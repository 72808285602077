import { playerPointsCall } from '../../api/Player/Points'
import { processResponse, toCamelCase } from '../Utils'

export async function getPlayerPoints({ playerId, unAuthCallback }) {

  const response = await playerPointsCall(playerId);
 // console.log('RESOINSE ????', response)
  if (response ) {
    if (response && response.status !== 401 ) {
      // const newToken = response.headers.get("Authorization");
      // localStorage.setItem("token", newToken);
      if (response.status === 500) {
        return response
      }
      let res = {}

      Object.keys(response).map((key) => res[key] = key === 'notes'
        ?  response[key].map((note) =>
               processResponse(note))
        : processResponse(response[key]))
      // console.log('RESOINSE ???11111?', res)

      return res
    } else {
      unAuthCallback();
      return { status: 401 }
    }
  } else {
    console.log('ERROR', response)
    return { error: `ERROR! ${response}`};
  }
}
